import axios from 'axios';
import { IframelyMedia } from '@prismamedia/one-components';
import { auth } from './auth';
import { getIframely } from './getIframely';

const imageEndpoint = `${config.API_ONE_IMAGE}/api/images`;

export const uploadImages = async (
  files: File[],
  namespace: string,
): Promise<(IframelyMedia | Error)[] | false> => {
  const iframelys = [];
  const jwt = await auth.getJwt();
  if (!jwt) {
    return false;
  }
  const axiosInstance = axios.create({
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  for (let i = 0; i < files.length; i++) {
    const data = new FormData();
    data.append('image', files[i]);
    data.append('namespace', namespace);
    try {
      const result = await axiosInstance.post(imageEndpoint, data);
      const iframely = await getIframely(result.data.url);
      iframelys.push(iframely);
    } catch (e) {
      iframelys.push(e);
    }
  }
  return iframelys;
};

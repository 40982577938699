import * as React from 'react';
import { Avatar, Chip, StandardProps, Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { ChipProps } from '@mui/material/Chip';
import uuidv4 from 'uuid/v4';
import { getSpacing } from '@prismamedia/one-components';
import {
  parsePathToArray,
  parsePathToString,
} from '../../../crud-core/utils/Path';

export const DEFAULT_PATH_TO_LABEL = 'label';
export const DEFAULT_PATH_TO_VALUE = 'value';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chipRoot: {
      maxWidth: '100%',
      marginBottom: getSpacing(theme, 1),
      marginRight: getSpacing(theme, 1),
    },
    chipLabel: {
      display: 'block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      userSelect: 'auto',
    },
  });

export type ChipsFieldProps = {
  chipProps?: ChipProps;
  defaultAvatar?: JSX.Element;
  field: string;
  item?: Record<string, unknown>;
  label?: string;
  onClick?: (value: string) => void;
  onDelete?: (value: string) => void;
  pathToAvatar?: string;
  pathToLabel?: string;
  pathToValue?: string;
} & WithStyles<typeof styles> &
  StandardProps<React.HTMLAttributes<HTMLDivElement>, ''>;

const ChipsField: React.FC<ChipsFieldProps> = ({
  chipProps,
  classes,
  defaultAvatar,
  field,
  item = {},
  onClick,
  onDelete,
  pathToAvatar,
  pathToLabel = DEFAULT_PATH_TO_LABEL,
  pathToValue = DEFAULT_PATH_TO_VALUE,
}: ChipsFieldProps) => {
  const chipItems = parsePathToArray(field, item, null);

  if (!Array.isArray(chipItems)) {
    return null;
  }
  return (
    <>
      {chipItems.map((chipItem) => {
        const label = parsePathToString(pathToLabel, chipItem, chipItem);
        const value = parsePathToString(pathToValue, chipItem, chipItem);
        const avatarUrl = parsePathToString(pathToAvatar, chipItem, null);

        const avatar = avatarUrl ? (
          <Avatar
            alt={label}
            src={avatarUrl}
            imgProps={{
              onError: (e) => (e.target as Element).remove(),
            }}
          />
        ) : (
          defaultAvatar
        );

        let props = chipProps;
        if (onDelete) {
          props = {
            onDelete: () => onDelete(value),
            ...props,
          };
        }
        if (onClick) {
          props = {
            onClick: () => onClick(value),
            ...props,
          };
        }

        return (
          <Chip
            key={`${value}_${uuidv4()}`}
            label={label}
            classes={{
              root: classes.chipRoot,
              label: classes.chipLabel,
            }}
            avatar={avatar}
            {...props}
          />
        );
      })}
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default withStyles(styles)(ChipsField);

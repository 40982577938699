import { useApolloClient } from '@apollo/react-hooks';
import { gql } from '@apollo/client';
import {
  SearchAllImageSourcesByCategories,
  SearchAllImageSourcesByCategoriesVariables,
} from '../../__generated__/queries-web';

export const SEARCH_IMAGE_SOURCES_CATEGORIES = gql`
  query SearchAllImageSourcesByCategories(
    $where: ImageSourceCategoryWhereInput
    $sourceWhere: ImageSourceWhereInput
  ) {
    imageSourceCategories(first: 100, orderBy: title_ASC, where: $where) {
      id
      title
      imageSources(first: 100, orderBy: title_ASC, where: $sourceWhere) {
        id
        title
      }
    }
  }
`;

export const useSearchImageSourceCategories = () => {
  const client = useApolloClient();
  return (search: string) =>
    client
      .query<
        SearchAllImageSourcesByCategories,
        SearchAllImageSourcesByCategoriesVariables
      >({
        query: SEARCH_IMAGE_SOURCES_CATEGORIES,
        variables: {
          where: {
            imageSources_some: {
              title_contains: search,
            },
          },
          sourceWhere: {
            title_contains: search,
          },
        },
      })
      .then(({ data }) => data?.imageSourceCategories);
};

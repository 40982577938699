import axios from 'axios';

const iframelyEndpoint = `${config.API_ONE_IFRAMELY}/iframely`;

export const getIframely = async (url: string) => {
  const result = await axios.get(
    `${iframelyEndpoint}?uri=${encodeURIComponent(url)}`,
  );
  return result.data;
};

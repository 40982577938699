import { ApolloError, FetchPolicy, MutationUpdaterFn } from '@apollo/client';
import { Typography } from '@mui/material';
import { DocumentNode } from 'apollo-link';
import * as R from 'ramda';
import React from 'react';
import { Mutation, Query, QueryResult } from 'react-apollo';
import { isReactComponent } from '../../crud-core/utils/TypescriptUtils';
import { computeUpdateMutationVariables } from '../Utils/computeUpdateMutationVariables';
import { Item, Resource } from '../Utils/genericTypes';
import { EditContext } from './EditContext';

interface QueryVariables {
  where?: Record<string, unknown> | null;
}

interface childrenFunction {
  item: Record<string, any>;
  onSave: (item: Record<string, unknown>) => Promise<null | void>;
  resourceConfig: Resource;
  saving: boolean;
}

interface MutationProps {
  mutation: DocumentNode;
  update?: MutationUpdaterFn;
  onCompleted?: (data: Record<string, any>) => void;
  onError?: (error: ApolloError) => void;
}

interface ResourceEditContainerProps {
  messages?: {
    error?: JSX.Element;
    loading?: JSX.Element;
    nocontent?: JSX.Element;
  };
  query: DocumentNode;
  redirectAfterSave?: boolean;
  resourceConfig: Resource;
  variables: QueryVariables;
  isCreate: boolean;
  beforeFetch?: (item: any) => any;
  afterFetch?: (item: any) => any;
  beforeSave?: (item: any) => null | any;
  mutationProps: MutationProps;
  children?:
    | JSX.Element
    | JSX.Element[]
    | (({
        item,
        onSave,
        resourceConfig,
        saving,
      }: childrenFunction) => JSX.Element);
  emptyResourceItem?: Item;
  fetchPolicy?: FetchPolicy;
}

interface ResourceEditContainerState {
  saving: boolean;
  hasFetched: boolean;
  data: any | null;
}

export class ResourceEditContainer extends React.Component<
  ResourceEditContainerProps,
  ResourceEditContainerState
> {
  readonly state: ResourceEditContainerState = {
    saving: false,
    hasFetched: false,
    data: null,
  };

  onSave = (save: any, resourceItem: Record<string, any>) => async (
    item: Record<string, unknown>,
    doFetch = true,
  ) => {
    const { resourceConfig, beforeSave, isCreate } = this.props;

    let itemToSave = R.clone(item); // avoid ref from context
    if (beforeSave) {
      itemToSave = beforeSave(itemToSave);
      if (!itemToSave) {
        return null;
      }
    }

    if (doFetch) {
      const mutationVariables = computeUpdateMutationVariables(
        itemToSave,
        resourceConfig,
        resourceItem,
        isCreate,
      );

      if (mutationVariables) {
        this.setState({ saving: true });

        save({ variables: mutationVariables }).then(({ data }: any) => {
          const [property] = Object.keys(data);
          const itemSaved = data[property];

          this.setState({
            saving: false,
            data: { [resourceConfig.singular]: itemSaved },
          });
        });
      }
    } else {
      this.setState({
        saving: false,
        data: { [resourceConfig.singular]: item },
      });
    }
    return null;
  };

  onCompleted = (data: any) => {
    if (!this.state.hasFetched) {
      this.setState({
        data,
        hasFetched: true,
      });
    }
  };

  renderChildren = (
    resourceItem: Record<string, any>,
    onSave: (item: Record<string, unknown>) => Promise<null | void>,
  ) => {
    const { children, resourceConfig } = this.props;
    const { saving } = this.state;

    if (typeof children === 'function') {
      return children({ item: resourceItem, resourceConfig, saving, onSave });
    }

    return React.Children.map(children, (child) => {
      if (!child || !isReactComponent(child)) {
        return;
      }

      return React.cloneElement(child, {
        key: child.props.name,
        item: resourceItem,
        resourceConfig,
        saving,
        onSave,
      });
    });
  };

  shouldComponentUpdate(
    nextProps: ResourceEditContainerProps,
    nextState: ResourceEditContainerState,
  ) {
    return (
      !R.equals(this.state.data, nextState.data) ||
      nextState.hasFetched !== this.state.hasFetched ||
      nextProps.isCreate !== this.props.isCreate
    );
  }

  render() {
    const {
      messages,
      mutationProps,
      query,
      resourceConfig,
      variables,
      isCreate,
      afterFetch,
      emptyResourceItem = {},
      fetchPolicy,
    } = this.props;

    return (
      <Query
        query={query}
        variables={variables}
        skip={isCreate || this.state.hasFetched}
        onCompleted={this.onCompleted}
        fetchPolicy={fetchPolicy}
      >
        {({ error, loading }: QueryResult) => {
          if (!isCreate) {
            if (loading) {
              return (
                (messages && messages.loading) || (
                  <Typography>Loading.</Typography>
                )
              );
            }
            if (error) {
              return (
                (messages && messages.error) || <Typography>Error.</Typography>
              );
            }
            if (!this.state.data || !this.state.data[resourceConfig.singular]) {
              return (
                (messages && messages.nocontent) || (
                  <Typography>No content.</Typography>
                )
              );
            }
          }

          let resourceItem =
            (this.state.data && this.state.data[resourceConfig.singular]) ||
            emptyResourceItem;
          if (afterFetch) {
            resourceItem = afterFetch(resourceItem);
          }

          return (
            <Mutation {...(mutationProps as any)}>
              {(save: any) => (
                <EditContext.Provider
                  value={{
                    item: resourceItem,
                    resourceConfig,
                    onSave: this.onSave(save, resourceItem),
                  }}
                >
                  {this.renderChildren(
                    resourceItem,
                    this.onSave(save, resourceItem),
                  )}
                </EditContext.Provider>
              )}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

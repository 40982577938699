import slug from 'slug';
import { BrandKey } from '../__generated__/queries-person';
import { brandTitleByKey } from '@prismamedia/one-brandkey';

export const brandKeys = Object.values(BrandKey);

export const getBrandSlug = (key: BrandKey): string =>
  slug(brandTitleByKey[key].toLowerCase());

export const getBrandKeyFromSlug = (slugString: string): BrandKey => {
  const brandKey = brandKeys.find((key) => getBrandSlug(key) === slugString);

  if (!brandKey) {
    throw new Error(`getBrandKeyFromSlug: No match for slug "${slugString}"`);
  }
  return brandKey;
};

import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  Typography,
  IconButton,
} from '@mui/material';
import { Lock, MailOutline } from '@mui/icons-material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { paths } from '../../../../../routing';
import { useStyles } from './styles';

interface User {
  id: string;
  name: string;
  email: string;
  avatarUrl: string;
}

interface LockBackdrop {
  open?: boolean;
  user: User;
  mailParams: string;
}

export const LockBackDrop: React.FC<LockBackdrop> = ({
  user,
  mailParams,
  open = true,
}) => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <Dialog open={open}>
      <div className={classes.dialogContent}>
        <div className={classes.header}>
          <Lock />
          <Typography
            variant="subtitle1"
            color="inherit"
            className={classes.title}
          >
            Accès vérrouillé
          </Typography>
        </div>
        <Typography variant="caption" className={classes.body}>
          Une personne est déjà en train de travailler sur cette fiche.
          <br /> Revenez plus tard ou contactez la.
        </Typography>
        <div className={classes.userPanel}>
          <Avatar className={classes.avatar} src={user.avatarUrl} />
          <div>{user.name}</div>
          <IconButton
            className={classes.emailButton}
            onClick={() => {
              window.open(
                `https://mail.google.com/mail/u/0/?fs=1&to=${user.email}&tf=cm${mailParams}`,
              );
            }}
            size="large"
          >
            <MailOutline />
          </IconButton>
        </div>
      </div>
      <DialogActions>
        <Button color="primary" onClick={() => window.location.reload()}>
          Rafraichir
        </Button>
        <Button color="primary" onClick={() => history.push(paths.PERSON_LIST)}>
          Revenir à la liste
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { gql } from '@apollo/client';
import { PERSON_FRAMENT } from '../Fragments/personFragment.person.graphql';

export const GET_PERSON_QUERY = gql`
  query GetPerson($where: PersonUniqueFilterInput!) {
    person(where: $where) {
      ...Person
    }
  }
  ${PERSON_FRAMENT}
`;

import {
  Avatar,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
} from '@mui/material';
import { FC } from 'react';
import { BrandKey } from '../../../../__generated__/queries-person';
import { useGetPeopleHistory } from '../../../../apollo/person/Query/useGetPersonHistory.person.graphql';
import { BrandsIndicator } from '../../../../components/BrandsIndicator';
import { CircularLoader } from '../../../../components/Loader/Circular';
import { formatDateFr } from '../../../../utils/format';
import { PERSON_STATUS } from '../../../../utils/personStatus';

interface ArticleHistoryProps {
  id: string;
}

const renderText = (history: any) => {
  const userText = history.user?.name ? `par ${history.user?.name} :` : ':';
  const dateText = formatDateFr(history.createdAt, 'd MMMM yyyy à HH:mm');
  const statusLabel = PERSON_STATUS.find(
    (status) => status.value === history.status,
  )?.singularLabel;
  const statusText = statusLabel ? `- Status : ${statusLabel}` : '';
  return `Modifié ${userText} le ${dateText} ${statusText}`;
};

export const ArticleHistory: FC<ArticleHistoryProps> = ({ id }) => {
  const { data, loading } = useGetPeopleHistory(id);
  if (loading) {
    return <CircularLoader />;
  }
  const histories = data?.person?.histories;
  if (!histories) {
    return <></>;
  }
  const statusToExistingBrands = (
    statusByBrand: Record<string, { status: string }> | null,
  ) =>
    statusByBrand
      ? Object.entries(statusByBrand)
          .filter(([, value]) => value?.status === 'published')
          .map(([brand]) => brand as BrandKey)
      : [];

  return (
    <>
      <DialogTitle>Historique des modifications de l'article</DialogTitle>
      <DialogContent>
        <List>
          {histories.map((history, index) => (
            <ListItem data-testid={`history-item-${index}`} key={history.id}>
              <Grid container>
                <Grid item xs={1}>
                  {history.user?.avatarUrl && (
                    <Avatar src={history.user.avatarUrl} />
                  )}
                </Grid>
                <Grid item xs={8}>
                  {renderText(history)}
                </Grid>
                <Grid item xs={3}>
                  <BrandsIndicator
                    id={id.toString()}
                    existingBrands={statusToExistingBrands(
                      history.statusBioByBrandKey,
                    )}
                  />
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </>
  );
};

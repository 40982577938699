import { FormControlLabel, Grid, Switch, Tooltip } from '@mui/material';
import { OneMedia } from '@prismamedia/one-components';
import { isEqual } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';
import {
  BrandKey,
  PersonStatus,
} from '../../../../__generated__/queries-person';
import { MediaInput } from '../../../../components/MediaInput';
import {
  DraftField,
  InputGroup,
  useEditContext,
} from '../../../../one-crud-react';
import { getBrandBiographyIndex, getInitialBiography } from '../utils';

interface BrandTabProps {
  brandKey: BrandKey;
}

interface BrandTabState {
  hasContent: boolean;
  isPublished: boolean;
}

export const BrandTab: FC<BrandTabProps> = ({ brandKey }) => {
  const context = useEditContext();
  const { biographies } = context.item;

  // HERE TYPE
  const brandBiographyIndex = useMemo(
    () =>
      getBrandBiographyIndex(biographies, brandKey) > -1
        ? getBrandBiographyIndex(biographies, brandKey)
        : biographies.length,
    [brandKey, biographies],
  );

  const brandBiography =
    biographies[brandBiographyIndex] || getInitialBiography(brandKey);
  const medias = useMemo(
    () => (brandBiography.media ? [brandBiography.media] : []),
    [brandBiography.media],
  );

  const [state, updateState] = useState<BrandTabState>({
    hasContent: !!brandBiography.body || !!brandBiography.media,
    isPublished: brandBiography.status === PersonStatus.Published,
  });
  const previousState = usePrevious(state);

  const setState = useCallback(
    (data: React.SetStateAction<BrandTabState>) => {
      context.item['lastEditAt'] = Date.now();
      updateState(data);
    },
    [context.item],
  );

  const handleBiographyChange = (body: string) => {
    const hasContent = !!body || !!brandBiography.media;
    const isPublished = hasContent ? state.isPublished : false;

    biographies[brandBiographyIndex] = {
      ...brandBiography,
      body,
      status: isPublished ? PersonStatus.Published : PersonStatus.Draft,
    };

    setState({ hasContent, isPublished });
  };

  const handleMediaChange = (media?: OneMedia) => {
    const hasContent = !!brandBiography.body || !!media;
    const isPublished = hasContent ? state.isPublished : false;

    biographies[brandBiographyIndex] = {
      ...brandBiography,
      media,
      status: isPublished ? PersonStatus.Published : PersonStatus.Draft,
    };

    const newState = { hasContent, isPublished };

    if (previousState && !isEqual(previousState, newState)) {
      setState(newState);
    }
  };

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    biographies[brandBiographyIndex].status = event.target.checked
      ? PersonStatus.Published
      : PersonStatus.Draft;

    setState((prev) => ({ ...prev, isPublished: event.target.checked }));
  };

  useEffect(() => {
    setState({
      hasContent: !!brandBiography.body || !!brandBiography.media,
      isPublished: brandBiography.status === PersonStatus.Published,
    });

    context.item.biographies[brandBiographyIndex] = brandBiography;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandKey]);

  return (
    <Grid container>
      <Grid item xs={1} sm={1} />
      <Grid item xs={10} sm={10}>
        <Tooltip title="Activer la fiche bio sur cette marque" arrow>
          <FormControlLabel
            control={
              <Switch
                disabled={!state.hasContent}
                inputProps={
                  { 'data-testid': `switch-status-${brandKey}` } as any
                }
                checked={state.isPublished}
                onChange={handleStatusChange}
                name="checkedA"
              />
            }
            label="Activer"
          />
        </Tooltip>
        <MediaInput
          label="Illustration(s)"
          medias={medias}
          brandKey={brandKey}
          onChange={handleMediaChange}
        />
        <InputGroup style={{ marginTop: 10 }}>
          <DraftField
            label="Biographie"
            draft={brandBiography.body}
            sidebarRendererFn={() => <span />}
            brandKey={brandKey}
            onChange={handleBiographyChange}
          />
        </InputGroup>
      </Grid>
    </Grid>
  );
};

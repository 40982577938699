import * as React from 'react';
import { ContentState, DraftEntityType } from 'draft-js';

interface DecoratorLinkProps {
  entityKey: DraftEntityType;
  children: JSX.Element;
  contentState: ContentState;
}

const DecoratorLink: React.FC<DecoratorLinkProps> = ({
  entityKey,
  children,
  contentState,
}) => {
  const { url } = contentState.getEntity(entityKey).getData();
  return (
    <a className="editor__link" href={url} title={url}>
      {children}
    </a>
  );
};

// eslint-disable-next-line import/no-default-export
export default DecoratorLink;

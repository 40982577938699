import { StandardProps, Typography } from '@mui/material';
import * as React from 'react';

import { parsePathToString } from '../../../crud-core/utils/Path';

type TextFieldProps = {
  field: string;
  item?: any;
  label?: string;
  keyValue?: string;
  modifier?: (input: string) => string;
} & StandardProps<React.HTMLAttributes<HTMLDivElement>, ''>;

const TextField: React.FC<TextFieldProps> = ({
  className,
  field,
  item = {},
  style,
  modifier = (input: string) => input,
  keyValue,
}: TextFieldProps) => {
  const value = modifier(parsePathToString(field, item));
  return (
    <Typography
      data-testid={keyValue}
      component="span"
      variant="body2"
      className={className}
      style={style}
    >
      {value}
    </Typography>
  );
};

const styledComponent = TextField;
styledComponent.displayName = 'TextField';
// eslint-disable-next-line import/no-default-export
export default styledComponent;

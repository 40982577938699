import { Theme } from '@mui/material';

import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { getSpacing } from '@prismamedia/one-components';

const styles = (theme: Theme) =>
  createStyles({
    fieldsContainer: {
      padding: 10,
      margin: 0,
      marginTop: 40,
      width: '100%',
    },
    noShadow: {
      boxShadow: 'none',
      padding: 0,
    },
    fieldGroup: {
      position: 'relative',
      padding: 20,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 20,
    },
    fieldGroupTitle: {
      backgroundColor: theme.palette.common.white,
      position: 'absolute',
      top: -17,
    },
    suggestionStyle: {
      position: 'absolute',
      width: '100%',
      zIndex: 10,
      '& > ul': {
        listStyle: 'none',
        margin: 0,
        padding: 0,
      },
    },
    dragRelation: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.common.white,
      '& > div, & > span': {
        marginRight: 10,
        width: '30%',
        position: 'relative',
        fontSize: '1rem',
      },
    },
    addBtn: {
      marginTop: 40,
    },
    nomination: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.common.white,
      marginBottom: 10,
      padding: 10,
      position: 'relative',
      borderRadius: 20,
      '& > div': {
        width: '100%',
      },
      '& > button': {
        position: 'absolute',
        top: '-8px',
        right: '-2px',
        visibility: 'hidden',
      },
      '&:hover': {
        boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.3)',
        '& > button': {
          visibility: 'visible',
        },
      },
    },
    chipsInput: {
      '& [class^="react-autosuggest__container"]': {
        position: 'relative',
        width: '100%',
      },
    },
    statusButton: {
      marginTop: 0,
      backgroundColor: theme.palette.common.white,
      borderRadius: getSpacing(theme, 0.5),
      textTransform: 'uppercase',
      color: theme.palette.primary.main,
      marginLeft: getSpacing(theme, 2),
      fontSize: '1rem',
      '& .MuiInputBase-input': {
        padding: getSpacing(theme, 1),
      },
      '& label': {
        display: 'none',
      },
      '& .MuiInput-formControl': {
        marginTop: 0,
      },
      '& .MuiInput-underline:before, & .MuiInput-underline:after': {
        borderBottom: 0,
      },
      '& .MuiSelect-icon': {
        borderLeft: `1px solid ${theme.palette.grey[400]}`,
        width: '1.3em',
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 0,
      },
    },
  });

export type stylesProps = WithStyles<typeof styles>;
export const useStyles = makeStyles(styles);
export const wrapped = withStyles(styles);

import { gql } from '@apollo/client';

export const GET_DEGREES_QUERY = gql`
  query GetDegrees(
    $first: UnsignedInt!
    $where: DegreeFilterInput
    $skip: UnsignedInt
    $orderBy: [DegreeOrderingInput!]
  ) {
    degrees(first: $first, where: $where, skip: $skip, orderBy: $orderBy) {
      id
      title
    }
  }
`;

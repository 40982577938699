import { Resource, Item } from './genericTypes';

type DeleteMutationVariables = any | null;

export function computeDeleteMutationVariables(
  item: Item,
  resourceConfig: Resource,
): DeleteMutationVariables {
  if (resourceConfig.isRelationEnity) {
    const resourceAsKey =
      resourceConfig.linkedResources &&
      resourceConfig.linkedResources.find(
        (resource) => resource.field === resourceConfig.keyProperty,
      );
    if (resourceAsKey) {
      return {
        [resourceConfig.keyProperty]: {
          [resourceAsKey.resource.keyProperty]:
            item[resourceAsKey.field][resourceAsKey.resource.keyProperty],
        },
      };
    }
    return {
      [resourceConfig.keyProperty]: item[resourceConfig.keyProperty],
    };
  }
  return true;
}

import { JobResource } from './job.config';
import { Resource } from '../../../one-crud-react';

export const PersonJobResource: Resource = {
  singular: 'personJob',
  plural: 'personJobs',
  basePath: 'personJob',
  keyProperty: 'job',
  isRelationEnity: true,
  linkedResources: [
    {
      field: 'job',
      resource: JobResource,
    },
  ],
};

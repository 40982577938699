import { gql } from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';
import {
  LockPerson,
  LockPersonVariables,
} from '../../../__generated__/queries-person';
import { auth } from '../../../utils/auth';

const LOCK_PERSON = gql`
  mutation LockPerson($personId: UUIDv4, $lockerId: UUIDv4) {
    updatePerson(where: { id: $personId }, data: { lockerId: $lockerId }) {
      lockerId
    }
  }
`;

export const useLock = (personId: string) =>
  useMutation<LockPerson, LockPersonVariables>(LOCK_PERSON, {
    variables: {
      personId,
      lockerId: auth.user?.id,
    },
  });

import { gql } from '@apollo/client';
import { lightPersonFragment } from '../Fragments/personFragment.person.graphql';

export const GET_PEOPLE_QUERY = gql`
  query GetPeople(
    $first: UnsignedInt!
    $where: PersonFilterInput
    $skip: UnsignedInt
    $orderBy: [PersonOrderingInput!]
  ) {
    people(first: $first, where: $where, skip: $skip, orderBy: $orderBy) {
      ...LightPerson
    }
    personCount(where: $where)
  }
  ${lightPersonFragment}
`;

export const GET_PEOPLE_COUNT_QUERY = gql`
  query GetPeopleCount($where: PersonFilterInput) {
    personCount(where: $where)
  }
`;

import { PassionResource } from './passion.config';
import { Resource } from '../../../one-crud-react';

export const PersonPassionResource: Resource = {
  singular: 'personPassion',
  plural: 'personPassions',
  basePath: 'personPassion',
  keyProperty: 'passion',
  isRelationEnity: true,
  linkedResources: [
    {
      field: 'passion',
      resource: PassionResource,
    },
  ],
};

import { omit } from 'ramda';
import uuidv4 from 'uuid/v4';
import { CreateMutationVariablesData, Item, Resource } from './genericTypes';

export function computeCreateMutationVariables(
  item: Item,
  resourceConfig: Resource,
): CreateMutationVariablesData {
  let data = {
    ...item,
  } as CreateMutationVariablesData;
  if (
    resourceConfig.keyProperty !== 'none' &&
    typeof item[resourceConfig.keyProperty] !== 'string'
  ) {
    data[resourceConfig.keyProperty] = uuidv4();
  } else {
    data = {
      [resourceConfig.keyProperty]: item[resourceConfig.keyProperty],
    };
  }

  if (
    resourceConfig.linkedResources &&
    resourceConfig.linkedResources.length > 0
  ) {
    for (const linkedResource of resourceConfig.linkedResources) {
      if (typeof item[linkedResource.field] === 'undefined') {
        continue;
      }

      let createList = null;
      if (Array.isArray(item[linkedResource.field])) {
        createList = item[
          linkedResource.field
        ].map((field: Record<string, unknown>) =>
          computeCreateMutationVariables(field, linkedResource.resource),
        );
      } else {
        createList = computeCreateMutationVariables(
          item[linkedResource.field],
          linkedResource.resource,
        );
      }

      if (createList.id) {
        data[linkedResource.field] = {
          connect: createList,
        };
      } else {
        data[linkedResource.field] = {
          create: createList,
        };
      }
    }
  }
  return omit(['__typename'], data);
}

import {
  nullIfEmpty,
  ObjectArrayOrNull,
  ObjectOrObjectArrayOrNull,
  Resource,
} from '../../../one-crud-react';
import { AwardResource } from './award.config';

const customMutation = (
  updated: ObjectArrayOrNull,
  deleted: ObjectArrayOrNull,
  created: ObjectArrayOrNull,
) => {
  let createCustom: ObjectOrObjectArrayOrNull = null;
  let updateCustom: ObjectOrObjectArrayOrNull = null;
  let deleteCustom: ObjectOrObjectArrayOrNull = null;
  if (updated) {
    updateCustom = nullIfEmpty(
      updated.map((field: Record<string, any>) => ({
        data: {
          work: field.work,
          year: parseInt(field.year, 10),
          award: {
            connect: {
              id: field.award.id,
            },
          },
        },
        where: {
          id: field.id,
        },
      })),
    );
  }

  if (deleted) {
    deleteCustom = nullIfEmpty(
      deleted &&
        deleted.map((field: Record<string, any>) => ({
          year: parseInt(field.year, 10),
          award: {
            id: field.award.id,
          },
        })),
    );
  }
  if (created) {
    createCustom = nullIfEmpty(
      created.map((field: Record<string, any>) => ({
        id: field.id,
        work: field.work,
        year: parseInt(field.year, 10),
        award: {
          connect: {
            id: field.award.id,
          },
        },
      })),
    );
  }

  return {
    deleteCustom,
    createCustom,
    updateCustom,
    connectCustom: null,
  };
};

export const NominationResource: Resource = {
  singular: 'Nomination',
  plural: 'Nominations',
  basePath: 'nomination',
  keyProperty: 'id',
  isRelationEnity: true,
  customMutation,
  linkedResources: [
    {
      field: 'award',
      resource: AwardResource,
    },
  ],
};

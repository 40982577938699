import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1),
  },
  chipsField: {},
  chipsWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    flexWrap: 'wrap',
    paddingTop: theme.spacing(1),
  },
  label: {
    fontSize: theme.typography.caption.fontSize,
  },
  textField: {},
}));

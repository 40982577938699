import { CompositeDecorator } from 'draft-js';
import { createTypeStrategy } from 'megadraft';

// Decorators components
import DecoratorLink from './Link';

export const decorators = new CompositeDecorator([
  {
    strategy: createTypeStrategy('LINK'),
    component: DecoratorLink,
  },
]);

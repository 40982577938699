import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    border: 'none',
  },
  'variant-horizontal': {
    flexDirection: 'row',
    '& > *': {
      marginLeft: theme.spacing(1),
    },
    '& > *:first-child': {
      marginLeft: 0,
    },
  },
  'variant-vertical': {
    flexDirection: 'column',
  },
}));

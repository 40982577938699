import React from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import uuidv4 from 'uuid/v4';

import { wrapped, stylesProps } from '../styles';
import { Relation, RelationComponent } from './Relation';
import { BasicContainer } from './BasicContainer';
import { useResource } from './useResource';

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: undefined | DraggingStyle | NotDraggingStyle,
): React.CSSProperties => ({
  boxShadow: isDragging ? '0 0 2px 0 rgba(0, 0, 0, 0.2)' : 'none',
  ...draggableStyle,
});

const reorder = (
  list: Relation[],
  startIndex: number,
  endIndex: number,
): Relation[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result.map((item, i) => ({ ...item, order: i }));
};

const RelationsFieldsWithoutStyle: React.FC<stylesProps> = ({ classes }) => {
  const {
    resources,
    setResources,
    onDelete,
    onUpdate,
    onChange,
  } = useResource<Relation>('relationshipTo');

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      resources,
      result.source.index,
      result.destination.index,
    );
    onChange(items);
  };

  const onAdd = (e: React.MouseEvent) => {
    e.stopPropagation();
    const emptyRelation: Relation = {
      order: resources.length,
      personTo: { id: uuidv4(), fullName: '' },
      relationshipTitle: { id: uuidv4(), title: '', slug: '' },
    };
    setResources([...resources, emptyRelation]);
  };

  return (
    <BasicContainer keyValue="relations" title="Relations" onAdd={onAdd}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {resources.map((relation: Relation, index: number) => (
                <Draggable
                  key={relation.personTo.id}
                  draggableId={relation.personTo.id}
                  index={index}
                >
                  {(itemProvided: any, snapshot) => (
                    <div
                      data-testid={`relationship-item-${index}`}
                      ref={itemProvided.innerRef}
                      {...itemProvided.draggableProps}
                      {...itemProvided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        itemProvided.draggableProps.style,
                      )}
                      className={classes.dragRelation}
                    >
                      <RelationComponent
                        relation={relation}
                        onDelete={onDelete(index)}
                        onUpdate={onUpdate(index)}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </BasicContainer>
  );
};

export const RelationsFields = wrapped(RelationsFieldsWithoutStyle);

import * as React from 'react';
import { path } from 'ramda';
import { StandardProps, TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';

import { useEditContextForInput } from '../useEditContextForInput';
import { Omit } from '../../../crud-core/utils/TypescriptUtils';

type TextInputProps = {
  field: string;
  label?: string;
  item?: any;
  showLabel?: boolean;
  onChange?: (field: string, value: any) => void;
  textFieldProps?: TextFieldProps;
} & Omit<StandardProps<React.HTMLAttributes<HTMLDivElement>, ''>, 'onChange'>;

const TextInput: React.FC<TextInputProps> = ({
  className,
  field,
  showLabel = true,
  label,
  style,
  textFieldProps,
  onChange,
  item,
}: TextInputProps) => {
  // If we provide an onChange function it prevails from using context
  let value: string | undefined;
  let props: TextFieldProps;
  const {
    fieldValue,
    onChange: onInputChange,
  } = useEditContextForInput<string>(field);
  if (onChange) {
    value = path(field.split('.'), item) || '';
    props = {
      onChange: (event) => onChange(field, event.target.value),
    };
  } else {
    value = fieldValue || '';
    props = {
      onChange: (event) => onInputChange(event.target.value || null),
    };
  }

  return (
    <TextField
      inputProps={{ 'data-testid': `text-input-${field}` }}
      label={showLabel ? label || field : ''}
      value={value}
      margin="normal"
      className={className}
      style={style}
      {...props}
      {...textFieldProps}
    />
  );
};

TextInput.displayName = 'TextInput';
// eslint-disable-next-line import/no-default-export
export default TextInput;

import { differenceInMinutes } from 'date-fns';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useLock } from '../../../../apollo/person/Mutations/lock.person.graphql';
import { useUnlock } from '../../../../apollo/person/Mutations/unlock.person.graphql';
import { useEditContext } from '../../../../one-crud-react';
import { paths } from '../../../../routing';
import { useInterval } from '../../../../utils/useInterval';
import { LockBackDrop } from './LockBackDrop';
import { LockRelease } from './LockRelease';

const RELEASE_TIME_IN_MINUTE = 30;
const FETCH_LOCK_TIME_IN_MINUTE = 4;
interface LockDialogsParams {
  personId: string;
}

enum CurrentMode {
  LockMode = 'LOCK',
  ReleaseMode = 'RELEASE',
}

export const LockDialogs: React.FC<LockDialogsParams> = ({ personId }) => {
  const context = useEditContext();
  const [lock] = useLock(personId);
  const [unlock] = useUnlock(personId);
  const history = useHistory();
  const [currentMode, setDisplayMode] = useState<CurrentMode>(
    CurrentMode.LockMode,
  );
  const couldFetchLocks = personId && !context.item.isLocked;
  const lockFn = () => {
    if (couldFetchLocks) {
      lock().catch((err) => {
        console.warn('Error durant le lock', err);
      });
    }
  };
  const unlockFn = () => {
    if (couldFetchLocks) {
      unlock().catch((err) => {
        console.warn('Error durant le unlock', err);
      });
    }
    return null;
  };

  // Every 4 minutes check if the user should release the lock or refresh it
  useInterval(() => {
    const hasNotEditTheForm =
      (!context.item.isLocked && !context.item.lastEditAt) ||
      differenceInMinutes(Date.now(), context.item.lastEditAt) >=
        RELEASE_TIME_IN_MINUTE;

    if (hasNotEditTheForm) {
      setDisplayMode(CurrentMode.ReleaseMode);
    } else {
      setDisplayMode(CurrentMode.LockMode);
      lockFn();
    }
  }, FETCH_LOCK_TIME_IN_MINUTE * 60000);

  // Add unlock event launch when the user leave the page
  React.useEffect(() => {
    if (couldFetchLocks) {
      window.addEventListener('unload', unlockFn);
      return () => {
        window.addEventListener('unload', unlockFn);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couldFetchLocks]);

  // Lock person after creation
  React.useEffect(() => {
    lockFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personId]);

  const formatUrl = (text: string) =>
    text.trim().replace(/\r/g, '%20').replace(/\n/g, '%0A');
  const fullName = `${context.item.firstName || ''} ${
    context.item.lastName || ''
  }`;

  const mailBody = formatUrl(`
Bonjour,

Ce mail est généré automatiquement. Il vous est adressé car je souhaite accéder à la fiche de ${fullName}.

Pouvez-vous libérer l'édition de cette fiche en cliquant sur ce lien s'il vous plaît ?
${config.APP_BASE_URL}/person/unlock/${personId}

Cordialement
`);
  const mailSubject = formatUrl(`Demande d'accès à la fiche de ${fullName}`);

  return (
    <>
      {currentMode === CurrentMode.LockMode && context.item.isLocked && (
        <LockBackDrop
          mailParams={`&body=${mailBody}&su=${mailSubject}`}
          user={context.item.lockedBy}
        />
      )}
      {currentMode === CurrentMode.ReleaseMode && (
        <LockRelease
          onClose={() => {
            setDisplayMode(CurrentMode.LockMode);
          }}
          onRelease={() => {
            unlock().finally(() => {
              history.push(paths.PERSON_LIST);
            });
          }}
        />
      )}
    </>
  );
};

import { Button, Chip, Dialog, DialogActions, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useInterval } from '../../../../../utils/useInterval';
import { useStyles } from './styles';

interface LockReleaseParams {
  onClose: () => void;
  onRelease: () => void;
}

export const LockRelease: React.FC<LockReleaseParams> = ({
  onClose,
  onRelease,
}) => {
  const WAITING_TIME = 60;
  const DIALOG_TITLE = "Période d'inactivité";
  const [countDown, setCountDown] = useState(WAITING_TIME);
  useInterval(() => {
    if (countDown > 0) {
      setCountDown(countDown - 1);
    } else {
      onRelease();
    }
  }, 1000);

  useEffect(() => {
    if (Notification.permission === 'granted') {
      new Notification(DIALOG_TITLE, { body: 'ONE-people' });
    }
  }, [DIALOG_TITLE]);

  const classes = useStyles();

  return (
    <Dialog open={true}>
      <div className={classes.dialogContent}>
        <div className={classes.header}>
          <Typography
            variant="subtitle1"
            color="inherit"
            className={classes.title}
          >
            {DIALOG_TITLE}
          </Typography>
        </div>
        <Typography variant="caption" className={classes.body}>
          Nous remarquons que vous êtes inactif sur cette page depuis plus de 30
          minutes. Pour éviter de bloquer l'édition à d'autre, vous allez être
          déconnecté de la fiche dans :
        </Typography>
        <Chip
          className={classes.secondDisplay}
          label={`${countDown} secondes`}
        />
      </div>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            onRelease();
          }}
        >
          Retour à la vue liste
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onClose();
          }}
        >
          Rester sur la page
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { Resource } from '../../../one-crud-react';
import { NominationResource } from './nominations.config';
import { PersonJobResource } from './personJob.config';
import { PersonStudyResource } from './personStudy.config';
import { PersonDegreeResource } from './personDegree.config';
import { PersonPassionResource } from './PersonPassion.config';
import { PersonTitleResource } from './PersonTitle.config';
import { PersonRelationshipToResource } from './personRelationshipTo.config';
import { PersonCountryResource } from './personCountry.config';

export const PersonResource: Resource = {
  singular: 'person',
  plural: 'people',
  basePath: 'person',
  keyProperty: 'id',
  linkedResources: [
    {
      field: 'jobs',
      resource: PersonJobResource,
    },
    {
      field: 'nominations',
      resource: NominationResource,
    },
    {
      field: 'schools',
      resource: PersonStudyResource,
    },
    {
      field: 'degrees',
      resource: PersonDegreeResource,
    },
    {
      field: 'passions',
      resource: PersonPassionResource,
    },
    {
      field: 'titles',
      resource: PersonTitleResource,
    },
    {
      field: 'relationshipTo',
      resource: PersonRelationshipToResource,
    },
    {
      field: 'countries',
      resource: PersonCountryResource,
    },
  ],
};

import * as React from 'react';
import { lensPath, view, set, Lens } from 'ramda';
import { useEditContext } from '../../crud-graphql-containers';

export function useEditContextForInput<T>(field: string) {
  const defaultValue = (val: Record<string, unknown> | undefined | string) => {
    if (typeof val === 'string') {
      return '';
    }
    if (Array.isArray(val)) {
      return [];
    }
    return '';
  };

  const context = useEditContext();

  const lensToItem: Lens = lensPath(field.split('.'));
  const initialValue: any = view(lensToItem, context.item);
  const value = (initialValue || defaultValue(initialValue)) as T;
  const [fieldValue, setFieldValue] = React.useState<T | null>(value);

  React.useEffect(() => {
    setFieldValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field]);

  const onChange = (val: T | null) => {
    setFieldValue(val);
    context.item = set(lensToItem, val, context.item);
    context.item['lastEditAt'] = Date.now();
  };

  return {
    fieldValue,
    setFieldValue,
    onChange,
  };
}

import { gql } from '@apollo/client';

export const GET_COUNTRY_QUERY = gql`
  query GetCountry(
    $first: UnsignedInt!
    $where: CountryFilterInput
    $skip: UnsignedInt
  ) {
    countries(first: $first, where: $where, skip: $skip, orderBy: title_ASC) {
      id
      title
    }
  }
`;

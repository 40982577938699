import { path } from 'ramda';
import { isStringOrFalsy } from './TypescriptUtils';

export function parsePathToArray(
  pathToProp?: string,
  obj?: Record<string, unknown>,
  defaultValue: string | null = null,
): (string | Record<string, unknown>)[] {
  const value = pathToProp ? path(pathToProp.split('.'), obj) : defaultValue;

  return Array.isArray(value) ? value : [];
}

export function parsePathToString(
  pathToProp?: string,
  obj?: any,
  defaultValue: string | null | Record<string, unknown> = null,
): string {
  const value = pathToProp ? path(pathToProp.split('.'), obj) : defaultValue;

  return isStringOrFalsy(value) ? value : JSON.stringify(value);
}

import { ReactElement } from 'react';
import { isEmpty } from 'ramda';

export type ObjectOrObjectArrayOrNull = any | ObjectArrayOrNull;
export type ObjectArrayOrNull = any[] | null;
export type ObjectArray = any[];
export type ObjectOrNull = any | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export const isStringOrFalsy = (x: any): x is string => {
  return typeof x === 'string' || !x;
};

export const isObjectTruthy = (
  obj: Record<string, unknown> | null,
): obj is Record<string, unknown> => {
  return Boolean(obj && Object.keys(obj).length > 0);
};

export const isReactComponent = (x: any): x is ReactElement<any> => {
  // tslint:disable-next-line
  return (<ReactElement<any>>x).props !== undefined;
};

export const nullIfEmpty = (arr: any) => {
  return Array.isArray(arr) && isEmpty(arr) ? null : arr;
};

/* tslint:disable */
import * as R from 'ramda';
import uuidv4 from 'uuid/v4';
import type { GetPerson_person } from '../../../__generated__/queries-person';
import {
  isObjectTruthy,
  nullIfEmpty,
  ObjectOrObjectArrayOrNull,
} from '../../crud-core/utils/TypescriptUtils';
import { computeConnectMutationVariables } from './computeConnectMutationVariables';
import { computeCreateMutationVariables } from './computeCreateMutationVariables';
import { computeDeleteMutationVariables } from './computeDeleteMutationVariables';
import { diffArray } from './diffArray';
import { diffObject } from './diffObject';
import { Item, Resource, UpdateMutationVariables } from './genericTypes';

export function computeUpdateMutationVariables(
  item: Item,
  resourceConfig: Resource,
  previousItem: Item,
  isCreate?: boolean,
): UpdateMutationVariables | null {
  let data: any = item;
  const dataToOmitFromCreate: any = [];
  const ommittedRelationForCreate: string[] = [];
  let where: { [s: string]: string | { [s: string]: string } } = {};

  if (
    !isCreate &&
    (!item[resourceConfig.keyProperty] ||
      !previousItem[resourceConfig.keyProperty])
  ) {
    throw new Error(
      `Property ${resourceConfig.keyProperty} is missing in ${resourceConfig.singular}. It is required for an update mutation.`,
    );
  }

  if (
    !isCreate &&
    !R.propEq(
      resourceConfig.keyProperty,
      previousItem[resourceConfig.keyProperty],
    )(item)
  ) {
    throw new Error(
      `Given ${resourceConfig.plural} don't have the same ${resourceConfig.keyProperty} property. They must be the same to perform an update mutation.`,
    );
  }

  if (
    resourceConfig.linkedResources &&
    resourceConfig.linkedResources.length > 0
  ) {
    for (const linkedResource of resourceConfig.linkedResources) {
      if (
        typeof item[linkedResource.field] === 'undefined' &&
        typeof previousItem[linkedResource.field] === 'undefined'
      ) {
        continue;
      }

      let createList: ObjectOrObjectArrayOrNull = [];
      let connectList: ObjectOrObjectArrayOrNull = [];
      let updateList: ObjectOrObjectArrayOrNull = [];
      let deleteList: ObjectOrObjectArrayOrNull = [];

      if (Array.isArray(item[linkedResource.field])) {
        const { updated, deleted, created } = diffArray(
          data[linkedResource.field],
          previousItem ? previousItem[linkedResource.field] : [],
          linkedResource.resource,
        );

        if (linkedResource.resource.customMutation) {
          const {
            deleteCustom,
            createCustom,
            updateCustom,
            connectCustom,
          } = linkedResource.resource.customMutation(
            updated,
            deleted,
            created,
            linkedResource.resource,
          );
          createList = createCustom;
          updateList = updateCustom;
          deleteList = deleteCustom;
          connectList = connectCustom;
        } else {
          createList =
            created &&
            nullIfEmpty(
              created
                .filter((i) => !R.has(resourceConfig.keyProperty)(i))
                .map((field: Record<string, unknown>) =>
                  computeCreateMutationVariables(
                    field,
                    linkedResource.resource,
                  ),
                ),
            );

          connectList =
            created &&
            nullIfEmpty(
              created
                .filter(R.has(resourceConfig.keyProperty))
                .map((field: Record<string, unknown>) =>
                  computeConnectMutationVariables(
                    field,
                    linkedResource.resource,
                  ),
                ),
            );

          updateList =
            updated &&
            updated.map((field: Record<string, unknown>, index: number) =>
              computeUpdateMutationVariables(
                field,
                linkedResource.resource,
                previousItem && previousItem[linkedResource.field][index],
              ),
            );

          deleteList =
            deleted &&
            deleted.map((field: Record<string, unknown>) =>
              computeDeleteMutationVariables(field, linkedResource.resource),
            );
        }
      } else {
        const { updated, deleted, created } = diffObject(
          item[linkedResource.field],
          previousItem ? previousItem[linkedResource.field] : [],
          linkedResource.resource,
        );

        createList =
          created &&
          !R.has(resourceConfig.keyProperty, created) &&
          computeCreateMutationVariables(created, linkedResource.resource);

        connectList =
          created &&
          R.has(resourceConfig.keyProperty, created) &&
          computeConnectMutationVariables(created, linkedResource.resource);

        updateList =
          updated &&
          computeUpdateMutationVariables(
            updated,
            linkedResource.resource,
            previousItem && previousItem[linkedResource.field],
          );

        deleteList =
          deleted &&
          computeDeleteMutationVariables(deleted, linkedResource.resource);
      }

      if (createList || connectList || updateList || deleteList) {
        data[linkedResource.field] = {};
        if (createList && nullIfEmpty(createList) !== null) {
          data[linkedResource.field] = {
            create: createList,
            ...data[linkedResource.field],
          };
        } else {
          dataToOmitFromCreate.push(linkedResource.field);
        }
        if (connectList && nullIfEmpty(connectList) !== null) {
          data[linkedResource.field] = {
            connect: connectList,
            ...data[linkedResource.field],
          };
        }
        if (updateList && nullIfEmpty(updateList) !== null) {
          data[linkedResource.field] = {
            update: updateList,
            ...data[linkedResource.field],
          };
        }
        if (deleteList && nullIfEmpty(deleteList) !== null) {
          data[linkedResource.field][
            linkedResource.resource.isRelationEnity ? 'delete' : 'disconnect'
          ] = deleteList;

          if (
            (data[linkedResource.field]['disconnect'] &&
              data[linkedResource.field]['disconnect'] === true) ||
            data[linkedResource.field]['delete']
          ) {
            ommittedRelationForCreate.push(linkedResource.field);
          }
        }
      } else {
        data = R.omit([linkedResource.field], data);
      }
    }
  }

  if (isObjectTruthy(data)) {
    const keyProperty = item[resourceConfig.keyProperty] || uuidv4();

    where = {
      [resourceConfig.keyProperty]:
        item[resourceConfig.keyProperty] || keyProperty,
    };
    if (!data[resourceConfig.keyProperty]) {
      data[resourceConfig.keyProperty] = keyProperty;
    }
    const excludedFIelds = [
      '__typename',
      'updatedAt',
      'deletedAt',
      'createdAt',
      'isLocked',
      'lockedBy',
      'lockedAt',
      'lastEditAt',
    ];

    let update = R.omit([resourceConfig.keyProperty, ...excludedFIelds], data);

    let create = R.omit(
      [...excludedFIelds, ...ommittedRelationForCreate],
      R.omit(dataToOmitFromCreate, data),
    );

    // Person Biographies
    if ((data.biographies as any)?.length) {
      const { biographies } = (data as unknown) as GetPerson_person;
      const serializedPersonBiographies = biographies.map((biography) => ({
        ...R.omit(['__typename', 'id', 'createdAt', 'updatedAt'], biography),
        media: biography.media || null,
      }));

      update = {
        ...update,
        biographies: {
          upsert: serializedPersonBiographies.map((personBiography) => ({
            where: { brandKey: personBiography.brandKey },
            create: personBiography,
            update: R.omit(['brandKey'], personBiography),
          })),
        },
      };

      create = {
        ...create,
        biographies: {
          create: serializedPersonBiographies,
        },
      };
    }

    return { update, create, where };
  }

  return null;
}

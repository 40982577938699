import { InputLabel } from '@mui/material';
import {
  getImagePlugin,
  IframelyMedia,
  MultimediaManager,
  OneMedia,
  useNotification,
} from '@prismamedia/one-components';
import React, { FC, useEffect, useState } from 'react';
import { useSearchImageSourceCategories } from '../../apollo/web/getSources.web.graphql';
import { uploadImages } from '../../utils/uploadImage';
import { fetchFocusPoint } from './focuspoint';

interface MediaInputProps {
  brandKey: string;
  label: string;
  onChange: (media?: OneMedia) => void;
  medias: OneMedia[];
}

export const MediaInput: FC<MediaInputProps> = ({
  brandKey,
  label,
  medias,
  onChange,
}) => {
  const { pushNotification } = useNotification();
  const [mediasState, setMediaState] = useState<OneMedia[]>(medias);

  useEffect(() => {
    setMediaState(medias);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medias]);

  const getLastMedia = (mediaList: OneMedia[]) => {
    if (mediaList.length) {
      return [mediaList[mediaList.length - 1]];
    }
    return mediaList;
  };

  useEffect(() => {
    onChange(getLastMedia(mediasState)[0]);
  }, [mediasState, onChange]);

  const postImages = async (files: File[]): Promise<IframelyMedia[]> => {
    const iframelys = await uploadImages(files, brandKey.toLowerCase());
    if (iframelys === false) {
      return [];
    }
    return iframelys.filter(
      (iframely) => iframely.constructor !== Error,
    ) as IframelyMedia[];
  };

  const disableImgWidth = true;

  const ImagePlugin = getImagePlugin({
    postImages,
    pushNotification,
    disableImgWidth,
  });
  const fetchSource = useSearchImageSourceCategories();
  return (
    <>
      {label && <InputLabel style={{ marginTop: '32px' }}>{label}</InputLabel>}
      <MultimediaManager
        fetchFocusPoint={fetchFocusPoint}
        mediaHeight={300}
        fieldsRequired
        fetchSources={fetchSource}
        medias={getLastMedia(mediasState)}
        setMedias={setMediaState}
        plugins={[ImagePlugin]}
      />
    </>
  );
};

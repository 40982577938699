import { LoginPage, UserDialog } from '@prismamedia/one-components';
import React from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import { getUsers } from './apollo/auth/users.auth.graphql';
import { ErrorPage } from './components/ErrorPage';
import { PersonPage } from './pages/Person';
import { auth } from './utils/auth';
import { useLocationChange } from './utils/locationChange';

export const replaceParams = (
  path: string,
  params: Record<string, any>,
): string => {
  const keys = Object.entries(params);
  let newPath = path;
  keys.forEach((k) => {
    newPath = newPath.replace(`:${k[0]}`, k[1]);
  });
  return newPath.replace(/\(.*\)/, '');
};

export enum paths {
  PERSON_NEW = '/person/new',
  PERSON_UNLOCK = '/person/unlock/:id',
  PERSON_LIST = '/person/list',
  PERSON_EDIT = '/person/:id',
  LOGIN = '/login',
}

type PrivateRouteProps = {
  component: React.ComponentType<RouteComponentProps<any>>;
} & RouteProps;

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => (
  <Route
    {...rest}
    render={(props) =>
      auth.isAuthenticated() ? (
        <>
          <Component {...props} />
          <UserDialog searchUsers={getUsers} auth={auth} />
        </>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const Routing: React.FC = () => {
  useLocationChange();
  return (
    <Switch>
      <Route
        exact
        path={paths.LOGIN}
        render={(props) => (
          <LoginPage
            {...props}
            homePath="/"
            loginPath={paths.LOGIN}
            auth={auth}
          />
        )}
      />
      <PrivateRoute path="/person" component={PersonPage} />
      <PrivateRoute path="/error" component={ErrorPage} />
      <PrivateRoute component={() => <Redirect to={paths.PERSON_LIST} />} />
    </Switch>
  );
};

export const Router = withRouter(Routing);

import { useApolloClient, useQuery } from '@apollo/react-hooks';
import {
  GetPeople,
  GetPeopleCount,
  GetPeopleCountVariables,
  GetPeopleVariables,
  PersonFilterInput,
  PersonOrderingInput,
} from '../../../__generated__/queries-person';
import {
  GET_PEOPLE_COUNT_QUERY,
  GET_PEOPLE_QUERY,
} from './getPeopleQuery.person.graphql';

interface useGetPeopleProps {
  queryFilter: PersonFilterInput;
  orderBy: any;
  first?: number;
  skip?: number;
}

export const useGetPeople = ({
  queryFilter,
  first = 50,
  skip = 0,
  orderBy = [PersonOrderingInput.updatedAt_DESC],
}: useGetPeopleProps) =>
  useQuery<GetPeople, GetPeopleVariables>(GET_PEOPLE_QUERY, {
    variables: {
      where: queryFilter,
      orderBy,
      first,
      skip,
    },
  });

export const usePeopleCountGetter = () => {
  const client = useApolloClient();
  return (where: PersonFilterInput) =>
    client.query<GetPeopleCount, GetPeopleCountVariables>({
      query: GET_PEOPLE_COUNT_QUERY,
      variables: { where },
    });
};

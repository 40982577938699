import React from 'react';
import { ApolloConsumer } from 'react-apollo';
import { GET_DEGREES_QUERY } from '../../../../apollo/person/Query/getDegreesQuery.person.graphql';
import { GET_PASSIONS_QUERY } from '../../../../apollo/person/Query/getPassionsQuery.person.graphql';
import { GET_SCHOOLS_QUERY } from '../../../../apollo/person/Query/getSchoolsQuery.person.graphql';
import { GET_TITLES_QUERY } from '../../../../apollo/person/Query/getTitlesQuery.person.graphql';
import { ChipsInput, InputGroup, TextInput } from '../../../../one-crud-react';
import { simpleQuery } from '../../../../utils/simpleQuery';
import {
  GetDegrees,
  GetPassions,
  GetSchools,
  GetTitles,
} from '../../../../__generated__/queries-person';
import { DegreeResource } from '../../config/degree.config';
import { PassionResource } from '../../config/passion.config';
import { SchoolResource } from '../../config/school.config';
import { TitleResource } from '../../config/title.config';
import { stylesProps, wrapped } from '../styles';
import { BasicContainer } from './BasicContainer';

const CVFieldsWithoutStyle: React.FC<stylesProps> = ({ classes }) => (
  <BasicContainer keyValue="resume" title="CV">
    <ApolloConsumer>
      {(client) => (
        <>
          <InputGroup>
            <TextInput
              field="residence"
              label="Lieu de résidence"
              textFieldProps={{ fullWidth: true }}
            />
          </InputGroup>
          <InputGroup>
            <ChipsInput
              field="schools"
              label="Etude(s)"
              pathToLabel="school.title"
              pathToValue="school.id"
              textFieldProps={{
                placeholder: 'Ajouter +',
              }}
              className={classes.chipsInput}
              autoSuggestFieldProps={{
                fetchSuggestions: (value) =>
                  simpleQuery<GetSchools>({
                    client: client as any,
                    value,
                    query: GET_SCHOOLS_QUERY,
                    resource: SchoolResource,
                    mapper: (res) => ({ id: res.id, label: res.title }),
                  }),
                suggestionStyle: classes.suggestionStyle,
                keepSelectedValue: false,
              }}
            />
          </InputGroup>
          <InputGroup>
            <ChipsInput
              field="degrees"
              label="Diplôme(s)"
              pathToLabel="degree.title"
              pathToValue="degree.id"
              textFieldProps={{
                placeholder: 'Ajouter +',
              }}
              className={classes.chipsInput}
              autoSuggestFieldProps={{
                fetchSuggestions: (value) =>
                  simpleQuery<GetDegrees>({
                    client: client as any,
                    value,
                    query: GET_DEGREES_QUERY,
                    resource: DegreeResource,
                    mapper: (res) => ({ id: res.id, label: res.title }),
                  }),
                suggestionStyle: classes.suggestionStyle,
                keepSelectedValue: false,
              }}
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              field="website"
              label="Site officiel"
              textFieldProps={{ fullWidth: true }}
            />
          </InputGroup>
          <InputGroup>
            <ChipsInput
              field="passions"
              label="Passions(s)"
              pathToLabel="passion.title"
              pathToValue="passion.id"
              textFieldProps={{
                placeholder: 'Ajouter +',
              }}
              className={classes.chipsInput}
              autoSuggestFieldProps={{
                fetchSuggestions: (value) =>
                  simpleQuery<GetPassions>({
                    client: client as any,
                    value,
                    query: GET_PASSIONS_QUERY,
                    resource: PassionResource,
                    mapper: (res) => ({ id: res.id, label: res.title }),
                  }),
                suggestionStyle: classes.suggestionStyle,
                keepSelectedValue: false,
              }}
            />
          </InputGroup>
          <InputGroup>
            <ChipsInput
              field="titles"
              label="Titre(s) de noblesse"
              pathToLabel="title.title"
              pathToValue="title.id"
              textFieldProps={{
                placeholder: 'Ajouter +',
              }}
              className={classes.chipsInput}
              autoSuggestFieldProps={{
                fetchSuggestions: (value) =>
                  simpleQuery<GetTitles>({
                    client: client as any,
                    value,
                    query: GET_TITLES_QUERY,
                    resource: TitleResource,
                    mapper: (res) => ({ id: res.id, label: res.title }),
                  }),
                suggestionStyle: classes.suggestionStyle,
                keepSelectedValue: false,
              }}
            />
          </InputGroup>
        </>
      )}
    </ApolloConsumer>
  </BasicContainer>
);

export const CVFields = wrapped(CVFieldsWithoutStyle);

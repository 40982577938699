import { JobResource } from './job.config';
import { Resource } from '../../../one-crud-react';

export const PersonCountryResource: Resource = {
  singular: 'personCountry',
  plural: 'personCountries',
  basePath: 'personCountry',
  keyProperty: 'country',
  isRelationEnity: true,
  linkedResources: [
    {
      field: 'country',
      resource: JobResource,
    },
  ],
};

import * as React from 'react';

import { Resource, Item } from '../Utils/genericTypes';

interface EditContextType {
  item: Item;
  resourceConfig: Resource;
  onSave: (item: Record<string, unknown>) => Promise<null | void>;
}

export const EditContext = React.createContext<EditContextType | null>(null);

export function useEditContext(): EditContextType {
  const context = React.useContext(EditContext);
  if (!context) {
    throw new Error(
      'You try to use EditContext outside of ResourceEditContainer. Please wrap your components inside this container',
    );
  }

  return context;
}

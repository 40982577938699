import {
  ApolloClient,
  ApolloLink,
  defaultDataIdFromObject,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getOperationName } from '@apollo/client/utilities';
import { initialize } from 'react-ga';
import { GET_USERS } from './apollo/auth/users.auth.graphql';
import { GET_BRANDKEYS } from './apollo/web/brandKeys.web.graphql';
import { SEARCH_IMAGE_SOURCES_CATEGORIES } from './apollo/web/getSources.web.graphql';
import { auth } from './utils/auth';

initialize(config.GA_ID, {
  testMode: process.env.NODE_ENV === 'test',
});

const httpLink = new HttpLink({
  uri: config.API_ONE_PEOPLE,
});

const identifyRequestLink = new ApolloLink((operation, forward) => {
  operation.setContext(() => ({
    uri: `${config.API_ONE_PEOPLE}?${operation.operationName}`,
  }));
  if (forward) {
    return forward(operation);
  }

  return null;
});

const authLink = setContext(async (operation, context) => {
  let baseUri;
  switch (operation.operationName) {
    case getOperationName(GET_USERS):
      baseUri = config.API_ONE_AUTH;
      break;
    case getOperationName(SEARCH_IMAGE_SOURCES_CATEGORIES):
    case getOperationName(GET_BRANDKEYS):
      baseUri = config.API_ONE_WEB;
      break;
    default:
      baseUri = config.API_ONE_PEOPLE;
      break;
  }

  const jwt = await auth.getJwt();
  return {
    ...context,
    headers: {
      ...context?.headers,
      Authorization: `Bearer ${jwt}`,
      'x-client-name': `${config.ENV}-PEOPLE-REACT`,
    },
    uri: `${baseUri}?${operation.operationName}`,
  };
}) as any;

const cache = new InMemoryCache({
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      default:
        return defaultDataIdFromObject(object) || undefined; // fall back to default handling
    }
  },
});
export const client = new ApolloClient<NormalizedCacheObject>({
  link: ApolloLink.from([identifyRequestLink, authLink, httpLink]),
  cache,
  resolvers: {},
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all',
    },
    query: {
      errorPolicy: 'all',
    },
  },
  name: 'BONE-PERSON-REACT',
  version: config.VERSION,
});

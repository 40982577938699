import { gql } from '@apollo/client';

export const GET_AWARDS_QUERY = gql`
  query GetAwards(
    $first: UnsignedInt!
    $where: AwardFilterInput
    $skip: UnsignedInt
    $orderBy: [AwardOrderingInput!]
  ) {
    awards(first: $first, where: $where, skip: $skip, orderBy: $orderBy) {
      id
      title
    }
  }
`;

import * as R from 'ramda';

import { Resource } from './genericTypes';

import {
  isObjectTruthy,
  ObjectArray,
  ObjectArrayOrNull,
} from '../../crud-core/utils/TypescriptUtils';

export function diffArray(
  newData: ObjectArray,
  oldData: ObjectArray,
  resource: Resource,
): {
  deleted: ObjectArrayOrNull;
  updated: ObjectArrayOrNull;
  created: ObjectArrayOrNull;
} {
  // Deleted = Object with same ID not found in oldData
  const deleted = oldData.filter(
    (oldItem: any) =>
      !R.find(R.propEq(resource.keyProperty, oldItem[resource.keyProperty]))(
        newData,
      ),
  );

  // Updated = Object has an ID property, and object not the exact same as in oldData
  const updated = newData.filter(
    (newItem: any) =>
      newItem[resource.keyProperty] &&
      R.find(R.propEq(resource.keyProperty, newItem[resource.keyProperty]))(
        oldData,
      ) &&
      !R.contains(newItem, oldData),
  );

  // Created = Object has no ID property, or has an ID not found in oldData
  const created = newData.filter(
    (newItem: any) =>
      isObjectTruthy(newItem) &&
      !R.contains(newItem, oldData) &&
      (!newItem[resource.keyProperty] ||
        !R.find(R.propEq(resource.keyProperty, newItem[resource.keyProperty]))(
          oldData,
        )),
  );

  return {
    deleted: deleted.length > 0 ? deleted : null,
    updated: updated.length > 0 ? updated : null,
    created: created.length > 0 ? created : null,
  };
}

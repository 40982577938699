import React from 'react';
import { Link } from 'react-router-dom';
import { Tabs, Tab, Theme } from '@mui/material';

import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      position: 'fixed',
      width: '100%',
      backgroundColor: '#f5f5f5',
      zIndex: theme.zIndex.appBar,
    },
  });

export interface TabRoute {
  label: string;
  path: string;
  brandKey?: string;
}

type RoutingTabsProps = {
  pathname: string;
  tabs: TabRoute[];
} & WithStyles<typeof styles>;

const _RoutingTabs: React.FC<RoutingTabsProps> = ({
  pathname,
  tabs,
  classes,
}) => {
  const myLink = (path: string) =>
    React.forwardRef((props: any, ref) => (
      <Link to={path} {...props} innerRef={ref} />
    ));

  return (
    <Tabs
      value={pathname}
      indicatorColor="primary"
      textColor="primary"
      scrollButtons={false}
      classes={{ root: classes.root }}
      centered
    >
      {tabs.map(({ path, label, brandKey }) => (
        <Tab
          data-testid={`tab-${brandKey || 'generalities'}`}
          component={myLink(path)}
          label={label}
          value={path}
          key={path}
        />
      ))}
    </Tabs>
  );
};

export const RoutingTabs = withStyles(styles)(_RoutingTabs);

import { gql } from '@apollo/client';

export const GET_PASSIONS_QUERY = gql`
  query GetPassions(
    $first: UnsignedInt!
    $where: PassionFilterInput
    $skip: UnsignedInt
    $orderBy: [PassionOrderingInput!]
  ) {
    passions(first: $first, where: $where, skip: $skip, orderBy: $orderBy) {
      id
      title
    }
  }
`;

import React from 'react';
import { InternalStandardProps } from '@mui/material';
import classnames from 'classnames';
import { Omit } from '../../../crud-core/utils/TypescriptUtils';
import { useStyles } from './styles';

type InputGroupProps = {
  children?: React.ReactNode;
  variant?: 'vertical' | 'horizontal';
} & Omit<
  InternalStandardProps<React.HTMLAttributes<HTMLDivElement>>,
  'onChange'
>;

const InputGroup: React.FC<InputGroupProps> = ({
  children,
  className,
  style,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  variant = 'vertical',
}: InputGroupProps) => {
  // TOFIX
  // const variantIndex: 'root' | 'variant-horizontal' | 'variant-vertical' = `variant-${variant}`;
  const classes = useStyles();
  return (
    <div
      className={classnames(
        classes.root,
        classes['variant-vertical'],
        className,
      )}
      style={style}
    >
      {children}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default InputGroup;

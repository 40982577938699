import { Resource, Item } from './genericTypes';

type ConnectMutationVariables = Record<string, unknown> | null;

export function computeConnectMutationVariables(
  item: Item,
  resourceConfig: Resource,
): ConnectMutationVariables {
  return {
    [resourceConfig.keyProperty]: item[resourceConfig.keyProperty],
  };
}

import InputLabel from '@mui/material/InputLabel';
import { draftActions } from '@prismamedia/one-components';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { MegadraftEditor } from 'megadraft';
import 'megadraft/dist/css/megadraft.css';
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { decorators } from './Decorators';

const reduceAction = (actions: any[]) =>
  actions.reduce(
    (acc, { action, decorator, entity }) => ({
      actions: [...acc.actions, action],
      decorators: [...acc.decorators, ...(decorator ? [decorator] : [])],
      entities: { ...acc.entities, ...(entity ? entity : {}) },
    }),
    { actions: [], entities: {}, decorators: [] },
  );

interface DraftFieldProps {
  draft?: string;
  label?: string;
  brandKey: string;
  onChange: (value: string) => void;
  sidebarRendererFn?: () => JSX.Element;
}

export const DraftField: FC<DraftFieldProps> = ({
  draft,
  label,
  brandKey,
  sidebarRendererFn,
  onChange,
}: DraftFieldProps) => {
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(stateFromHTML(draft || ''), decorators),
  );

  useEffect(() => {
    setEditorState(
      EditorState.createWithContent(stateFromHTML(draft || ''), decorators),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandKey]);

  const handleChange = (e: EditorState) => {
    setEditorState(e);
    const data = stateToHTML(e.getCurrentContent());
    onChange(data || '');
  };

  const editorConfig = reduceAction([
    draftActions.BOLD,
    draftActions.ITALIC,
    draftActions.STRIKETHROUGH,
    draftActions.LINK,
    draftActions.SEPARATOR,
    draftActions.TITLE,
    draftActions.LIST,
    draftActions.ORDERED_LIST,
    draftActions.BLOCKQUOTE,
  ]);

  return (
    <>
      <InputLabel htmlFor={label}>{label}</InputLabel>
      <MegadraftEditor
        editorState={editorState}
        spellCheck={true}
        sidebarRendererFn={sidebarRendererFn}
        onChange={handleChange}
        actions={editorConfig.actions}
        entityInputs={editorConfig.entities}
      />
    </>
  );
};

import { gql } from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';
import {
  UnlockPerson,
  UnlockPersonVariables,
} from '../../../__generated__/queries-person';

const UNLOCK_PERSON = gql`
  mutation UnlockPerson($personId: UUIDv4) {
    updatePerson(where: { id: $personId }, data: { lockerId: null }) {
      lockerId
      fullName
    }
  }
`;

export const useUnlock = (personId: string) =>
  useMutation<UnlockPerson, UnlockPersonVariables>(UNLOCK_PERSON, {
    variables: {
      personId,
    },
  });

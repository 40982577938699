import { DegreeResource } from './degree.config';
import { Resource } from '../../../one-crud-react';

export const PersonDegreeResource: Resource = {
  singular: 'personDegree',
  plural: 'personDegrees',
  basePath: 'personDegree',
  keyProperty: 'degree',
  isRelationEnity: true,
  linkedResources: [
    {
      field: 'degree',
      resource: DegreeResource,
    },
  ],
};

// https://github.com/unional/typescript-guidelines/blob/master/pages/advance-types/recursive-types.md
type stringOrStringArray = string | string[] | null | undefined;

const convertNumberParamsToStringValue = (
  param: stringOrStringArray,
): stringOrStringArray => {
  if (!param) {
    return '';
  }

  if (Array.isArray(param)) {
    return param.map((p) => convertNumberParamsToStringValue(p)) as string[];
  }
  // tslint:disable-next-line
  return isNaN(param as any) ? param : `'${param}'`;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const convertStringValueToNumberParams = (
  value: stringOrStringArray,
): stringOrStringArray => {
  if (Array.isArray(value)) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return value.map((p) => convertStringValueToNumberParams(p)) as string[];
  }
  // eslint-disable-next-line no-useless-escape
  return (value && value.replace(/\'(.*)\'/, '$1')) || null;
};

export const filterNamespacedUrlFilters = (
  parsedSearch: { [key: string]: string | string[] | null | undefined },
  namespace = '',
  mode: 'include' | 'exclude' = 'include',
): { [s: string]: string | string[] } => {
  const filteredSearch: { [s: string]: string } = Object.keys(parsedSearch)
    .filter(
      (name) =>
        (mode === 'include' && name.startsWith(namespace)) ||
        (mode === 'exclude' && !name.startsWith(namespace)),
    )
    .reduce(
      (params, name) => ({
        ...params,
        // https://github.com/final-form/react-final-form/blob/master/docs/faq.md#why-cant-i-have-numeric-keys-in-an-object
        [name.replace(`${namespace}_`, '')]: convertNumberParamsToStringValue(
          parsedSearch[name],
        ),
      }),
      {},
    );
  return filteredSearch;
};

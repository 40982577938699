import { IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import React from 'react';
import { ApolloConsumer } from 'react-apollo';
import uuid from 'uuid';
import { client } from '../../../../apollo';
import { GET_AWARDS_QUERY } from '../../../../apollo/person/Query/getAwardsQuery.person.graphql';
import { UPSERT_AWARD_MUTATION } from '../../../../apollo/person/Mutations/upsertAwardMutation.person.graphql';
import { AutoSuggestField, TextInput } from '../../../../one-crud-react';
import { simpleQuery } from '../../../../utils/simpleQuery';
import { GetAwards } from '../../../../__generated__/queries-person';
import { AwardResource } from '../../config/award.config';
import { stylesProps, wrapped } from '../styles';

export interface Nomination {
  id: string;
  work: string;
  year: number | null;
  award: {
    id: string;
    title: string;
  };
}

type NominationProps = {
  nomination: Nomination;
  onDelete: (e: React.MouseEvent) => void;
  onUpdate: (
    keys: string[] | null,
    path: string,
  ) => (selectedSuggestion: Record<string, any>) => void;
} & stylesProps;

const NominationWithoutStyle: React.FC<NominationProps> = React.memo(
  ({ classes, nomination, onDelete, onUpdate }) => {
    const upsertSuggestion = async ({ title }: any) => {
      const id = uuid();
      try {
        const result = await client.mutate<any, any>({
          mutation: UPSERT_AWARD_MUTATION,
          variables: {
            create: { id, title },
            update: { title },
            where: { id },
          },
        });
        if (result && result.data) {
          onUpdate(['id', 'title'], 'award')({ id, title });
          return result.data.upsertAward;
        }
      } catch (error) {
        console.warn('Error during the new award upsert', error);
      }
      return undefined;
    };

    return (
      <div className={classes.nomination}>
        <ApolloConsumer>
          {() => (
            <AutoSuggestField
              fetchSuggestions={(value) =>
                simpleQuery<GetAwards>({
                  client: client as any,
                  value,
                  query: GET_AWARDS_QUERY,
                  resource: AwardResource,
                  mapper: (res) => ({ id: res.id, label: res.title }),
                })
              }
              keyValue="nomination"
              inputLabel="Nom du prix"
              inputValue={nomination.award.title}
              suggestionStyle={classes.suggestionStyle}
              onSuggestionSelected={onUpdate(['id', 'title'], 'award')}
              upsertSuggestion={upsertSuggestion}
              keepSelectedValue
            />
          )}
        </ApolloConsumer>
        <TextInput
          label="Nom de l'oeuvre"
          field="work"
          item={nomination}
          textFieldProps={{
            fullWidth: true,
            InputLabelProps: { shrink: true },
          }}
          onChange={(_field: string, value: any) => {
            onUpdate(null, 'work')({ id: value });
          }}
        />
        <TextInput
          label="Année"
          field="year"
          item={nomination}
          textFieldProps={{
            fullWidth: true,
            type: 'number',
            InputLabelProps: { shrink: true },
          }}
          onChange={(_field: string, value: any) => {
            onUpdate(null, 'year')({ id: value });
          }}
        />
        <IconButton aria-label="Delete" onClick={onDelete} size="large">
          <Delete />
        </IconButton>
      </div>
    );
  },
);

export const NominationComponent = wrapped(NominationWithoutStyle);

import { gql } from '@apollo/client';

import { JOB_FRAGMENT } from '../Fragments/jobFragment.person.graphql';

export const UPSERT_JOB_MUTATION = gql`
  mutation UpsertJob(
    $where: JobUniqueFilterInput!
    $update: JobUpdateInput!
    $create: JobCreationInput!
  ) {
    upsertJob(create: $create, update: $update, where: $where) {
      ...Job
    }
  }
  ${JOB_FRAGMENT}
`;

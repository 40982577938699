import { CircularProgress } from '@mui/material';
import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useUnlock } from '../../../apollo/person/Mutations/unlock.person.graphql';
import { paths } from '../../../routing';

export const UnlockPage: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
  history,
}) => {
  const { id } = match.params;
  const [unlock] = useUnlock(id);
  const { pushNotification } = useNotification();

  React.useEffect(() => {
    unlock()
      .then(({ data }) => {
        pushNotification({
          message: `Vous avez enlevé le vérrouillage sur ${data?.updatePerson?.fullName}`,
          type: NotificationTypeEnum.success,
        });
      })
      .catch(() => {
        pushNotification({
          message: 'Vous ne pouvez pas enlever ce vérrouillage',
          type: NotificationTypeEnum.error,
        });
      })
      .finally(() => {
        history.push(paths.PERSON_LIST);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
    >
      <p>Dévérouillage en cours</p>
      <CircularProgress />
    </div>
  );
};

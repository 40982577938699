import { MenuItem, Paper, TextField } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import * as React from 'react';
import Autosuggest, {
  ChangeEvent,
  RenderSuggestionParams,
  SuggestionsFetchRequestedParams,
} from 'react-autosuggest';

type Suggestion = {
  id?: string;
  label: string;
  isNewValue?: boolean;
} & Record<string, any>;

interface UpsertParameters {
  id?: string | null;
  title: string;
}
interface UpsertOutputParameters {
  id: string | null;
  title: string;
}
const renderInputComponent = (inputProps: any) => {
  const { inputRef, ref, ...other } = inputProps;
  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: (node) => {
          ref(node);
          if (inputRef) {
            inputRef(node);
          }
        },
      }}
      InputLabelProps={{ shrink: true }}
      {...other}
    />
  );
};

const renderSuggestion = (
  suggestion: Suggestion,
  { query, isHighlighted }: RenderSuggestionParams,
) => {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div" disableGutters>
      <div>
        {parts.map(
          (part: { text: string; highlight: boolean }, index: number) => {
            return part.highlight ? (
              <span key={String(index)} style={{ fontWeight: 500 }}>
                {part.text}
              </span>
            ) : (
              <strong key={String(index)} style={{ fontWeight: 300 }}>
                {part.text}
              </strong>
            );
          },
        )}
      </div>
    </MenuItem>
  );
};

export interface AutoSuggestFieldProps {
  onChange?: (field: string, value: Record<string, unknown>) => void;
  onSuggestionsFetchRequested?: (
    params: SuggestionsFetchRequestedParams,
  ) => void;
  onSuggestionSelected?: (selectedSuggestion: Record<string, any>) => void;
  upsertSuggestion?: <T extends UpsertParameters>(
    mutation: T,
  ) => Promise<UpsertOutputParameters | void>;
  fetchSuggestions?: (value: string) => Promise<Suggestion[] | void>;
  inputValue?: string;
  inputLabel?: string;
  keyValue?: string;
  suggestionStyle?: string;
  keepSelectedValue?: boolean;
}

interface AutoSuggestFieldState {
  value: string;
  suggestions: Suggestion[];
}

class AutoSuggestField extends React.Component<
  AutoSuggestFieldProps,
  AutoSuggestFieldState
> {
  timeout: number | null = null;
  state: AutoSuggestFieldState = {
    value: this.props.inputValue || '',
    suggestions: [],
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionsFetchRequested = async ({
    value,
  }: SuggestionsFetchRequestedParams) => {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }

    const { fetchSuggestions, upsertSuggestion } = this.props;

    this.timeout = window.setTimeout(async () => {
      if (fetchSuggestions) {
        let suggestions = await fetchSuggestions(value);
        if (!suggestions) {
          suggestions = [];
        } else if (
          upsertSuggestion &&
          suggestions.length === 0 &&
          value !== ''
        ) {
          suggestions = [
            {
              label: `Voulez vous créer "${value}" ?`,
              value,
              isNewValue: true,
            },
          ];
        }
        this.setState({ suggestions });
      }
      // tslint:disable-next-line
    }, 500);
  };

  onChange = (
    event: React.FormEvent<HTMLInputElement>,
    params: ChangeEvent,
  ) => {
    this.setState({
      value: params.newValue,
    });
  };

  render() {
    const {
      onSuggestionSelected,
      upsertSuggestion,
      suggestionStyle,
      keepSelectedValue = false,
      inputLabel,
      keyValue,
    } = this.props;
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: 'Rechercher',
      value,
      onChange: this.onChange,
      label: inputLabel,
      'data-testid': keyValue ? `suggest-${keyValue}` : undefined,
    };

    return (
      <Autosuggest
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={(e, { suggestion }) => {
          if (onSuggestionSelected) {
            onSuggestionSelected(suggestion);
          }
          if (upsertSuggestion && suggestion.isNewValue) {
            upsertSuggestion({ title: value });
          }
          const displayValue = suggestion.value || suggestion.label || '';
          this.setState({
            value: keepSelectedValue ? displayValue : '',
          });
        }}
        inputProps={inputProps}
        renderInputComponent={renderInputComponent}
        suggestions={suggestions}
        renderSuggestion={renderSuggestion}
        getSuggestionValue={(suggestion: Suggestion) => suggestion.label}
        renderSuggestionsContainer={(options) => (
          <Paper {...options.containerProps} square className={suggestionStyle}>
            {options.children}
          </Paper>
        )}
      />
    );
  }
}

// eslint-disable-next-line import/no-default-export
export default AutoSuggestField;

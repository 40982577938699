import { Tooltip } from '@mui/material';
import { brandTitleByKey } from '@prismamedia/one-brandkey';
import classnames from 'classnames';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { BrandKey } from '../../__generated__/queries-person';
import { brandKeys, getBrandSlug } from '../../utils/brands';
import { useStyles } from './styles';

interface BrandsIndicatorProps {
  existingBrands: BrandKey[];
  id: string;
  shouldRedirect?: boolean;
}

export const BrandsIndicator: FC<BrandsIndicatorProps> = ({
  id,
  existingBrands,
  shouldRedirect = false,
}) => {
  const classes = useStyles();
  const isUnfilled = (brandKey: BrandKey) => !existingBrands.includes(brandKey);

  return (
    <>
      {brandKeys.map((brandKey, index) => (
        <Tooltip
          title={brandTitleByKey[brandKey]}
          placement="top"
          key={brandKey}
        >
          {shouldRedirect ? (
            <Link
              data-testid={`brand-indicator-${brandKey}`}
              data-ismodified={!isUnfilled(brandKey)}
              data-brandindicator="true"
              key={`brand-${index}`}
              to={`/person/${id}/${getBrandSlug(brandKey)}`}
              className={classnames(classes.shape, [classes[brandKey]], {
                [classes.unfilled]: isUnfilled(brandKey),
              })}
            />
          ) : (
            <div
              className={classnames(classes.shape, [classes[brandKey]], {
                [classes.unfilled]: isUnfilled(brandKey),
              })}
            />
          )}
        </Tooltip>
      ))}
    </>
  );
};

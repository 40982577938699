import { InternalStandardProps, Paper } from '@mui/material';
import { PaperProps } from '@mui/material/Paper';
import classnames from 'classnames';
import React from 'react';
import { useStyles } from './styles';

type SimpleFormProps = {
  children?: JSX.Element | JSX.Element[] | (() => JSX.Element);
  paperProps?: PaperProps;
} & InternalStandardProps<React.HTMLAttributes<HTMLDivElement>>;

const SimpleForm: React.FC<SimpleFormProps> = ({
  paperProps,
  className,
  style,
  children,
}) => {
  const classes = useStyles();
  return (
    <Paper
      {...paperProps}
      className={classnames(classes.root, className)}
      style={style}
    >
      {children}
    </Paper>
  );
};

// eslint-disable-next-line import/no-default-export
export default SimpleForm;

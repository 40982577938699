import { makeStyles } from '@mui/styles';
import { getSpacing } from '@prismamedia/one-components';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: getSpacing(theme, 2),
    display: 'flex',
    flexDirection: 'column',
  },
}));

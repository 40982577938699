import { dissocPath } from 'ramda';
import {
  Resource,
  computeCreateMutationVariables,
  ObjectArrayOrNull,
  ObjectOrObjectArrayOrNull,
  nullIfEmpty,
} from '../../../one-crud-react';

import { PersonToResource } from './personTo.config';
import { RelationshipResource } from './relationship.config';

const customMutation = (
  updated: ObjectArrayOrNull,
  deleted: ObjectArrayOrNull,
  created: ObjectArrayOrNull,
  resource: Resource,
) => {
  let createCustom: ObjectOrObjectArrayOrNull = null;
  let updateCustom: ObjectOrObjectArrayOrNull = null;
  let deleteCustom: ObjectOrObjectArrayOrNull = null;
  if (updated) {
    updateCustom = nullIfEmpty(
      updated.map((field: Record<string, any>) => ({
        order: field.order,
        relationshipTitle: {
          connect: {
            id: field.relationshipTitle.id,
          },
        },
        where: {
          personTo: {
            id: field.personTo.id,
          },
        },
      })),
    );
  }
  if (deleted) {
    deleteCustom = nullIfEmpty(
      deleted &&
        deleted.map((field: any) => ({
          personTo: {
            id: field.personTo.id,
          },
        })),
    );
  }
  if (created) {
    createCustom = nullIfEmpty(
      created.map((field: any) =>
        dissocPath(['id'], computeCreateMutationVariables(field, resource)),
      ),
    );
  }

  return {
    deleteCustom,
    createCustom,
    updateCustom,
    connectCustom: null,
  };
};

export const PersonRelationshipToResource: Resource = {
  singular: 'PersonRelationship',
  plural: 'PersonRelationships',
  basePath: 'relationshipTo',
  keyProperty: 'id',
  isRelationEnity: true,
  customMutation,
  linkedResources: [
    {
      field: 'personTo',
      resource: PersonToResource,
    },
    {
      field: 'relationshipTitle',
      resource: RelationshipResource,
    },
  ],
};

import React from 'react';
import uuidv4 from 'uuid/v4';
import { BasicContainer } from './BasicContainer';
import { Nomination, NominationComponent } from './Nomitation';
import { useResource } from './useResource';

export const NominationsFields: React.FC = () => {
  const {
    resources,
    setResources,
    onDelete,
    onUpdate,
  } = useResource<Nomination>('nominations');

  const onAdd = (e: React.MouseEvent) => {
    e.stopPropagation();
    const emptyNomination: Nomination = {
      id: uuidv4(),
      work: '',
      year: null,
      award: { id: uuidv4(), title: '' },
    };
    setResources([...resources, emptyNomination]);
  };

  return (
    <BasicContainer keyValue="nominations" title="Récompenses" onAdd={onAdd}>
      {resources.map((nomination: Nomination, index: number) => (
        <NominationComponent
          nomination={nomination}
          onDelete={onDelete(index)}
          onUpdate={onUpdate(index)}
          key={nomination.id}
        />
      ))}
    </BasicContainer>
  );
};

import { Button, CircularProgress, StandardProps } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useEditContext } from '../../../../one-crud-react/crud-graphql-containers';
import { Item } from '../../../../one-crud-react/crud-graphql-containers/Utils/genericTypes';
import {
  PersonStatusProps,
  PERSON_STATUS,
} from '../../../../utils/personStatus';
import { StatusMenu } from './StatusMenu';
import { useStyles } from './styles';

type SaveButtonProps = {
  buttonProps?: ButtonProps | { 'data-testid': string };
  buttonLabel?: string;
  redirect?: boolean;
} & StandardProps<React.HTMLAttributes<HTMLButtonElement>, ''> &
  RouteComponentProps<any>;

const SaveButton: React.FC<SaveButtonProps> = ({
  buttonProps,
  className,
  history,
  buttonLabel = 'Save',
  redirect,
  style,
}: SaveButtonProps) => {
  const classes = useStyles();
  const context = useEditContext();
  const [saving, setSaving] = useState<boolean>(false);

  const statusOptions = PERSON_STATUS.map((tab: PersonStatusProps) => ({
    label: tab.inputLabel,
    value: tab.value,
  }));

  const prevVersion = context.item;

  const isPersonEqual = (obj1: Item, obj2: Item) => {
    if (JSON.stringify(obj1) !== JSON.stringify(obj2)) {
      return true;
    }
    return false;
  };
  const props = {
    onClick: async () => {
      if (context.onSave) {
        setSaving(true);
        context.item.editedAt = isPersonEqual(prevVersion, context.item)
          ? new Date().toISOString()
          : context.item.editedAt;
        await context.onSave(context.item);
        setSaving(false);
      }
      if (redirect) {
        const path: string = context.resourceConfig
          ? `/${context.resourceConfig.basePath}/list`
          : '/list';
        history.push(path);
      }
    },
    ...(buttonProps as any),
  };

  return saving ? (
    <div style={{ minWidth: 250 }}>
      <CircularProgress size={25} thickness={2} color="inherit" />
    </div>
  ) : (
    <>
      <Button {...props} className={className} style={style} disabled={saving}>
        {buttonLabel}
      </Button>
      <StatusMenu
        setSaving={setSaving}
        key="statusButton"
        field="status"
        options={statusOptions}
        className={classes.statusButton}
      />
    </>
  );
};

const styledComponent = withRouter(SaveButton);
styledComponent.displayName = 'SaveButton';
// eslint-disable-next-line import/no-default-export
export default styledComponent;

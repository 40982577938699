import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const styles = (theme: Theme) =>
  createStyles({
    lockBackdrop: {
      zIndex: theme.zIndex.modal,
    },
    body: { textAlign: 'center' },
    userPanel: {
      alignItems: 'center',
      display: 'flex',
      marginTop: 5,
    },
    avatar: {
      border: 'solid #5c5c5c 1px',
      marginRight: 10,
      height: 35,
      width: 35,
    },
    dialogContent: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      padding: 10,
    },
    header: { display: 'flex' },
    title: { fontWeight: 'bold' },
    emailButton: { border: 'solid 1px', padding: 3, marginLeft: 10 },
  });

export const useStyles = makeStyles(styles);

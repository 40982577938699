import { useApolloClient } from '@apollo/react-hooks';
import { Add } from '@mui/icons-material';
import { Fab } from '@mui/material';
import {
  AppDrawerMenu,
  AppName,
  AutocompleteItem,
  HomeAppBar,
  SearchBar,
} from '@prismamedia/one-components';
import { parse, stringify } from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  GetPeople,
  GetPeopleVariables,
  PersonFilterInput,
  PersonOrderingInput,
  PersonStatus,
} from '../../../__generated__/queries-person';
import { GET_PEOPLE_QUERY } from '../../../apollo/person/Query/getPeopleQuery.person.graphql';
import { usePeopleCountGetter } from '../../../apollo/person/Query/useGetPeople.person.graphql';
import { paths, replaceParams } from '../../../routing';
import { auth } from '../../../utils/auth';
import { computeFiltersFromUrl } from '../../../utils/filter/urlFilters';
import { PERSON_STATUS, PersonStatusProps } from '../../../utils/personStatus';
import { PeopleListContent } from './PeopleListContent';
import { useStyles } from './styles';

export const ListPage: FC = () => {
  const apolloClient = useApolloClient();
  const history = useHistory();
  const location = useLocation();
  const parsedQuery = parse(location.search);
  const classes = useStyles();
  const [searchParams, setSearchParams] = React.useState({
    search: parsedQuery.PersonList_fullName_contains as string,
  });
  const [tabRoutes, setTabRoutes] = useState(
    PERSON_STATUS.map((tab: PersonStatusProps, i) => ({
      id: i,
      label: `${tab.label} (0)`,
      path: `${location.pathname}?status=${tab.value}`,
    })),
  );
  const getPeopleCount = usePeopleCountGetter();

  useEffect(() => {
    Promise.all(
      PERSON_STATUS.map(async (tab: PersonStatusProps, i) => {
        const getCountQuery = {
          ...queryFilter,
          status: tab.value as PersonStatus,
        } as PersonFilterInput;
        const { data } = await getPeopleCount(getCountQuery);
        return {
          id: i,
          label: `${tab.label} (${data?.personCount || 0})`,
          path: `${location.pathname}?status=${tab.value}`,
        };
      }),
    ).then(setTabRoutes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pushHistorySearch = (searchedLabel: string, value: string) => {
    if (history && location) {
      const newLocation = {
        pathname: paths.PERSON_LIST,
        search: stringify(
          Object.assign({}, parsedQuery, {
            [searchedLabel]: value,
          }),
          {
            encode: true,
          },
        ),
      };
      history.push(newLocation);
    }
  };
  const handleSearchParam = (value: any) => {
    pushHistorySearch('PersonList_fullName_contains', value.search);
    setSearchParams(value);
  };

  const queryFilter: PersonFilterInput = React.useMemo(
    () => computeFiltersFromUrl(location, 'PersonList'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.search],
  );

  const fetchAutocompleteList = async (
    search: string,
  ): Promise<AutocompleteItem[] | undefined> => {
    const { data, errors } = await apolloClient.query<
      GetPeople,
      GetPeopleVariables
    >({
      query: GET_PEOPLE_QUERY,
      variables: {
        first: 100,
        where: {
          fullName_contains: search,
          status: PersonStatus.Published,
        },
        skip: 0,
        orderBy: [PersonOrderingInput.editedAt_DESC],
      },
    });

    if (errors?.length) {
      throw errors[0];
    }

    return data?.people?.map(({ id, fullName }) => ({
      id,
      label: fullName || '',
    }));
  };

  const searchBar = (
    <SearchBar
      autocomplete={{
        onSelect: ({ id }) => {
          history.push(
            `${replaceParams(paths.PERSON_EDIT, {
              id,
            })}/generalities`,
          );
        },
        fetchList: fetchAutocompleteList,
      }}
      placeholder="Rechercher un people"
      setSearchParams={handleSearchParam}
      searchParams={searchParams}
    />
  );
  return (
    <>
      <HomeAppBar
        appDrawerMenu={
          <AppDrawerMenu
            appName={AppName.People}
            disconnectUser={auth.logout}
            overriddenAppUrls={{
              [AppName.Authors]: `${config.FRONT_ONE_WEB}/#/authors/list`,
              [AppName.Diapo]: `${config.FRONT_ONE_WEB}/#/slideshow/list?status=Published`,
              [AppName.Newsletter]: config.FRONT_ONE_NEWSLETTER,
              [AppName.Photo]: config.FRONT_ONE_PHOTO,
              [AppName.Recipe]: config.FRONT_ONE_RECIPE,
              [AppName.Story]: config.FRONT_ONE_TOPIC,
              [AppName.Web]: `${config.FRONT_ONE_WEB}`,
            }}
          />
        }
        searchBar={searchBar}
        currentUser={{
          avatarUrl: auth.user?.avatarUrl,
        }}
        disconnectUser={auth.logout}
        tabs={
          tabRoutes.map(({ label, id }) => ({
            id,
            label,
            selected:
              (queryFilter['status'] || PersonStatus.Published) ===
              PERSON_STATUS[id].value,
          })) as any
        }
        onTabSelect={({ id }: any) => {
          pushHistorySearch('status', PERSON_STATUS[id].value);
        }}
      />
      <div className={classes.peopleList}>
        <PeopleListContent history={history} queryFilter={queryFilter} />
      </div>
      <Fab
        data-testid="create-people-btn"
        color="primary"
        className={classes.fab}
        onClick={() => {
          history.push(`${paths.PERSON_NEW}/generalities`);
        }}
      >
        <Add />
      </Fab>
    </>
  );
};

import * as React from 'react';
import { path } from 'ramda';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  StandardProps,
  Theme,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { SelectProps } from '@mui/material/Select';
import classnames from 'classnames';
import { useEditContextForInput } from '../useEditContextForInput';
import { getSpacing } from '@prismamedia/one-components';

const styles = (theme: Theme) =>
  createStyles({
    root: { minWidth: 120, marginTop: getSpacing(theme, 2) },
  });

interface SelectOption {
  value: string | number;
  label: string | number;
}

type SelectInputProps = {
  field: string;
  label?: string;
  item?: Record<string, unknown>;
  onChange?: (field: string, value: any) => void;
  options?: SelectOption[];
  placeholder?: string;
  selectProps?: SelectProps;
} & WithStyles<typeof styles> &
  StandardProps<React.HTMLAttributes<HTMLDivElement>, ''>;

const SelectInputWithoutStyles: React.FC<SelectInputProps> = ({
  classes,
  className,
  field,
  label,
  options,
  placeholder,
  selectProps,
  style,
  item,
  onChange,
}: SelectInputProps) => {
  // If we provide an onChange function it prevails from using context
  let value: string | undefined;
  let props: SelectProps;
  const {
    fieldValue,
    onChange: onInputChange,
  } = useEditContextForInput<string>(field);
  if (onChange) {
    value = path(field.split('.'), item) || '';
    props = {
      onChange: (event) =>
        onChange(field, event.target.value as Record<string, unknown>),
    };
  } else {
    value = fieldValue || '';
    props = {
      onChange: (event) => onInputChange(event.target.value as string),
    };
  }

  return (
    <FormControl className={classnames(classes.root, className)} style={style}>
      <InputLabel htmlFor={field}>{label || field}</InputLabel>
      <Select
        value={value}
        inputProps={{
          name: field,
          id: field,
        }}
        {...selectProps}
        {...props}
      >
        {placeholder && (
          <MenuItem value="">
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {Array.isArray(options) &&
          options.map(({ label: optionLabel, value: optionValue }) => (
            <MenuItem key={optionValue} value={optionValue}>
              {optionLabel}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

const SelectInput = withStyles(styles)(SelectInputWithoutStyles);
// eslint-disable-next-line import/no-default-export
export default SelectInput;

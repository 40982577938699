import { Avatar, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid';
import { History } from 'history';
import React from 'react';
import {
  GetPeople_people,
  PersonFilterInput,
  PersonOrderingInput,
} from '../../../__generated__/queries-person';
import { useGetPeople } from '../../../apollo/person/Query/useGetPeople.person.graphql';
import { BrandsIndicator } from '../../../components/BrandsIndicator';
import { CircularLoader } from '../../../components/Loader/Circular';
import { paths, replaceParams } from '../../../routing';
import { formatDateFr } from '../../../utils/format';

interface PeopleListContentProps {
  history: History;
  queryFilter: PersonFilterInput;
}

export const PeopleListContent: React.FC<PeopleListContentProps> = ({
  history,
  queryFilter,
}) => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('lg'));
  const [orderBy, setOrderBy] = React.useState<PersonOrderingInput[]>([
    PersonOrderingInput.updatedAt_DESC,
  ]);
  const onFilterChange = (sortModel: GridSortModel) => {
    const order = sortModel.map(
      ({ field, sort }) =>
        `${field}_${sort!.toUpperCase()}` as PersonOrderingInput,
    );
    setOrderBy(order);
  };

  const { data, error, loading } = useGetPeople({ queryFilter, orderBy });
  if (loading) {
    return <CircularLoader fullScreen />;
  }
  if (error) {
    return <>Une erreur est survenue</>;
  }
  if (!data || data.people.length === 0) {
    return <>Pas de resultats</>;
  }

  const { people, personCount } = data!;
  const fnDisplayRow = ({
    id,
    fullName,
    updatedAt,
    existingBrands,
    lockedBy,
  }: GetPeople_people) => ({
    id,
    fullName,
    updatedAt,
    existingBrands,
    lockedBy,
  });
  const onRowClick = (param: GridRowParams) => {
    const { id } = param.row;
    history.push(
      `${replaceParams(paths.PERSON_EDIT, {
        id,
      })}/generalities`,
    );
  };

  const lockField = {
    field: 'lockerId',
    headerName: 'Verrouillé',
    width: 100,
    renderCell: ({ row: { lockedBy } }: GridCellParams) => (
      <>
        {lockedBy && (
          <Tooltip title={`${lockedBy.name} est connecté à la fiche people`}>
            <Avatar
              style={{
                height: 30,
                width: 30,
                margin: 'auto',
                border: 'solid #5c5c5c 1px',
              }}
              sizes="small"
              src={lockedBy.avatarUrl}
            />
          </Tooltip>
        )}
      </>
    ),
  };

  const mediasField = {
    field: 'existingBrands',
    headerName: 'Marques',
    width: 200,
    sortable: false,
    renderCell: ({ row: { id, existingBrands } }: GridCellParams) => (
      <BrandsIndicator
        shouldRedirect
        id={id?.toString()}
        existingBrands={existingBrands}
      />
    ),
  };
  const fullNameField = { field: 'fullName', headerName: 'Nom', width: 700 };
  const updatedAtField = {
    field: 'updatedAt',
    headerName: 'Date de modification',
    width: 200,
    valueFormatter: (params: GridCellParams) =>
      formatDateFr(params.value?.toString() || '', "d MMM yyyy, HH'h'mm"),
  };
  const columns = isSmallDevice
    ? [
        { ...mediasField, flex: 1 },
        { ...fullNameField, width: 200 },
        lockField,
        updatedAtField,
      ]
    : [fullNameField, updatedAtField, lockField, mediasField];
  const rows = people.map(fnDisplayRow);
  return (
    <DataGrid
      disableExtendRowFullWidth={true}
      onSortModelChange={onFilterChange}
      rowCount={personCount}
      onRowClick={onRowClick}
      columns={columns as GridColumns}
      rows={rows}
      pageSize={50}
      density="compact"
      autoHeight
    />
  );
};

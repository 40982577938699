import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

export const formatDateFr = (
  date: number | Date | string | null,
  dateFormat: string,
) => {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  if (!date) {
    date = new Date();
  }
  return format(date, dateFormat, { locale: fr });
};

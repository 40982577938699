import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import {
  GetPersonHistory,
  GetPersonHistoryVariables,
} from '../../../__generated__/queries-person';

const GET_PERSON_HISTORY = gql`
  query GetPersonHistory($where: PersonUniqueFilterInput!) {
    person(where: $where) {
      id
      fullName
      histories(first: 100, orderBy: createdAt_DESC) {
        id
        status
        createdAt
        statusBioByBrandKey
        user {
          name
          avatarUrl
        }
      }
    }
  }
`;

export const useGetPeopleHistory = (id: string) =>
  useQuery<GetPersonHistory, GetPersonHistoryVariables>(GET_PERSON_HISTORY, {
    variables: {
      where: { id },
    },
  });

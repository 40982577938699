import { gql } from '@apollo/client';

export const PERSON_FRAMENT = gql`
  fragment Person on Person {
    id
    firstName
    lastName
    birth
    death
    residence
    status
    biographies(first: 100) {
      body
      brandKey
      createdAt
      editedAt
      id
      media
      status
      updatedAt
    }
    facebook
    twitch
    instagram
    twitter
    youtube
    snapchat
    tiktok
    isLocked
    lockedAt
    lockerId
    lockedBy {
      id
      name
      avatarUrl
      email
    }
    nominations(first: 100) {
      id
      work
      year
      award {
        id
        title
      }
    }
    jobs(first: 100) {
      job {
        id
        title
      }
    }
    countries(first: 100) {
      country {
        id
        title
      }
    }
    realFirstName
    realLastName
    sex
    schools(first: 100) {
      school {
        id
        title
      }
    }
    degrees(first: 100) {
      degree {
        id
        title
      }
    }
    passions(first: 100) {
      passion {
        id
        title
      }
    }
    titles(first: 100) {
      title {
        id
        title
      }
    }
    relationshipTo(first: 100, orderBy: order_ASC) {
      order
      personTo {
        id
        fullName
      }
      relationshipTitle {
        id
        title
        slug
      }
    }
    website
    updatedAt
    createdAt
    editedAt
  }
`;

export const lightPersonFragment = gql`
  fragment LightPerson on Person {
    id
    fullName
    existingBrands
    lockedBy {
      id
      name
      avatarUrl
    }
    updatedAt
  }
`;

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const styles = createStyles({
  peopleList: {
    cursor: 'pointer',
    margin: 'auto',
    marginTop: '120px',
    height: 500,
    maxWidth: '1200px',
    width: '100%',
  },
  avatarIcon: {
    width: 40,
    height: 40,
  },
  fab: {
    position: 'fixed',
    right: '8px',
    bottom: '16px',
    color: '#fff',
    backgroundColor: 'rgb(85, 114, 128)',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: 'rgb(59, 79, 89)',
    },
  },
});

export const useStyles = makeStyles(styles);

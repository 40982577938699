import H from 'history';
import { parse } from 'query-string';
import { PersonStatus } from '../../__generated__/queries-person';
import { filterNamespacedUrlFilters } from './namespaceUrlFilters';

export const computeFiltersFromUrl = (
  location: H.Location | undefined,
  namespace: string | undefined,
) => {
  let resourceListFilters = {} as ReturnType<typeof filterNamespacedUrlFilters>;
  if (location) {
    const params = parse(location.search);
    resourceListFilters = filterNamespacedUrlFilters(params, namespace);
    resourceListFilters['status'] = params.status || PersonStatus.Published;
  }

  return resourceListFilters;
};

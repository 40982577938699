import { gql } from '@apollo/client';

export const GET_SCHOOLS_QUERY = gql`
  query GetSchools(
    $first: UnsignedInt!
    $where: SchoolFilterInput
    $skip: UnsignedInt
    $orderBy: [SchoolOrderingInput!]
  ) {
    schools(first: $first, where: $where, skip: $skip, orderBy: $orderBy) {
      id
      title
    }
  }
`;

import { TitleResource } from './title.config';
import { Resource } from '../../../one-crud-react';

export const PersonTitleResource: Resource = {
  singular: 'personTitle',
  plural: 'personTitles',
  basePath: 'personTitle',
  keyProperty: 'title',
  isRelationEnity: true,
  linkedResources: [
    {
      field: 'title',
      resource: TitleResource,
    },
  ],
};

import CircularProgress from '@mui/material/CircularProgress';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';

const styles = createStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  fullScreen: {
    height: '100vh',
    width: '100vw',
  },
});

enum LoaderSizes {
  big = 100,
  small = 50,
}

type CircularLoaderProps = {
  size?: LoaderSizes;
  fullScreen?: boolean;
} & WithStyles<typeof styles>;

const _CircularLoader: React.SFC<CircularLoaderProps> = ({
  size,
  fullScreen,
  classes,
}) => (
  <div className={`${classes.wrapper} ${fullScreen && classes.fullScreen}`}>
    <CircularProgress data-testid="loading" size={size || LoaderSizes.big} />
  </div>
);

export const CircularLoader = withStyles(styles)(_CircularLoader);

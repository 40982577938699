import { Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import React, { FC } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { paths } from '../../routing';
import { EditPage } from './EditPage';
import { ListPage } from './ListPage';
import { UnlockPage } from './UnlockPage';

const styles = (theme: Theme) =>
  createStyles({
    pageContent: {
      marginTop: 64,
    },
    search: {
      margin: 0,
      backgroundColor: theme.palette.secondary.main,
    },
    fullWidth: {
      width: '100%',
    },
    input: {
      alignItems: 'center',
      borderRadius: 4,
    },
    textFieldInput: {
      fontSize: 16,
      marginLeft: '8px',
      lineHeight: 1.7,
      color: theme.palette.grey[100],
    },
  });

interface PersonPageProps
  extends RouteComponentProps,
    WithStyles<typeof styles> {}

const _PersonPage: FC<PersonPageProps> = ({ classes }) => (
  <div className={classes.pageContent}>
    <Switch>
      <Route path={paths.PERSON_LIST} component={ListPage} />
      <Route path={paths.PERSON_UNLOCK} component={UnlockPage} />
      <Route
        // Disable ts here cause typings for RR5 are not ready yet...
        path={[paths.PERSON_NEW, paths.PERSON_EDIT]}
        component={EditPage}
      />
      <Redirect to={paths.PERSON_LIST} />
    </Switch>
  </div>
);

export const PersonPage = withStyles(styles)(_PersonPage);

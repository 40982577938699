import { IconButton } from '@mui/material';
import { Delete, DragIndicatorOutlined } from '@mui/icons-material';
import React from 'react';
import { ApolloConsumer } from 'react-apollo';
import { GET_PEOPLE_QUERY } from '../../../../apollo/person/Query/getPeopleQuery.person.graphql';
import { GET_RELATIONSHIP_TITLES_QUERY } from '../../../../apollo/person/Query/getRelationshipTitles.person.graphql';
import { AutoSuggestField, TextField } from '../../../../one-crud-react';
import { simpleQuery } from '../../../../utils/simpleQuery';
import {
  GetPeople,
  GetRelationshipTitles,
  PersonStatus,
} from '../../../../__generated__/queries-person';
import { PersonResource } from '../../config/person.config';
import { RelationshipResource } from '../../config/relationship.config';
import { stylesProps, wrapped } from '../../EditPage/styles';

export interface Relation {
  order: number;
  personTo: {
    id: string;
    fullName: string;
  };
  relationshipTitle: {
    id: string;
    title: string;
    slug: string;
  };
}

type RelationProps = {
  relation: Relation;
  onDelete: (e: React.MouseEvent) => void;
  onUpdate: (
    keys: string[],
    path: string,
  ) => (selectedSuggestion: Record<string, any>) => void;
} & stylesProps;

const RelationWithoutStyle: React.FC<RelationProps> = ({
  classes,
  relation,
  onDelete,
  onUpdate,
}) => (
  <ApolloConsumer>
    {(client) => (
      <>
        <DragIndicatorOutlined />
        {relation.personTo.fullName === '' ? (
          <AutoSuggestField
            keyValue="relationship-name-input"
            fetchSuggestions={(value) =>
              simpleQuery<GetPeople>({
                extraWhereConditions: {
                  status: PersonStatus.Published,
                },
                client: client as any,
                value,
                query: GET_PEOPLE_QUERY,
                resource: PersonResource,
                mapper: (res: any) => ({ id: res.id, label: res.fullName }),
                keyContain: 'fullName',
              })
            }
            inputValue={relation.personTo.fullName}
            suggestionStyle={classes.suggestionStyle}
            onSuggestionSelected={onUpdate(['id', 'fullName'], 'personTo')}
            keepSelectedValue
          />
        ) : (
          <TextField
            keyValue="relationship-name-input"
            field="personTo.fullName"
            item={relation}
          />
        )}
        <AutoSuggestField
          keyValue="relationship-kind-input"
          fetchSuggestions={(value) =>
            simpleQuery<GetRelationshipTitles>({
              client: client as any,
              value,
              query: GET_RELATIONSHIP_TITLES_QUERY,
              resource: RelationshipResource,
              mapper: (res) => ({
                id: res.id,
                label: res.title,
                slug: res.slug,
              }),
            })
          }
          inputValue={relation.relationshipTitle.title}
          suggestionStyle={classes.suggestionStyle}
          onSuggestionSelected={onUpdate(['id', 'title'], 'relationshipTitle')}
          keepSelectedValue
        />
        <IconButton
          data-testid="relationship-deleteBtn"
          aria-label="Delete"
          onClick={onDelete}
          size="large"
        >
          <Delete />
        </IconButton>
      </>
    )}
  </ApolloConsumer>
);

export const RelationComponent = wrapped(RelationWithoutStyle);

import { SchoolResource } from './school.config';
import { Resource } from '../../../one-crud-react';

export const PersonStudyResource: Resource = {
  singular: 'personStudy',
  plural: 'personStudies',
  basePath: 'personStudy',
  keyProperty: 'school',
  isRelationEnity: true,
  linkedResources: [
    {
      field: 'school',
      resource: SchoolResource,
    },
  ],
};

import { ArrowBack } from '@mui/icons-material';
import {
  AppBar,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { brandTitleByKey } from '@prismamedia/one-brandkey';
import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import { isEmpty } from 'ramda';
import { FC } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import uuidv4 from 'uuid/v4';
import {
  BrandKey,
  GetPerson,
  GetPerson_person,
  GetPerson_person_biographies,
  PersonStatus,
} from '../../../__generated__/queries-person';
import { useUnlock } from '../../../apollo/person/Mutations/unlock.person.graphql';
import { UPSERT_PERSON_MUTATION } from '../../../apollo/person/Mutations/upsertPersonMutation.person.graphql';
import { GET_PERSON_QUERY } from '../../../apollo/person/Query/getPersonQuery.person.graphql';
import { useGetBrandKeys } from '../../../apollo/web/brandKeys.web.graphql';
import { CircularLoader } from '../../../components/Loader/Circular';
import { RoutingTabs, TabRoute } from '../../../components/RoutingTabs';
import { ResourceEditContainer, SimpleForm } from '../../../one-crud-react';
import { paths, replaceParams } from '../../../routing';
import {
  brandKeys,
  getBrandKeyFromSlug,
  getBrandSlug,
} from '../../../utils/brands';
import { PersonResource } from '../config/person.config';
import { ActionBtns, AppBarTitle } from './AppBarTitle';
import { BrandTab } from './BrandTab';
import { LockDialogs } from './LockDialogs';
import { Generalities } from './generalities';
import { useStyles } from './styles';

const afterFetch = (item: GetPerson['person']) => {
  if (isEmpty(item) || !item) {
    return item;
  }
  // In order to get the diffArray from one-crud-react lib to return values we need to add an id
  const relationshipTo = item.relationshipTo.map((relationship: any) => ({
    id: uuidv4(),
    ...relationship,
  }));

  const biographies = item.biographies.map(
    (personBiography: GetPerson_person_biographies) => ({
      ...personBiography,
      ...(personBiography.media && {
        media: personBiography.media,
      }),
    }),
  );
  const editedAt = new Date().toISOString();
  return { ...item, biographies, editedAt, relationshipTo };
};

const beforeSave = (item: any) => {
  if (isEmpty(item)) {
    return item;
  }

  const biographies = item.biographies.filter(
    (personBiography: GetPerson_person_biographies) =>
      personBiography.body || personBiography.media,
  );

  return { ...item, biographies };
};

export const EditPage: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch<{ id: string }>();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
  const { data: brandKeysData } = useGetBrandKeys();

  const personId = match.params.id;
  const [unlock] = useUnlock(personId);
  const unlockFn = () => {
    if (personId) {
      unlock().catch((err) => {
        console.warn('Error durant le unlock', err);
      });
    }
    return null;
  };

  const { pushNotification } = useNotification();
  const pushNotificationField = (label: string) => {
    pushNotification({
      message: `Le champ ${label} doit être renseigné pour pouvoir enregistrer.`,
      type: NotificationTypeEnum.error,
    });
  };

  const isCreate = match.path === paths.PERSON_NEW;
  const brandRoutes = Array.from(
    new Set(
      brandKeysData?.categories.map(
        ({ brandKey }) => (brandKey as unknown) as BrandKey,
      ),
    ),
  )
    .filter((brandKey) => brandKeys.includes(brandKey))
    .map((brandKey) => ({
      label: brandTitleByKey[brandKey],
      path: `${match.url}/${getBrandSlug(brandKey)}`,
      brandKey,
    }));

  const emptyResourceItem = {
    countries: [],
    degrees: [],
    jobs: [],
    nominations: [],
    passions: [],
    biographies: [],
    relationshipTo: [],
    schools: [],
    status: PersonStatus.Draft,
    titles: [],
  };
  const tabs: TabRoute[] = [
    {
      label: 'Généralités',
      path: `${match.url}/generalities`,
    },
    ...(brandRoutes ? brandRoutes : []),
  ];

  return (
    <>
      <RoutingTabs pathname={location.pathname} tabs={tabs} />
      <ResourceEditContainer
        mutationProps={{
          mutation: UPSERT_PERSON_MUTATION,
          onCompleted: (data) => {
            pushNotification({
              message: `Ce people a bien été ${
                isCreate ? 'sauvegardé' : 'mis à jour'
              }`,
              type: NotificationTypeEnum.success,
            });

            if (!isCreate || !data.upsertPerson || !data.upsertPerson.id) {
              return;
            }

            history.push(
              replaceParams(`${paths.PERSON_EDIT}/generalities`, {
                id: data.upsertPerson.id,
              }),
            );
          },
          onError: (e) => {
            pushNotification({
              message: e.message,
              type: NotificationTypeEnum.error,
            });
          },
        }}
        resourceConfig={PersonResource}
        query={GET_PERSON_QUERY}
        isCreate={isCreate}
        variables={{
          where: {
            id: personId,
          },
        }}
        fetchPolicy="network-only"
        messages={{
          loading: <CircularLoader fullScreen />,
          error: <Typography>Une erreur est survenue.</Typography>,
          nocontent: <Typography>Aucune données.</Typography>,
        }}
        afterFetch={afterFetch}
        beforeSave={(item: any) => {
          if (!item.sex) {
            pushNotificationField('Sexe');
            return null;
          }
          if (!item.firstName) {
            pushNotificationField('Prénom usuel');
            return null;
          }
          return beforeSave(item);
        }}
        emptyResourceItem={emptyResourceItem}
      >
        {({ item: resourceItem }) => {
          return (
            <SimpleForm className={classes.noShadow}>
              {personId ? <LockDialogs personId={personId} /> : <></>}
              <AppBar color="primary">
                <Toolbar>
                  <IconButton
                    onClick={() => {
                      unlockFn();
                      history.push(
                        !isCreate
                          ? `${paths.PERSON_LIST}?status=${resourceItem.status}`
                          : paths.PERSON_LIST,
                      );
                    }}
                    size="large"
                  >
                    <ArrowBack style={{ color: 'white' }} />
                  </IconButton>

                  {!isSmallDevice && (
                    <AppBarTitle item={resourceItem as GetPerson_person} />
                  )}
                  <ActionBtns />
                </Toolbar>
              </AppBar>

              <Grid container spacing={4} className={classes.fieldsContainer}>
                <Switch>
                  <Route
                    path={`${paths.PERSON_EDIT}/generalities`}
                    render={() => <Generalities />}
                  />
                  <Route
                    path={`${match.url}/:brandSlug`}
                    render={(props) => (
                      <BrandTab
                        brandKey={getBrandKeyFromSlug(
                          props.match.params.brandSlug || '',
                        )}
                      />
                    )}
                  />
                </Switch>
              </Grid>
            </SimpleForm>
          );
        }}
      </ResourceEditContainer>
    </>
  );
};

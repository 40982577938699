import * as R from 'ramda';

import { Resource } from './genericTypes';

import {
  isObjectTruthy,
  ObjectOrNull,
} from '../../crud-core/utils/TypescriptUtils';

function isSameObjectButModified(
  newItem: Record<string, unknown>,
  oldItem: any,
  resource: Resource,
) {
  return (
    R.propEq(resource.keyProperty, oldItem[resource.keyProperty])(newItem) &&
    !R.equals(newItem, oldItem)
  );
}

export function diffObject(
  newData: ObjectOrNull,
  oldData: ObjectOrNull,
  resource: Resource,
): {
  deleted: ObjectOrNull;
  updated: ObjectOrNull;
  created: ObjectOrNull;
} {
  const defaultValues = { updated: null, deleted: null, created: null };
  // Created
  if (!isObjectTruthy(oldData)) {
    return {
      ...defaultValues,
      created: isObjectTruthy(newData) ? newData : null,
    };
  }

  // Deleted
  if (!isObjectTruthy(newData)) {
    return { ...defaultValues, deleted: oldData };
  }

  // Updated
  if (isSameObjectButModified(newData, oldData, resource)) {
    return { ...defaultValues, updated: newData };
  }

  // Replaced
  if (!R.equals(newData, oldData)) {
    return {
      ...defaultValues,
      created: newData,
      deleted: oldData,
    };
  }

  return defaultValues;
}

import { gql } from '@apollo/client';

export const UPSERT_AWARD_MUTATION = gql`
  mutation UpsertAward(
    $where: AwardUniqueFilterInput!
    $update: AwardUpdateInput!
    $create: AwardCreationInput!
  ) {
    upsertAward(create: $create, update: $update, where: $where) {
      id
      title
    }
  }
`;

import { ApolloClient, FetchPolicy } from '@apollo/client';
import { Resource } from '../one-crud-react';

interface SimpleQueryArguments<T> {
  client: ApolloClient<any>;
  value: string;
  query: any;
  resource: Resource;
  mapper: (
    res: T[keyof T] extends (infer U)[] ? U : never,
  ) => Record<string, any>;
  keyContain?: string;
  extraWhereConditions?: Record<string, any>;
  extraVariables?: Record<string, any>;
  fetchPolicy?: FetchPolicy;
}

export const simpleQuery = async <T>({
  client,
  value,
  query,
  resource,
  mapper,
  keyContain = 'title',
  extraWhereConditions = {},
  extraVariables = {},
  fetchPolicy = 'cache-first',
}: SimpleQueryArguments<T>) => {
  const variables = {
    where: {
      [`${keyContain}_contains`]: value,
      ...extraWhereConditions,
    },
    first: 10,
    ...extraVariables,
  };

  const { data } = await client.query<T>({
    query,
    variables,
    fetchPolicy,
  });

  if ((data as any)?.[resource.plural]?.length) {
    return (data as any)[resource.plural].map(mapper);
  }

  return [];
};

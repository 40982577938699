import React from 'react';
import { remove, lensPath, over } from 'ramda';

import { useEditContextForInput } from '../../../../one-crud-react';

export function useResource<T>(resourceName: string) {
  const { fieldValue, setFieldValue, onChange } = useEditContextForInput<T[]>(
    resourceName,
  );

  const onDelete = (index: number) => (e: React.MouseEvent) => {
    e.stopPropagation();
    const items: T[] = remove(index, 1, fieldValue as T[]);
    onChange(items);
  };

  const onUpdate = (index: number) => (keys: string[] | null, path: string) => (
    selectedSuggestion: Record<string, any>,
  ) => {
    const { id, label, ...rest } = selectedSuggestion;
    const lens = lensPath([index, path]);
    const items = over(
      lens,
      (nomination: T) =>
        Array.isArray(keys)
          ? keys.reduce(
              (acc, key, i) => ({
                ...acc,
                ...rest,
                [key]: i === 0 ? id : label,
              }),
              nomination,
            )
          : id,
      fieldValue,
    );
    onChange(items);
  };

  return {
    setResources: setFieldValue,
    resources: fieldValue || [],
    onDelete,
    onUpdate,
    onChange,
  };
}

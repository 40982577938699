import { gql } from '@apollo/client';

import { PERSON_FRAMENT } from '../Fragments/personFragment.person.graphql';

export const UPSERT_PERSON_MUTATION = gql`
  mutation UpsertPerson(
    $where: PersonUniqueFilterInput!
    $create: PersonCreationInput!
    $update: PersonUpdateInput!
  ) {
    upsertPerson(create: $create, update: $update, where: $where) {
      ...Person
    }
  }
  ${PERSON_FRAMENT}
`;

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const styles = () =>
  createStyles({
    body: { textAlign: 'center' },
    dialogContent: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      padding: 10,
    },
    header: { display: 'flex' },
    title: { fontWeight: 'bold' },
    secondDisplay: { fontWeight: 'bold', marginTop: 10 },
  });

export const useStyles = makeStyles(styles);

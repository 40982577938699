import { TextFieldProps } from '@mui/material';
import { useState } from 'react';
import { TextInput, useEditContextForInput } from '../../one-crud-react';
import { isUrl } from './utils';

export const UrlTextInput = ({
  field,
  label,
  textFieldProps,
}: {
  field: string;
  label?: string;
  textFieldProps?: TextFieldProps;
}) => {
  const { fieldValue, onChange } = useEditContextForInput(field);
  const [isFocus, setIsFocus] = useState(false);

  const error = !!fieldValue && !isUrl(fieldValue as string) && !isFocus;

  return (
    <TextInput
      field={field}
      label={label}
      textFieldProps={{
        onFocus: () => setIsFocus(true),
        onBlur: () => setIsFocus(false),
        value: fieldValue,
        onChange: ({ target }) => onChange(target.value || null),
        error,
        helperText: error && "Ce n'est pas une URL valide",
        ...textFieldProps,
      }}
    />
  );
};

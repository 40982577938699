/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LockPerson
// ====================================================

export interface LockPerson_updatePerson {
  __typename: "Person";
  lockerId: GraphQL$UUIDv4 | null;
}

export interface LockPerson {
  /**
   * Updates one "Person", throws an error if it does not exists
   */
  updatePerson: LockPerson_updatePerson;
}

export interface LockPersonVariables {
  personId?: GraphQL$UUIDv4 | null;
  lockerId?: GraphQL$UUIDv4 | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnlockPerson
// ====================================================

export interface UnlockPerson_updatePerson {
  __typename: "Person";
  lockerId: GraphQL$UUIDv4 | null;
  fullName: GraphQL$NonEmptyTrimmedString;
}

export interface UnlockPerson {
  /**
   * Updates one "Person", throws an error if it does not exists
   */
  updatePerson: UnlockPerson_updatePerson;
}

export interface UnlockPersonVariables {
  personId?: GraphQL$UUIDv4 | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertAward
// ====================================================

export interface UpsertAward_upsertAward {
  __typename: "Award";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface UpsertAward {
  /**
   * Updates an existing "Award" or creates a new one
   */
  upsertAward: UpsertAward_upsertAward;
}

export interface UpsertAwardVariables {
  where: AwardUniqueFilterInput;
  update: AwardUpdateInput;
  create: AwardCreationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertCountry
// ====================================================

export interface UpsertCountry_upsertCountry {
  __typename: "Country";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface UpsertCountry {
  /**
   * Updates an existing "Country" or creates a new one
   */
  upsertCountry: UpsertCountry_upsertCountry;
}

export interface UpsertCountryVariables {
  where: CountryUniqueFilterInput;
  update: CountryUpdateInput;
  create: CountryCreationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertJob
// ====================================================

export interface UpsertJob_upsertJob {
  __typename: "Job";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface UpsertJob {
  /**
   * Updates an existing "Job" or creates a new one
   */
  upsertJob: UpsertJob_upsertJob;
}

export interface UpsertJobVariables {
  where: JobUniqueFilterInput;
  update: JobUpdateInput;
  create: JobCreationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertPerson
// ====================================================

export interface UpsertPerson_upsertPerson_biographies {
  __typename: "PersonBiography";
  body: GraphQL$NonEmptyTrimmedString | null;
  brandKey: BrandKey;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  editedAt: GraphQL$DateTime | null;
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  media: GraphQL$JSONObject | null;
  status: PersonBiographyStatus;
  /**
   * The date and time of the document's last update
   */
  updatedAt: GraphQL$DateTime;
}

export interface UpsertPerson_upsertPerson_lockedBy {
  __typename: "User";
  id: string;
  name: string | null;
  avatarUrl: string | null;
  email: string | null;
}

export interface UpsertPerson_upsertPerson_nominations_award {
  __typename: "Award";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface UpsertPerson_upsertPerson_nominations {
  __typename: "PersonNomination";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  work: GraphQL$NonEmptyTrimmedString | null;
  year: GraphQL$UnsignedInt | null;
  award: UpsertPerson_upsertPerson_nominations_award;
}

export interface UpsertPerson_upsertPerson_jobs_job {
  __typename: "Job";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface UpsertPerson_upsertPerson_jobs {
  __typename: "PersonJob";
  job: UpsertPerson_upsertPerson_jobs_job;
}

export interface UpsertPerson_upsertPerson_countries_country {
  __typename: "Country";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface UpsertPerson_upsertPerson_countries {
  __typename: "PersonCountry";
  country: UpsertPerson_upsertPerson_countries_country;
}

export interface UpsertPerson_upsertPerson_schools_school {
  __typename: "School";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface UpsertPerson_upsertPerson_schools {
  __typename: "PersonStudy";
  school: UpsertPerson_upsertPerson_schools_school;
}

export interface UpsertPerson_upsertPerson_degrees_degree {
  __typename: "Degree";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface UpsertPerson_upsertPerson_degrees {
  __typename: "PersonDegree";
  degree: UpsertPerson_upsertPerson_degrees_degree;
}

export interface UpsertPerson_upsertPerson_passions_passion {
  __typename: "Passion";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface UpsertPerson_upsertPerson_passions {
  __typename: "PersonPassion";
  passion: UpsertPerson_upsertPerson_passions_passion;
}

export interface UpsertPerson_upsertPerson_titles_title {
  __typename: "Title";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface UpsertPerson_upsertPerson_titles {
  __typename: "PersonTitle";
  title: UpsertPerson_upsertPerson_titles_title;
}

export interface UpsertPerson_upsertPerson_relationshipTo_personTo {
  __typename: "Person";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  fullName: GraphQL$NonEmptyTrimmedString;
}

export interface UpsertPerson_upsertPerson_relationshipTo_relationshipTitle {
  __typename: "RelationshipTitle";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
  slug: GraphQL$NonEmptyTrimmedString;
}

export interface UpsertPerson_upsertPerson_relationshipTo {
  __typename: "PersonRelationship";
  order: GraphQL$UnsignedInt | null;
  personTo: UpsertPerson_upsertPerson_relationshipTo_personTo;
  /**
   * This title is meant to read as: "personTo" is "relationshipTitle" with "personFrom"
   */
  relationshipTitle: UpsertPerson_upsertPerson_relationshipTo_relationshipTitle;
}

export interface UpsertPerson_upsertPerson {
  __typename: "Person";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  firstName: GraphQL$NonEmptyTrimmedString;
  lastName: GraphQL$NonEmptyTrimmedString | null;
  birth: GraphQL$Date | null;
  death: GraphQL$Date | null;
  residence: GraphQL$NonEmptyTrimmedString | null;
  status: PersonStatus;
  biographies: UpsertPerson_upsertPerson_biographies[];
  /**
   * His facebook profile
   */
  facebook: GraphQL$URL | null;
  /**
   * His twitch profile
   */
  twitch: GraphQL$URL | null;
  /**
   * His instagram profile
   */
  instagram: GraphQL$URL | null;
  /**
   * His twitter profile
   */
  twitter: GraphQL$URL | null;
  /**
   * His youtube profile
   */
  youtube: GraphQL$URL | null;
  /**
   * His snapchat profile
   */
  snapchat: GraphQL$URL | null;
  /**
   * His tiktok profile
   */
  tiktok: GraphQL$URL | null;
  isLocked: boolean;
  lockedAt: GraphQL$DateTime | null;
  lockerId: GraphQL$UUIDv4 | null;
  lockedBy: UpsertPerson_upsertPerson_lockedBy | null;
  nominations: UpsertPerson_upsertPerson_nominations[];
  jobs: UpsertPerson_upsertPerson_jobs[];
  countries: UpsertPerson_upsertPerson_countries[];
  realFirstName: GraphQL$NonEmptyTrimmedString | null;
  realLastName: GraphQL$NonEmptyTrimmedString | null;
  sex: GraphQL$NonEmptyTrimmedString | null;
  schools: UpsertPerson_upsertPerson_schools[];
  degrees: UpsertPerson_upsertPerson_degrees[];
  passions: UpsertPerson_upsertPerson_passions[];
  titles: UpsertPerson_upsertPerson_titles[];
  relationshipTo: UpsertPerson_upsertPerson_relationshipTo[];
  website: GraphQL$URL | null;
  /**
   * The date and time of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  editedAt: GraphQL$DateTime | null;
}

export interface UpsertPerson {
  /**
   * Updates an existing "Person" or creates a new one
   */
  upsertPerson: UpsertPerson_upsertPerson;
}

export interface UpsertPersonVariables {
  where: PersonUniqueFilterInput;
  create: PersonCreationInput;
  update: PersonUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAwards
// ====================================================

export interface GetAwards_awards {
  __typename: "Award";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface GetAwards {
  /**
   * Retrieves a list of "Awards"
   */
  awards: GetAwards_awards[];
}

export interface GetAwardsVariables {
  first: GraphQL$UnsignedInt;
  where?: AwardFilterInput | null;
  skip?: GraphQL$UnsignedInt | null;
  orderBy?: AwardOrderingInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCountry
// ====================================================

export interface GetCountry_countries {
  __typename: "Country";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface GetCountry {
  /**
   * Retrieves a list of "Countries"
   */
  countries: GetCountry_countries[];
}

export interface GetCountryVariables {
  first: GraphQL$UnsignedInt;
  where?: CountryFilterInput | null;
  skip?: GraphQL$UnsignedInt | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDegrees
// ====================================================

export interface GetDegrees_degrees {
  __typename: "Degree";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface GetDegrees {
  /**
   * Retrieves a list of "Degrees"
   */
  degrees: GetDegrees_degrees[];
}

export interface GetDegreesVariables {
  first: GraphQL$UnsignedInt;
  where?: DegreeFilterInput | null;
  skip?: GraphQL$UnsignedInt | null;
  orderBy?: DegreeOrderingInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetJob
// ====================================================

export interface GetJob_jobs {
  __typename: "Job";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface GetJob {
  /**
   * Retrieves a list of "Jobs"
   */
  jobs: GetJob_jobs[];
}

export interface GetJobVariables {
  first: GraphQL$UnsignedInt;
  where?: JobFilterInput | null;
  skip?: GraphQL$UnsignedInt | null;
  orderBy?: JobOrderingInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPassions
// ====================================================

export interface GetPassions_passions {
  __typename: "Passion";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface GetPassions {
  /**
   * Retrieves a list of "Passions"
   */
  passions: GetPassions_passions[];
}

export interface GetPassionsVariables {
  first: GraphQL$UnsignedInt;
  where?: PassionFilterInput | null;
  skip?: GraphQL$UnsignedInt | null;
  orderBy?: PassionOrderingInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPeople
// ====================================================

export interface GetPeople_people_lockedBy {
  __typename: "User";
  id: string;
  name: string | null;
  avatarUrl: string | null;
}

export interface GetPeople_people {
  __typename: "Person";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  fullName: GraphQL$NonEmptyTrimmedString;
  /**
   * Retrieve the list of brand having content
   */
  existingBrands: BrandKey[];
  lockedBy: GetPeople_people_lockedBy | null;
  /**
   * The date and time of the document's last update
   */
  updatedAt: GraphQL$DateTime;
}

export interface GetPeople {
  /**
   * Retrieves a list of "People"
   */
  people: GetPeople_people[];
  /**
   * Gets the number of "People"
   */
  personCount: GraphQL$UnsignedInt;
}

export interface GetPeopleVariables {
  first: GraphQL$UnsignedInt;
  where?: PersonFilterInput | null;
  skip?: GraphQL$UnsignedInt | null;
  orderBy?: PersonOrderingInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPeopleCount
// ====================================================

export interface GetPeopleCount {
  /**
   * Gets the number of "People"
   */
  personCount: GraphQL$UnsignedInt;
}

export interface GetPeopleCountVariables {
  where?: PersonFilterInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPerson
// ====================================================

export interface GetPerson_person_biographies {
  __typename: "PersonBiography";
  body: GraphQL$NonEmptyTrimmedString | null;
  brandKey: BrandKey;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  editedAt: GraphQL$DateTime | null;
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  media: GraphQL$JSONObject | null;
  status: PersonBiographyStatus;
  /**
   * The date and time of the document's last update
   */
  updatedAt: GraphQL$DateTime;
}

export interface GetPerson_person_lockedBy {
  __typename: "User";
  id: string;
  name: string | null;
  avatarUrl: string | null;
  email: string | null;
}

export interface GetPerson_person_nominations_award {
  __typename: "Award";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface GetPerson_person_nominations {
  __typename: "PersonNomination";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  work: GraphQL$NonEmptyTrimmedString | null;
  year: GraphQL$UnsignedInt | null;
  award: GetPerson_person_nominations_award;
}

export interface GetPerson_person_jobs_job {
  __typename: "Job";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface GetPerson_person_jobs {
  __typename: "PersonJob";
  job: GetPerson_person_jobs_job;
}

export interface GetPerson_person_countries_country {
  __typename: "Country";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface GetPerson_person_countries {
  __typename: "PersonCountry";
  country: GetPerson_person_countries_country;
}

export interface GetPerson_person_schools_school {
  __typename: "School";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface GetPerson_person_schools {
  __typename: "PersonStudy";
  school: GetPerson_person_schools_school;
}

export interface GetPerson_person_degrees_degree {
  __typename: "Degree";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface GetPerson_person_degrees {
  __typename: "PersonDegree";
  degree: GetPerson_person_degrees_degree;
}

export interface GetPerson_person_passions_passion {
  __typename: "Passion";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface GetPerson_person_passions {
  __typename: "PersonPassion";
  passion: GetPerson_person_passions_passion;
}

export interface GetPerson_person_titles_title {
  __typename: "Title";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface GetPerson_person_titles {
  __typename: "PersonTitle";
  title: GetPerson_person_titles_title;
}

export interface GetPerson_person_relationshipTo_personTo {
  __typename: "Person";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  fullName: GraphQL$NonEmptyTrimmedString;
}

export interface GetPerson_person_relationshipTo_relationshipTitle {
  __typename: "RelationshipTitle";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
  slug: GraphQL$NonEmptyTrimmedString;
}

export interface GetPerson_person_relationshipTo {
  __typename: "PersonRelationship";
  order: GraphQL$UnsignedInt | null;
  personTo: GetPerson_person_relationshipTo_personTo;
  /**
   * This title is meant to read as: "personTo" is "relationshipTitle" with "personFrom"
   */
  relationshipTitle: GetPerson_person_relationshipTo_relationshipTitle;
}

export interface GetPerson_person {
  __typename: "Person";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  firstName: GraphQL$NonEmptyTrimmedString;
  lastName: GraphQL$NonEmptyTrimmedString | null;
  birth: GraphQL$Date | null;
  death: GraphQL$Date | null;
  residence: GraphQL$NonEmptyTrimmedString | null;
  status: PersonStatus;
  biographies: GetPerson_person_biographies[];
  /**
   * His facebook profile
   */
  facebook: GraphQL$URL | null;
  /**
   * His twitch profile
   */
  twitch: GraphQL$URL | null;
  /**
   * His instagram profile
   */
  instagram: GraphQL$URL | null;
  /**
   * His twitter profile
   */
  twitter: GraphQL$URL | null;
  /**
   * His youtube profile
   */
  youtube: GraphQL$URL | null;
  /**
   * His snapchat profile
   */
  snapchat: GraphQL$URL | null;
  /**
   * His tiktok profile
   */
  tiktok: GraphQL$URL | null;
  isLocked: boolean;
  lockedAt: GraphQL$DateTime | null;
  lockerId: GraphQL$UUIDv4 | null;
  lockedBy: GetPerson_person_lockedBy | null;
  nominations: GetPerson_person_nominations[];
  jobs: GetPerson_person_jobs[];
  countries: GetPerson_person_countries[];
  realFirstName: GraphQL$NonEmptyTrimmedString | null;
  realLastName: GraphQL$NonEmptyTrimmedString | null;
  sex: GraphQL$NonEmptyTrimmedString | null;
  schools: GetPerson_person_schools[];
  degrees: GetPerson_person_degrees[];
  passions: GetPerson_person_passions[];
  titles: GetPerson_person_titles[];
  relationshipTo: GetPerson_person_relationshipTo[];
  website: GraphQL$URL | null;
  /**
   * The date and time of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  editedAt: GraphQL$DateTime | null;
}

export interface GetPerson {
  /**
   * Retrieves one "Person", throws an error if it does not exist
   */
  person: GetPerson_person;
}

export interface GetPersonVariables {
  where: PersonUniqueFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRelationshipTitles
// ====================================================

export interface GetRelationshipTitles_relationshipTitles {
  __typename: "RelationshipTitle";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
  slug: GraphQL$NonEmptyTrimmedString;
}

export interface GetRelationshipTitles {
  /**
   * Retrieves a list of "RelationshipTitles"
   */
  relationshipTitles: GetRelationshipTitles_relationshipTitles[];
}

export interface GetRelationshipTitlesVariables {
  first: GraphQL$UnsignedInt;
  where?: RelationshipTitleFilterInput | null;
  skip?: GraphQL$UnsignedInt | null;
  orderBy?: RelationshipTitleOrderingInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSchools
// ====================================================

export interface GetSchools_schools {
  __typename: "School";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface GetSchools {
  /**
   * Retrieves a list of "Schools"
   */
  schools: GetSchools_schools[];
}

export interface GetSchoolsVariables {
  first: GraphQL$UnsignedInt;
  where?: SchoolFilterInput | null;
  skip?: GraphQL$UnsignedInt | null;
  orderBy?: SchoolOrderingInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTitles
// ====================================================

export interface GetTitles_titles {
  __typename: "Title";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface GetTitles {
  /**
   * Retrieves a list of "Titles"
   */
  titles: GetTitles_titles[];
}

export interface GetTitlesVariables {
  first: GraphQL$UnsignedInt;
  where?: TitleFilterInput | null;
  skip?: GraphQL$UnsignedInt | null;
  orderBy?: TitleOrderingInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPersonHistory
// ====================================================

export interface GetPersonHistory_person_histories_user {
  __typename: "User";
  name: string | null;
  avatarUrl: string | null;
}

export interface GetPersonHistory_person_histories {
  __typename: "PersonHistory";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  status: PersonStatus;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  statusBioByBrandKey: GraphQL$JSONObject | null;
  user: GetPersonHistory_person_histories_user | null;
}

export interface GetPersonHistory_person {
  __typename: "Person";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  fullName: GraphQL$NonEmptyTrimmedString;
  histories: GetPersonHistory_person_histories[];
}

export interface GetPersonHistory {
  /**
   * Retrieves one "Person", throws an error if it does not exist
   */
  person: GetPersonHistory_person;
}

export interface GetPersonHistoryVariables {
  where: PersonUniqueFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Country
// ====================================================

export interface Country {
  __typename: "Country";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Job
// ====================================================

export interface Job {
  __typename: "Job";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Person
// ====================================================

export interface Person_biographies {
  __typename: "PersonBiography";
  body: GraphQL$NonEmptyTrimmedString | null;
  brandKey: BrandKey;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  editedAt: GraphQL$DateTime | null;
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  media: GraphQL$JSONObject | null;
  status: PersonBiographyStatus;
  /**
   * The date and time of the document's last update
   */
  updatedAt: GraphQL$DateTime;
}

export interface Person_lockedBy {
  __typename: "User";
  id: string;
  name: string | null;
  avatarUrl: string | null;
  email: string | null;
}

export interface Person_nominations_award {
  __typename: "Award";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface Person_nominations {
  __typename: "PersonNomination";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  work: GraphQL$NonEmptyTrimmedString | null;
  year: GraphQL$UnsignedInt | null;
  award: Person_nominations_award;
}

export interface Person_jobs_job {
  __typename: "Job";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface Person_jobs {
  __typename: "PersonJob";
  job: Person_jobs_job;
}

export interface Person_countries_country {
  __typename: "Country";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface Person_countries {
  __typename: "PersonCountry";
  country: Person_countries_country;
}

export interface Person_schools_school {
  __typename: "School";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface Person_schools {
  __typename: "PersonStudy";
  school: Person_schools_school;
}

export interface Person_degrees_degree {
  __typename: "Degree";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface Person_degrees {
  __typename: "PersonDegree";
  degree: Person_degrees_degree;
}

export interface Person_passions_passion {
  __typename: "Passion";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface Person_passions {
  __typename: "PersonPassion";
  passion: Person_passions_passion;
}

export interface Person_titles_title {
  __typename: "Title";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
}

export interface Person_titles {
  __typename: "PersonTitle";
  title: Person_titles_title;
}

export interface Person_relationshipTo_personTo {
  __typename: "Person";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  fullName: GraphQL$NonEmptyTrimmedString;
}

export interface Person_relationshipTo_relationshipTitle {
  __typename: "RelationshipTitle";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  title: GraphQL$NonEmptyTrimmedString;
  slug: GraphQL$NonEmptyTrimmedString;
}

export interface Person_relationshipTo {
  __typename: "PersonRelationship";
  order: GraphQL$UnsignedInt | null;
  personTo: Person_relationshipTo_personTo;
  /**
   * This title is meant to read as: "personTo" is "relationshipTitle" with "personFrom"
   */
  relationshipTitle: Person_relationshipTo_relationshipTitle;
}

export interface Person {
  __typename: "Person";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  firstName: GraphQL$NonEmptyTrimmedString;
  lastName: GraphQL$NonEmptyTrimmedString | null;
  birth: GraphQL$Date | null;
  death: GraphQL$Date | null;
  residence: GraphQL$NonEmptyTrimmedString | null;
  status: PersonStatus;
  biographies: Person_biographies[];
  /**
   * His facebook profile
   */
  facebook: GraphQL$URL | null;
  /**
   * His twitch profile
   */
  twitch: GraphQL$URL | null;
  /**
   * His instagram profile
   */
  instagram: GraphQL$URL | null;
  /**
   * His twitter profile
   */
  twitter: GraphQL$URL | null;
  /**
   * His youtube profile
   */
  youtube: GraphQL$URL | null;
  /**
   * His snapchat profile
   */
  snapchat: GraphQL$URL | null;
  /**
   * His tiktok profile
   */
  tiktok: GraphQL$URL | null;
  isLocked: boolean;
  lockedAt: GraphQL$DateTime | null;
  lockerId: GraphQL$UUIDv4 | null;
  lockedBy: Person_lockedBy | null;
  nominations: Person_nominations[];
  jobs: Person_jobs[];
  countries: Person_countries[];
  realFirstName: GraphQL$NonEmptyTrimmedString | null;
  realLastName: GraphQL$NonEmptyTrimmedString | null;
  sex: GraphQL$NonEmptyTrimmedString | null;
  schools: Person_schools[];
  degrees: Person_degrees[];
  passions: Person_passions[];
  titles: Person_titles[];
  relationshipTo: Person_relationshipTo[];
  website: GraphQL$URL | null;
  /**
   * The date and time of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  editedAt: GraphQL$DateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LightPerson
// ====================================================

export interface LightPerson_lockedBy {
  __typename: "User";
  id: string;
  name: string | null;
  avatarUrl: string | null;
}

export interface LightPerson {
  __typename: "Person";
  /**
   * The public ID
   */
  id: GraphQL$UUIDv4;
  fullName: GraphQL$NonEmptyTrimmedString;
  /**
   * Retrieve the list of brand having content
   */
  existingBrands: BrandKey[];
  lockedBy: LightPerson_lockedBy | null;
  /**
   * The date and time of the document's last update
   */
  updatedAt: GraphQL$DateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AstrologicalSign {
  Aquarius = "Aquarius",
  Aries = "Aries",
  Cancer = "Cancer",
  Capricorn = "Capricorn",
  Gemini = "Gemini",
  Leo = "Leo",
  Libra = "Libra",
  Pisces = "Pisces",
  Sagittarius = "Sagittarius",
  Scorpio = "Scorpio",
  Taurus = "Taurus",
  Virgo = "Virgo",
}

/**
 * Order the "Award" nodes
 */
export enum AwardOrderingInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  personCount_ASC = "personCount_ASC",
  personCount_DESC = "personCount_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum BrandKey {
  FAC = "FAC",
  FRGSG = "FRGSG",
  FRGSS = "FRGSS",
  FRGTS = "FRGTS",
  FROMM = "FROMM",
  GAL = "GAL",
  TEL = "TEL",
  VOI = "VOI",
}

/**
 * Order the "Degree" nodes
 */
export enum DegreeOrderingInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  personCount_ASC = "personCount_ASC",
  personCount_DESC = "personCount_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/**
 * Order the "Job" nodes
 */
export enum JobOrderingInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  personCount_ASC = "personCount_ASC",
  personCount_DESC = "personCount_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/**
 * Order the "Passion" nodes
 */
export enum PassionOrderingInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  personCount_ASC = "personCount_ASC",
  personCount_DESC = "personCount_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum PersonBiographyStatus {
  Draft = "Draft",
  Published = "Published",
}

/**
 * Order the "Person" nodes
 */
export enum PersonOrderingInput {
  biographyCount_ASC = "biographyCount_ASC",
  biographyCount_DESC = "biographyCount_DESC",
  birth_ASC = "birth_ASC",
  birth_DESC = "birth_DESC",
  countryCount_ASC = "countryCount_ASC",
  countryCount_DESC = "countryCount_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  death_ASC = "death_ASC",
  death_DESC = "death_DESC",
  degreeCount_ASC = "degreeCount_ASC",
  degreeCount_DESC = "degreeCount_DESC",
  editedAt_ASC = "editedAt_ASC",
  editedAt_DESC = "editedAt_DESC",
  fullName_ASC = "fullName_ASC",
  fullName_DESC = "fullName_DESC",
  historyCount_ASC = "historyCount_ASC",
  historyCount_DESC = "historyCount_DESC",
  jobCount_ASC = "jobCount_ASC",
  jobCount_DESC = "jobCount_DESC",
  lockedAt_ASC = "lockedAt_ASC",
  lockedAt_DESC = "lockedAt_DESC",
  lockerId_ASC = "lockerId_ASC",
  lockerId_DESC = "lockerId_DESC",
  nominationCount_ASC = "nominationCount_ASC",
  nominationCount_DESC = "nominationCount_DESC",
  passionCount_ASC = "passionCount_ASC",
  passionCount_DESC = "passionCount_DESC",
  prismamediaId_ASC = "prismamediaId_ASC",
  prismamediaId_DESC = "prismamediaId_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  relationshipFromCount_ASC = "relationshipFromCount_ASC",
  relationshipFromCount_DESC = "relationshipFromCount_DESC",
  relationshipToCount_ASC = "relationshipToCount_ASC",
  relationshipToCount_DESC = "relationshipToCount_DESC",
  schoolCount_ASC = "schoolCount_ASC",
  schoolCount_DESC = "schoolCount_DESC",
  titleCount_ASC = "titleCount_ASC",
  titleCount_DESC = "titleCount_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum PersonStatus {
  Deleted = "Deleted",
  Draft = "Draft",
  Published = "Published",
}

/**
 * Order the "RelationshipTitle" nodes
 */
export enum RelationshipTitleOrderingInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  personRelationshipCount_ASC = "personRelationshipCount_ASC",
  personRelationshipCount_DESC = "personRelationshipCount_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/**
 * Order the "School" nodes
 */
export enum SchoolOrderingInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  personCount_ASC = "personCount_ASC",
  personCount_DESC = "personCount_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/**
 * Order the "Title" nodes
 */
export enum TitleOrderingInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  personCount_ASC = "personCount_ASC",
  personCount_DESC = "personCount_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/**
 * The "Award" node's creation
 */
export interface AwardCreationInput {
  id?: GraphQL$UUIDv4 | null;
  title: GraphQL$NonEmptyTrimmedString;
  people?: AwardCreationPeopleInput | null;
}

export interface AwardCreationPeopleInput {
  create?: PersonNominationCreationWithoutAwardInput[] | null;
}

/**
 * The "Award" nodes' filter
 */
export interface AwardFilterInput {
  id?: GraphQL$UUIDv4 | null;
  id_not?: GraphQL$UUIDv4 | null;
  id_in?: GraphQL$UUIDv4[] | null;
  id_not_in?: GraphQL$UUIDv4[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  title?: GraphQL$NonEmptyTrimmedString | null;
  title_not?: GraphQL$NonEmptyTrimmedString | null;
  title_in?: GraphQL$NonEmptyTrimmedString[] | null;
  title_not_in?: GraphQL$NonEmptyTrimmedString[] | null;
  title_gt?: GraphQL$NonEmptyTrimmedString | null;
  title_gte?: GraphQL$NonEmptyTrimmedString | null;
  title_lt?: GraphQL$NonEmptyTrimmedString | null;
  title_lte?: GraphQL$NonEmptyTrimmedString | null;
  title_contains?: GraphQL$NonEmptyString | null;
  title_not_contains?: GraphQL$NonEmptyString | null;
  title_starts_with?: GraphQL$NonEmptyString | null;
  title_not_starts_with?: GraphQL$NonEmptyString | null;
  title_ends_with?: GraphQL$NonEmptyString | null;
  title_not_ends_with?: GraphQL$NonEmptyString | null;
  people_every?: PersonNominationFilterInput | null;
  people_some?: PersonNominationFilterInput | null;
  people_none?: PersonNominationFilterInput | null;
  personCount?: GraphQL$UnsignedInt | null;
  personCount_not?: GraphQL$UnsignedInt | null;
  personCount_gt?: GraphQL$UnsignedInt | null;
  personCount_gte?: GraphQL$UnsignedInt | null;
  personCount_lt?: GraphQL$UnsignedInt | null;
  personCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (AwardFilterInput | null)[] | null;
  OR?: (AwardFilterInput | null)[] | null;
  NOT?: AwardFilterInput | null;
}

/**
 * Identifies exactly one "Award" with one of the following combination of components' value:
 * - id
 * - title
 */
export interface AwardUniqueFilterInput {
  id?: GraphQL$UUIDv4 | null;
  title?: GraphQL$NonEmptyTrimmedString | null;
}

/**
 * The "Award" node's update
 */
export interface AwardUpdateInput {
  title?: GraphQL$NonEmptyTrimmedString | null;
  people?: AwardUpdatePeopleInput | null;
}

export interface AwardUpdatePeopleCreateIfNotExistsInput {
  where: PersonNominationUniqueFilterWithoutAwardInput;
  data: PersonNominationCreationWithoutAwardInput;
}

export interface AwardUpdatePeopleInput {
  deleteAll?: boolean | null;
  deleteMany?: PersonNominationFilterInput | null;
  delete?: PersonNominationUniqueFilterWithoutAwardInput[] | null;
  deleteIfExists?: PersonNominationUniqueFilterWithoutAwardInput[] | null;
  create?: PersonNominationCreationWithoutAwardInput[] | null;
  createIfNotExists?: AwardUpdatePeopleCreateIfNotExistsInput[] | null;
  updateAll?: PersonNominationUpdateWithoutAwardInput | null;
  updateMany?: AwardUpdatePeopleUpdateManyInput | null;
  update?: AwardUpdatePeopleUpdateInput[] | null;
  updateIfExists?: AwardUpdatePeopleUpdateIfExistsInput[] | null;
  upsert?: AwardUpdatePeopleUpsertInput[] | null;
}

export interface AwardUpdatePeopleUpdateIfExistsInput {
  where: PersonNominationUniqueFilterWithoutAwardInput;
  data?: PersonNominationUpdateWithoutAwardInput | null;
}

export interface AwardUpdatePeopleUpdateInput {
  where: PersonNominationUniqueFilterWithoutAwardInput;
  data?: PersonNominationUpdateWithoutAwardInput | null;
}

export interface AwardUpdatePeopleUpdateManyInput {
  where?: PersonNominationFilterInput | null;
  data?: PersonNominationUpdateWithoutAwardInput | null;
}

export interface AwardUpdatePeopleUpsertInput {
  where: PersonNominationUniqueFilterWithoutAwardInput;
  create: PersonNominationCreationWithoutAwardInput;
  update?: PersonNominationUpdateWithoutAwardInput | null;
}

/**
 * The "Country" node's creation
 */
export interface CountryCreationInput {
  id?: GraphQL$UUIDv4 | null;
  title: GraphQL$NonEmptyTrimmedString;
  people?: CountryCreationPeopleInput | null;
}

export interface CountryCreationPeopleInput {
  create?: PersonCountryCreationWithoutCountryInput[] | null;
}

/**
 * The "Country" nodes' filter
 */
export interface CountryFilterInput {
  id?: GraphQL$UUIDv4 | null;
  id_not?: GraphQL$UUIDv4 | null;
  id_in?: GraphQL$UUIDv4[] | null;
  id_not_in?: GraphQL$UUIDv4[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  title?: GraphQL$NonEmptyTrimmedString | null;
  title_not?: GraphQL$NonEmptyTrimmedString | null;
  title_in?: GraphQL$NonEmptyTrimmedString[] | null;
  title_not_in?: GraphQL$NonEmptyTrimmedString[] | null;
  title_gt?: GraphQL$NonEmptyTrimmedString | null;
  title_gte?: GraphQL$NonEmptyTrimmedString | null;
  title_lt?: GraphQL$NonEmptyTrimmedString | null;
  title_lte?: GraphQL$NonEmptyTrimmedString | null;
  title_contains?: GraphQL$NonEmptyString | null;
  title_not_contains?: GraphQL$NonEmptyString | null;
  title_starts_with?: GraphQL$NonEmptyString | null;
  title_not_starts_with?: GraphQL$NonEmptyString | null;
  title_ends_with?: GraphQL$NonEmptyString | null;
  title_not_ends_with?: GraphQL$NonEmptyString | null;
  people_every?: PersonCountryFilterInput | null;
  people_some?: PersonCountryFilterInput | null;
  people_none?: PersonCountryFilterInput | null;
  personCount?: GraphQL$UnsignedInt | null;
  personCount_not?: GraphQL$UnsignedInt | null;
  personCount_gt?: GraphQL$UnsignedInt | null;
  personCount_gte?: GraphQL$UnsignedInt | null;
  personCount_lt?: GraphQL$UnsignedInt | null;
  personCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (CountryFilterInput | null)[] | null;
  OR?: (CountryFilterInput | null)[] | null;
  NOT?: CountryFilterInput | null;
}

/**
 * Identifies exactly one "Country" with one of the following combination of components' value:
 * - id
 * - title
 */
export interface CountryUniqueFilterInput {
  id?: GraphQL$UUIDv4 | null;
  title?: GraphQL$NonEmptyTrimmedString | null;
}

/**
 * The "Country" node's update
 */
export interface CountryUpdateInput {
  title?: GraphQL$NonEmptyTrimmedString | null;
  people?: CountryUpdatePeopleInput | null;
}

export interface CountryUpdatePeopleCreateIfNotExistsInput {
  where: PersonCountryUniqueFilterWithoutCountryInput;
  data: PersonCountryCreationWithoutCountryInput;
}

export interface CountryUpdatePeopleInput {
  deleteAll?: boolean | null;
  deleteMany?: PersonCountryFilterInput | null;
  delete?: PersonCountryUniqueFilterWithoutCountryInput[] | null;
  deleteIfExists?: PersonCountryUniqueFilterWithoutCountryInput[] | null;
  create?: PersonCountryCreationWithoutCountryInput[] | null;
  createIfNotExists?: CountryUpdatePeopleCreateIfNotExistsInput[] | null;
}

/**
 * The "Degree" node's creation
 */
export interface DegreeCreationInput {
  id?: GraphQL$UUIDv4 | null;
  title: GraphQL$NonEmptyTrimmedString;
  people?: DegreeCreationPeopleInput | null;
}

export interface DegreeCreationPeopleInput {
  create?: PersonDegreeCreationWithoutDegreeInput[] | null;
}

/**
 * The "Degree" nodes' filter
 */
export interface DegreeFilterInput {
  id?: GraphQL$UUIDv4 | null;
  id_not?: GraphQL$UUIDv4 | null;
  id_in?: GraphQL$UUIDv4[] | null;
  id_not_in?: GraphQL$UUIDv4[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  title?: GraphQL$NonEmptyTrimmedString | null;
  title_not?: GraphQL$NonEmptyTrimmedString | null;
  title_in?: GraphQL$NonEmptyTrimmedString[] | null;
  title_not_in?: GraphQL$NonEmptyTrimmedString[] | null;
  title_gt?: GraphQL$NonEmptyTrimmedString | null;
  title_gte?: GraphQL$NonEmptyTrimmedString | null;
  title_lt?: GraphQL$NonEmptyTrimmedString | null;
  title_lte?: GraphQL$NonEmptyTrimmedString | null;
  title_contains?: GraphQL$NonEmptyString | null;
  title_not_contains?: GraphQL$NonEmptyString | null;
  title_starts_with?: GraphQL$NonEmptyString | null;
  title_not_starts_with?: GraphQL$NonEmptyString | null;
  title_ends_with?: GraphQL$NonEmptyString | null;
  title_not_ends_with?: GraphQL$NonEmptyString | null;
  people_every?: PersonDegreeFilterInput | null;
  people_some?: PersonDegreeFilterInput | null;
  people_none?: PersonDegreeFilterInput | null;
  personCount?: GraphQL$UnsignedInt | null;
  personCount_not?: GraphQL$UnsignedInt | null;
  personCount_gt?: GraphQL$UnsignedInt | null;
  personCount_gte?: GraphQL$UnsignedInt | null;
  personCount_lt?: GraphQL$UnsignedInt | null;
  personCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (DegreeFilterInput | null)[] | null;
  OR?: (DegreeFilterInput | null)[] | null;
  NOT?: DegreeFilterInput | null;
}

/**
 * Identifies exactly one "Degree" with one of the following combination of components' value:
 * - id
 * - title
 */
export interface DegreeUniqueFilterInput {
  id?: GraphQL$UUIDv4 | null;
  title?: GraphQL$NonEmptyTrimmedString | null;
}

/**
 * The "Job" node's creation
 */
export interface JobCreationInput {
  id?: GraphQL$UUIDv4 | null;
  title: GraphQL$NonEmptyTrimmedString;
  people?: JobCreationPeopleInput | null;
}

export interface JobCreationPeopleInput {
  create?: PersonJobCreationWithoutJobInput[] | null;
}

/**
 * The "Job" nodes' filter
 */
export interface JobFilterInput {
  id?: GraphQL$UUIDv4 | null;
  id_not?: GraphQL$UUIDv4 | null;
  id_in?: GraphQL$UUIDv4[] | null;
  id_not_in?: GraphQL$UUIDv4[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  title?: GraphQL$NonEmptyTrimmedString | null;
  title_not?: GraphQL$NonEmptyTrimmedString | null;
  title_in?: GraphQL$NonEmptyTrimmedString[] | null;
  title_not_in?: GraphQL$NonEmptyTrimmedString[] | null;
  title_gt?: GraphQL$NonEmptyTrimmedString | null;
  title_gte?: GraphQL$NonEmptyTrimmedString | null;
  title_lt?: GraphQL$NonEmptyTrimmedString | null;
  title_lte?: GraphQL$NonEmptyTrimmedString | null;
  title_contains?: GraphQL$NonEmptyString | null;
  title_not_contains?: GraphQL$NonEmptyString | null;
  title_starts_with?: GraphQL$NonEmptyString | null;
  title_not_starts_with?: GraphQL$NonEmptyString | null;
  title_ends_with?: GraphQL$NonEmptyString | null;
  title_not_ends_with?: GraphQL$NonEmptyString | null;
  people_every?: PersonJobFilterInput | null;
  people_some?: PersonJobFilterInput | null;
  people_none?: PersonJobFilterInput | null;
  personCount?: GraphQL$UnsignedInt | null;
  personCount_not?: GraphQL$UnsignedInt | null;
  personCount_gt?: GraphQL$UnsignedInt | null;
  personCount_gte?: GraphQL$UnsignedInt | null;
  personCount_lt?: GraphQL$UnsignedInt | null;
  personCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (JobFilterInput | null)[] | null;
  OR?: (JobFilterInput | null)[] | null;
  NOT?: JobFilterInput | null;
}

/**
 * Identifies exactly one "Job" with one of the following combination of components' value:
 * - id
 * - title
 */
export interface JobUniqueFilterInput {
  id?: GraphQL$UUIDv4 | null;
  title?: GraphQL$NonEmptyTrimmedString | null;
}

/**
 * The "Job" node's update
 */
export interface JobUpdateInput {
  title?: GraphQL$NonEmptyTrimmedString | null;
  people?: JobUpdatePeopleInput | null;
}

export interface JobUpdatePeopleCreateIfNotExistsInput {
  where: PersonJobUniqueFilterWithoutJobInput;
  data: PersonJobCreationWithoutJobInput;
}

export interface JobUpdatePeopleInput {
  deleteAll?: boolean | null;
  deleteMany?: PersonJobFilterInput | null;
  delete?: PersonJobUniqueFilterWithoutJobInput[] | null;
  deleteIfExists?: PersonJobUniqueFilterWithoutJobInput[] | null;
  create?: PersonJobCreationWithoutJobInput[] | null;
  createIfNotExists?: JobUpdatePeopleCreateIfNotExistsInput[] | null;
}

/**
 * The "Passion" node's creation
 */
export interface PassionCreationInput {
  id?: GraphQL$UUIDv4 | null;
  title: GraphQL$NonEmptyTrimmedString;
  people?: PassionCreationPeopleInput | null;
}

export interface PassionCreationPeopleInput {
  create?: PersonPassionCreationWithoutPassionInput[] | null;
}

/**
 * The "Passion" nodes' filter
 */
export interface PassionFilterInput {
  id?: GraphQL$UUIDv4 | null;
  id_not?: GraphQL$UUIDv4 | null;
  id_in?: GraphQL$UUIDv4[] | null;
  id_not_in?: GraphQL$UUIDv4[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  title?: GraphQL$NonEmptyTrimmedString | null;
  title_not?: GraphQL$NonEmptyTrimmedString | null;
  title_in?: GraphQL$NonEmptyTrimmedString[] | null;
  title_not_in?: GraphQL$NonEmptyTrimmedString[] | null;
  title_gt?: GraphQL$NonEmptyTrimmedString | null;
  title_gte?: GraphQL$NonEmptyTrimmedString | null;
  title_lt?: GraphQL$NonEmptyTrimmedString | null;
  title_lte?: GraphQL$NonEmptyTrimmedString | null;
  title_contains?: GraphQL$NonEmptyString | null;
  title_not_contains?: GraphQL$NonEmptyString | null;
  title_starts_with?: GraphQL$NonEmptyString | null;
  title_not_starts_with?: GraphQL$NonEmptyString | null;
  title_ends_with?: GraphQL$NonEmptyString | null;
  title_not_ends_with?: GraphQL$NonEmptyString | null;
  people_every?: PersonPassionFilterInput | null;
  people_some?: PersonPassionFilterInput | null;
  people_none?: PersonPassionFilterInput | null;
  personCount?: GraphQL$UnsignedInt | null;
  personCount_not?: GraphQL$UnsignedInt | null;
  personCount_gt?: GraphQL$UnsignedInt | null;
  personCount_gte?: GraphQL$UnsignedInt | null;
  personCount_lt?: GraphQL$UnsignedInt | null;
  personCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (PassionFilterInput | null)[] | null;
  OR?: (PassionFilterInput | null)[] | null;
  NOT?: PassionFilterInput | null;
}

/**
 * Identifies exactly one "Passion" with one of the following combination of components' value:
 * - id
 * - title
 */
export interface PassionUniqueFilterInput {
  id?: GraphQL$UUIDv4 | null;
  title?: GraphQL$NonEmptyTrimmedString | null;
}

/**
 * The "PersonBiography" node's creation
 */
export interface PersonBiographyCreationWithoutPersonInput {
  id?: GraphQL$UUIDv4 | null;
  editedAt?: GraphQL$DateTime | null;
  brandKey: BrandKey;
  status: PersonBiographyStatus;
  body?: GraphQL$NonEmptyTrimmedString | null;
  media?: GraphQL$JSONObject | null;
  url?: GraphQL$URL | null;
}

/**
 * The "PersonBiography" nodes' filter
 */
export interface PersonBiographyFilterInput {
  id?: GraphQL$UUIDv4 | null;
  id_not?: GraphQL$UUIDv4 | null;
  id_in?: GraphQL$UUIDv4[] | null;
  id_not_in?: GraphQL$UUIDv4[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  editedAt?: GraphQL$DateTime | null;
  editedAt_not?: GraphQL$DateTime | null;
  editedAt_is_null?: boolean | null;
  editedAt_in?: (GraphQL$DateTime | null)[] | null;
  editedAt_not_in?: (GraphQL$DateTime | null)[] | null;
  editedAt_gt?: GraphQL$DateTime | null;
  editedAt_gte?: GraphQL$DateTime | null;
  editedAt_lt?: GraphQL$DateTime | null;
  editedAt_lte?: GraphQL$DateTime | null;
  person?: PersonFilterInput | null;
  person_not?: PersonFilterInput | null;
  brandKey?: BrandKey | null;
  brandKey_not?: BrandKey | null;
  brandKey_in?: BrandKey[] | null;
  brandKey_not_in?: BrandKey[] | null;
  status?: PersonBiographyStatus | null;
  status_not?: PersonBiographyStatus | null;
  status_in?: PersonBiographyStatus[] | null;
  status_not_in?: PersonBiographyStatus[] | null;
  body?: GraphQL$NonEmptyTrimmedString | null;
  body_not?: GraphQL$NonEmptyTrimmedString | null;
  body_is_null?: boolean | null;
  body_in?: (GraphQL$NonEmptyTrimmedString | null)[] | null;
  body_not_in?: (GraphQL$NonEmptyTrimmedString | null)[] | null;
  body_contains?: GraphQL$NonEmptyString | null;
  body_not_contains?: GraphQL$NonEmptyString | null;
  body_starts_with?: GraphQL$NonEmptyString | null;
  body_not_starts_with?: GraphQL$NonEmptyString | null;
  body_ends_with?: GraphQL$NonEmptyString | null;
  body_not_ends_with?: GraphQL$NonEmptyString | null;
  media?: GraphQL$JSONObject | null;
  media_not?: GraphQL$JSONObject | null;
  media_is_null?: boolean | null;
  url?: GraphQL$URL | null;
  url_not?: GraphQL$URL | null;
  url_is_null?: boolean | null;
  url_in?: (GraphQL$URL | null)[] | null;
  url_not_in?: (GraphQL$URL | null)[] | null;
  AND?: (PersonBiographyFilterInput | null)[] | null;
  OR?: (PersonBiographyFilterInput | null)[] | null;
  NOT?: PersonBiographyFilterInput | null;
}

/**
 * Given a "person", identifies exactly one "PersonBiography" with one of the following combination of components' value:
 * - brandKey / (person)
 */
export interface PersonBiographyUniqueFilterWithoutPersonInput {
  brandKey: BrandKey;
}

/**
 * The "PersonBiography" node's update
 */
export interface PersonBiographyUpdateWithoutPersonInput {
  editedAt?: GraphQL$DateTime | null;
  status?: PersonBiographyStatus | null;
  body?: GraphQL$NonEmptyTrimmedString | null;
  media?: GraphQL$JSONObject | null;
  url?: GraphQL$URL | null;
}

export interface PersonCountryCreationCountryCreateIfNotExistsInput {
  where: CountryUniqueFilterInput;
  data: CountryCreationInput;
}

export interface PersonCountryCreationCountryInput {
  connect?: CountryUniqueFilterInput | null;
  create?: CountryCreationInput | null;
  createIfNotExists?: PersonCountryCreationCountryCreateIfNotExistsInput | null;
}

export interface PersonCountryCreationPersonCreateIfNotExistsInput {
  where: PersonUniqueFilterInput;
  data: PersonCreationInput;
}

export interface PersonCountryCreationPersonInput {
  connect?: PersonUniqueFilterInput | null;
  create?: PersonCreationInput | null;
  createIfNotExists?: PersonCountryCreationPersonCreateIfNotExistsInput | null;
}

/**
 * The "PersonCountry" node's creation
 */
export interface PersonCountryCreationWithoutCountryInput {
  person: PersonCountryCreationPersonInput;
}

/**
 * The "PersonCountry" node's creation
 */
export interface PersonCountryCreationWithoutPersonInput {
  country: PersonCountryCreationCountryInput;
}

/**
 * The "PersonCountry" nodes' filter
 */
export interface PersonCountryFilterInput {
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  person?: PersonFilterInput | null;
  person_not?: PersonFilterInput | null;
  country?: CountryFilterInput | null;
  country_not?: CountryFilterInput | null;
  AND?: (PersonCountryFilterInput | null)[] | null;
  OR?: (PersonCountryFilterInput | null)[] | null;
  NOT?: PersonCountryFilterInput | null;
}

/**
 * Given a "country", identifies exactly one "PersonCountry" with one of the following combination of components' value:
 * - person / (country)
 */
export interface PersonCountryUniqueFilterWithoutCountryInput {
  person: PersonUniqueFilterInput;
}

/**
 * Given a "person", identifies exactly one "PersonCountry" with one of the following combination of components' value:
 * - (person) / country
 */
export interface PersonCountryUniqueFilterWithoutPersonInput {
  country: CountryUniqueFilterInput;
}

export interface PersonCreationBiographiesInput {
  create?: PersonBiographyCreationWithoutPersonInput[] | null;
}

export interface PersonCreationCountriesInput {
  create?: PersonCountryCreationWithoutPersonInput[] | null;
}

export interface PersonCreationDegreesInput {
  create?: PersonDegreeCreationWithoutPersonInput[] | null;
}

export interface PersonCreationHistoriesInput {
  create?: PersonHistoryCreationWithoutPersonInput[] | null;
}

/**
 * The "Person" node's creation
 */
export interface PersonCreationInput {
  id?: GraphQL$UUIDv4 | null;
  editedAt?: GraphQL$DateTime | null;
  prismamediaId?: GraphQL$UnsignedInt | null;
  status: PersonStatus;
  firstName: GraphQL$NonEmptyTrimmedString;
  lastName?: GraphQL$NonEmptyTrimmedString | null;
  realFirstName?: GraphQL$NonEmptyTrimmedString | null;
  realLastName?: GraphQL$NonEmptyTrimmedString | null;
  sex?: GraphQL$NonEmptyTrimmedString | null;
  birth?: GraphQL$Date | null;
  death?: GraphQL$Date | null;
  residence?: GraphQL$NonEmptyTrimmedString | null;
  website?: GraphQL$URL | null;
  facebook?: GraphQL$URL | null;
  instagram?: GraphQL$URL | null;
  snapchat?: GraphQL$URL | null;
  tiktok?: GraphQL$URL | null;
  twitch?: GraphQL$URL | null;
  twitter?: GraphQL$URL | null;
  youtube?: GraphQL$URL | null;
  lockerId?: GraphQL$UUIDv4 | null;
  biographies?: PersonCreationBiographiesInput | null;
  countries?: PersonCreationCountriesInput | null;
  degrees?: PersonCreationDegreesInput | null;
  jobs?: PersonCreationJobsInput | null;
  nominations?: PersonCreationNominationsInput | null;
  passions?: PersonCreationPassionsInput | null;
  relationshipTo?: PersonCreationRelationshipToInput | null;
  relationshipFrom?: PersonCreationRelationshipFromInput | null;
  schools?: PersonCreationSchoolsInput | null;
  titles?: PersonCreationTitlesInput | null;
  histories?: PersonCreationHistoriesInput | null;
}

export interface PersonCreationJobsInput {
  create?: PersonJobCreationWithoutPersonInput[] | null;
}

export interface PersonCreationNominationsInput {
  create?: PersonNominationCreationWithoutPersonInput[] | null;
}

export interface PersonCreationPassionsInput {
  create?: PersonPassionCreationWithoutPersonInput[] | null;
}

export interface PersonCreationRelationshipFromInput {
  create?: PersonRelationshipCreationWithoutPersonToInput[] | null;
}

export interface PersonCreationRelationshipToInput {
  create?: PersonRelationshipCreationWithoutPersonFromInput[] | null;
}

export interface PersonCreationSchoolsInput {
  create?: PersonStudyCreationWithoutPersonInput[] | null;
}

export interface PersonCreationTitlesInput {
  create?: PersonTitleCreationWithoutPersonInput[] | null;
}

export interface PersonDegreeCreationDegreeCreateIfNotExistsInput {
  where: DegreeUniqueFilterInput;
  data: DegreeCreationInput;
}

export interface PersonDegreeCreationDegreeInput {
  connect?: DegreeUniqueFilterInput | null;
  create?: DegreeCreationInput | null;
  createIfNotExists?: PersonDegreeCreationDegreeCreateIfNotExistsInput | null;
}

export interface PersonDegreeCreationPersonCreateIfNotExistsInput {
  where: PersonUniqueFilterInput;
  data: PersonCreationInput;
}

export interface PersonDegreeCreationPersonInput {
  connect?: PersonUniqueFilterInput | null;
  create?: PersonCreationInput | null;
  createIfNotExists?: PersonDegreeCreationPersonCreateIfNotExistsInput | null;
}

/**
 * The "PersonDegree" node's creation
 */
export interface PersonDegreeCreationWithoutDegreeInput {
  person: PersonDegreeCreationPersonInput;
}

/**
 * The "PersonDegree" node's creation
 */
export interface PersonDegreeCreationWithoutPersonInput {
  degree: PersonDegreeCreationDegreeInput;
}

/**
 * The "PersonDegree" nodes' filter
 */
export interface PersonDegreeFilterInput {
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  person?: PersonFilterInput | null;
  person_not?: PersonFilterInput | null;
  degree?: DegreeFilterInput | null;
  degree_not?: DegreeFilterInput | null;
  AND?: (PersonDegreeFilterInput | null)[] | null;
  OR?: (PersonDegreeFilterInput | null)[] | null;
  NOT?: PersonDegreeFilterInput | null;
}

/**
 * Given a "person", identifies exactly one "PersonDegree" with one of the following combination of components' value:
 * - (person) / degree
 */
export interface PersonDegreeUniqueFilterWithoutPersonInput {
  degree: DegreeUniqueFilterInput;
}

/**
 * The "Person" nodes' filter
 */
export interface PersonFilterInput {
  id?: GraphQL$UUIDv4 | null;
  id_not?: GraphQL$UUIDv4 | null;
  id_in?: GraphQL$UUIDv4[] | null;
  id_not_in?: GraphQL$UUIDv4[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  publishedAt?: GraphQL$DateTime | null;
  publishedAt_not?: GraphQL$DateTime | null;
  publishedAt_is_null?: boolean | null;
  publishedAt_in?: (GraphQL$DateTime | null)[] | null;
  publishedAt_not_in?: (GraphQL$DateTime | null)[] | null;
  publishedAt_gt?: GraphQL$DateTime | null;
  publishedAt_gte?: GraphQL$DateTime | null;
  publishedAt_lt?: GraphQL$DateTime | null;
  publishedAt_lte?: GraphQL$DateTime | null;
  editedAt?: GraphQL$DateTime | null;
  editedAt_not?: GraphQL$DateTime | null;
  editedAt_is_null?: boolean | null;
  editedAt_in?: (GraphQL$DateTime | null)[] | null;
  editedAt_not_in?: (GraphQL$DateTime | null)[] | null;
  editedAt_gt?: GraphQL$DateTime | null;
  editedAt_gte?: GraphQL$DateTime | null;
  editedAt_lt?: GraphQL$DateTime | null;
  editedAt_lte?: GraphQL$DateTime | null;
  prismamediaId?: GraphQL$UnsignedInt | null;
  prismamediaId_not?: GraphQL$UnsignedInt | null;
  prismamediaId_in?: GraphQL$UnsignedInt[] | null;
  prismamediaId_not_in?: GraphQL$UnsignedInt[] | null;
  prismamediaId_gt?: GraphQL$UnsignedInt | null;
  prismamediaId_gte?: GraphQL$UnsignedInt | null;
  prismamediaId_lt?: GraphQL$UnsignedInt | null;
  prismamediaId_lte?: GraphQL$UnsignedInt | null;
  status?: PersonStatus | null;
  status_not?: PersonStatus | null;
  status_in?: PersonStatus[] | null;
  status_not_in?: PersonStatus[] | null;
  firstName?: GraphQL$NonEmptyTrimmedString | null;
  firstName_not?: GraphQL$NonEmptyTrimmedString | null;
  firstName_in?: GraphQL$NonEmptyTrimmedString[] | null;
  firstName_not_in?: GraphQL$NonEmptyTrimmedString[] | null;
  firstName_contains?: GraphQL$NonEmptyString | null;
  firstName_not_contains?: GraphQL$NonEmptyString | null;
  firstName_starts_with?: GraphQL$NonEmptyString | null;
  firstName_not_starts_with?: GraphQL$NonEmptyString | null;
  firstName_ends_with?: GraphQL$NonEmptyString | null;
  firstName_not_ends_with?: GraphQL$NonEmptyString | null;
  lastName?: GraphQL$NonEmptyTrimmedString | null;
  lastName_not?: GraphQL$NonEmptyTrimmedString | null;
  lastName_is_null?: boolean | null;
  lastName_in?: (GraphQL$NonEmptyTrimmedString | null)[] | null;
  lastName_not_in?: (GraphQL$NonEmptyTrimmedString | null)[] | null;
  lastName_contains?: GraphQL$NonEmptyString | null;
  lastName_not_contains?: GraphQL$NonEmptyString | null;
  lastName_starts_with?: GraphQL$NonEmptyString | null;
  lastName_not_starts_with?: GraphQL$NonEmptyString | null;
  lastName_ends_with?: GraphQL$NonEmptyString | null;
  lastName_not_ends_with?: GraphQL$NonEmptyString | null;
  fullName?: GraphQL$NonEmptyTrimmedString | null;
  fullName_not?: GraphQL$NonEmptyTrimmedString | null;
  fullName_in?: GraphQL$NonEmptyTrimmedString[] | null;
  fullName_not_in?: GraphQL$NonEmptyTrimmedString[] | null;
  fullName_gt?: GraphQL$NonEmptyTrimmedString | null;
  fullName_gte?: GraphQL$NonEmptyTrimmedString | null;
  fullName_lt?: GraphQL$NonEmptyTrimmedString | null;
  fullName_lte?: GraphQL$NonEmptyTrimmedString | null;
  fullName_contains?: GraphQL$NonEmptyString | null;
  fullName_not_contains?: GraphQL$NonEmptyString | null;
  fullName_starts_with?: GraphQL$NonEmptyString | null;
  fullName_not_starts_with?: GraphQL$NonEmptyString | null;
  fullName_ends_with?: GraphQL$NonEmptyString | null;
  fullName_not_ends_with?: GraphQL$NonEmptyString | null;
  slug?: GraphQL$NonEmptyTrimmedString | null;
  slug_not?: GraphQL$NonEmptyTrimmedString | null;
  slug_in?: GraphQL$NonEmptyTrimmedString[] | null;
  slug_not_in?: GraphQL$NonEmptyTrimmedString[] | null;
  slug_contains?: GraphQL$NonEmptyString | null;
  slug_not_contains?: GraphQL$NonEmptyString | null;
  slug_starts_with?: GraphQL$NonEmptyString | null;
  slug_not_starts_with?: GraphQL$NonEmptyString | null;
  slug_ends_with?: GraphQL$NonEmptyString | null;
  slug_not_ends_with?: GraphQL$NonEmptyString | null;
  realFirstName?: GraphQL$NonEmptyTrimmedString | null;
  realFirstName_not?: GraphQL$NonEmptyTrimmedString | null;
  realFirstName_is_null?: boolean | null;
  realFirstName_in?: (GraphQL$NonEmptyTrimmedString | null)[] | null;
  realFirstName_not_in?: (GraphQL$NonEmptyTrimmedString | null)[] | null;
  realFirstName_contains?: GraphQL$NonEmptyString | null;
  realFirstName_not_contains?: GraphQL$NonEmptyString | null;
  realFirstName_starts_with?: GraphQL$NonEmptyString | null;
  realFirstName_not_starts_with?: GraphQL$NonEmptyString | null;
  realFirstName_ends_with?: GraphQL$NonEmptyString | null;
  realFirstName_not_ends_with?: GraphQL$NonEmptyString | null;
  realLastName?: GraphQL$NonEmptyTrimmedString | null;
  realLastName_not?: GraphQL$NonEmptyTrimmedString | null;
  realLastName_is_null?: boolean | null;
  realLastName_in?: (GraphQL$NonEmptyTrimmedString | null)[] | null;
  realLastName_not_in?: (GraphQL$NonEmptyTrimmedString | null)[] | null;
  realLastName_contains?: GraphQL$NonEmptyString | null;
  realLastName_not_contains?: GraphQL$NonEmptyString | null;
  realLastName_starts_with?: GraphQL$NonEmptyString | null;
  realLastName_not_starts_with?: GraphQL$NonEmptyString | null;
  realLastName_ends_with?: GraphQL$NonEmptyString | null;
  realLastName_not_ends_with?: GraphQL$NonEmptyString | null;
  sex?: GraphQL$NonEmptyTrimmedString | null;
  sex_not?: GraphQL$NonEmptyTrimmedString | null;
  sex_is_null?: boolean | null;
  sex_in?: (GraphQL$NonEmptyTrimmedString | null)[] | null;
  sex_not_in?: (GraphQL$NonEmptyTrimmedString | null)[] | null;
  sex_contains?: GraphQL$NonEmptyString | null;
  sex_not_contains?: GraphQL$NonEmptyString | null;
  sex_starts_with?: GraphQL$NonEmptyString | null;
  sex_not_starts_with?: GraphQL$NonEmptyString | null;
  sex_ends_with?: GraphQL$NonEmptyString | null;
  sex_not_ends_with?: GraphQL$NonEmptyString | null;
  birth?: GraphQL$Date | null;
  birth_not?: GraphQL$Date | null;
  birth_is_null?: boolean | null;
  birth_in?: (GraphQL$Date | null)[] | null;
  birth_not_in?: (GraphQL$Date | null)[] | null;
  birth_gt?: GraphQL$Date | null;
  birth_gte?: GraphQL$Date | null;
  birth_lt?: GraphQL$Date | null;
  birth_lte?: GraphQL$Date | null;
  death?: GraphQL$Date | null;
  death_not?: GraphQL$Date | null;
  death_is_null?: boolean | null;
  death_in?: (GraphQL$Date | null)[] | null;
  death_not_in?: (GraphQL$Date | null)[] | null;
  death_gt?: GraphQL$Date | null;
  death_gte?: GraphQL$Date | null;
  death_lt?: GraphQL$Date | null;
  death_lte?: GraphQL$Date | null;
  astrologicalSign?: AstrologicalSign | null;
  astrologicalSign_not?: AstrologicalSign | null;
  astrologicalSign_is_null?: boolean | null;
  astrologicalSign_in?: (AstrologicalSign | null)[] | null;
  astrologicalSign_not_in?: (AstrologicalSign | null)[] | null;
  residence?: GraphQL$NonEmptyTrimmedString | null;
  residence_not?: GraphQL$NonEmptyTrimmedString | null;
  residence_is_null?: boolean | null;
  residence_in?: (GraphQL$NonEmptyTrimmedString | null)[] | null;
  residence_not_in?: (GraphQL$NonEmptyTrimmedString | null)[] | null;
  residence_contains?: GraphQL$NonEmptyString | null;
  residence_not_contains?: GraphQL$NonEmptyString | null;
  residence_starts_with?: GraphQL$NonEmptyString | null;
  residence_not_starts_with?: GraphQL$NonEmptyString | null;
  residence_ends_with?: GraphQL$NonEmptyString | null;
  residence_not_ends_with?: GraphQL$NonEmptyString | null;
  website?: GraphQL$URL | null;
  website_not?: GraphQL$URL | null;
  website_is_null?: boolean | null;
  website_in?: (GraphQL$URL | null)[] | null;
  website_not_in?: (GraphQL$URL | null)[] | null;
  facebook?: GraphQL$URL | null;
  facebook_not?: GraphQL$URL | null;
  facebook_is_null?: boolean | null;
  facebook_in?: (GraphQL$URL | null)[] | null;
  facebook_not_in?: (GraphQL$URL | null)[] | null;
  instagram?: GraphQL$URL | null;
  instagram_not?: GraphQL$URL | null;
  instagram_is_null?: boolean | null;
  instagram_in?: (GraphQL$URL | null)[] | null;
  instagram_not_in?: (GraphQL$URL | null)[] | null;
  snapchat?: GraphQL$URL | null;
  snapchat_not?: GraphQL$URL | null;
  snapchat_is_null?: boolean | null;
  snapchat_in?: (GraphQL$URL | null)[] | null;
  snapchat_not_in?: (GraphQL$URL | null)[] | null;
  tiktok?: GraphQL$URL | null;
  tiktok_not?: GraphQL$URL | null;
  tiktok_is_null?: boolean | null;
  tiktok_in?: (GraphQL$URL | null)[] | null;
  tiktok_not_in?: (GraphQL$URL | null)[] | null;
  twitch?: GraphQL$URL | null;
  twitch_not?: GraphQL$URL | null;
  twitch_is_null?: boolean | null;
  twitch_in?: (GraphQL$URL | null)[] | null;
  twitch_not_in?: (GraphQL$URL | null)[] | null;
  twitter?: GraphQL$URL | null;
  twitter_not?: GraphQL$URL | null;
  twitter_is_null?: boolean | null;
  twitter_in?: (GraphQL$URL | null)[] | null;
  twitter_not_in?: (GraphQL$URL | null)[] | null;
  youtube?: GraphQL$URL | null;
  youtube_not?: GraphQL$URL | null;
  youtube_is_null?: boolean | null;
  youtube_in?: (GraphQL$URL | null)[] | null;
  youtube_not_in?: (GraphQL$URL | null)[] | null;
  lockerId?: GraphQL$UUIDv4 | null;
  lockerId_not?: GraphQL$UUIDv4 | null;
  lockerId_is_null?: boolean | null;
  lockerId_in?: (GraphQL$UUIDv4 | null)[] | null;
  lockerId_not_in?: (GraphQL$UUIDv4 | null)[] | null;
  lockerId_gt?: GraphQL$UUIDv4 | null;
  lockerId_gte?: GraphQL$UUIDv4 | null;
  lockerId_lt?: GraphQL$UUIDv4 | null;
  lockerId_lte?: GraphQL$UUIDv4 | null;
  lockedAt?: GraphQL$DateTime | null;
  lockedAt_not?: GraphQL$DateTime | null;
  lockedAt_is_null?: boolean | null;
  lockedAt_in?: (GraphQL$DateTime | null)[] | null;
  lockedAt_not_in?: (GraphQL$DateTime | null)[] | null;
  lockedAt_gt?: GraphQL$DateTime | null;
  lockedAt_gte?: GraphQL$DateTime | null;
  lockedAt_lt?: GraphQL$DateTime | null;
  lockedAt_lte?: GraphQL$DateTime | null;
  biographies_every?: PersonBiographyFilterInput | null;
  biographies_some?: PersonBiographyFilterInput | null;
  biographies_none?: PersonBiographyFilterInput | null;
  biographyCount?: GraphQL$UnsignedInt | null;
  biographyCount_not?: GraphQL$UnsignedInt | null;
  biographyCount_gt?: GraphQL$UnsignedInt | null;
  biographyCount_gte?: GraphQL$UnsignedInt | null;
  biographyCount_lt?: GraphQL$UnsignedInt | null;
  biographyCount_lte?: GraphQL$UnsignedInt | null;
  countries_every?: PersonCountryFilterInput | null;
  countries_some?: PersonCountryFilterInput | null;
  countries_none?: PersonCountryFilterInput | null;
  countryCount?: GraphQL$UnsignedInt | null;
  countryCount_not?: GraphQL$UnsignedInt | null;
  countryCount_gt?: GraphQL$UnsignedInt | null;
  countryCount_gte?: GraphQL$UnsignedInt | null;
  countryCount_lt?: GraphQL$UnsignedInt | null;
  countryCount_lte?: GraphQL$UnsignedInt | null;
  degrees_every?: PersonDegreeFilterInput | null;
  degrees_some?: PersonDegreeFilterInput | null;
  degrees_none?: PersonDegreeFilterInput | null;
  degreeCount?: GraphQL$UnsignedInt | null;
  degreeCount_not?: GraphQL$UnsignedInt | null;
  degreeCount_gt?: GraphQL$UnsignedInt | null;
  degreeCount_gte?: GraphQL$UnsignedInt | null;
  degreeCount_lt?: GraphQL$UnsignedInt | null;
  degreeCount_lte?: GraphQL$UnsignedInt | null;
  jobs_every?: PersonJobFilterInput | null;
  jobs_some?: PersonJobFilterInput | null;
  jobs_none?: PersonJobFilterInput | null;
  jobCount?: GraphQL$UnsignedInt | null;
  jobCount_not?: GraphQL$UnsignedInt | null;
  jobCount_gt?: GraphQL$UnsignedInt | null;
  jobCount_gte?: GraphQL$UnsignedInt | null;
  jobCount_lt?: GraphQL$UnsignedInt | null;
  jobCount_lte?: GraphQL$UnsignedInt | null;
  nominations_every?: PersonNominationFilterInput | null;
  nominations_some?: PersonNominationFilterInput | null;
  nominations_none?: PersonNominationFilterInput | null;
  nominationCount?: GraphQL$UnsignedInt | null;
  nominationCount_not?: GraphQL$UnsignedInt | null;
  nominationCount_gt?: GraphQL$UnsignedInt | null;
  nominationCount_gte?: GraphQL$UnsignedInt | null;
  nominationCount_lt?: GraphQL$UnsignedInt | null;
  nominationCount_lte?: GraphQL$UnsignedInt | null;
  passions_every?: PersonPassionFilterInput | null;
  passions_some?: PersonPassionFilterInput | null;
  passions_none?: PersonPassionFilterInput | null;
  passionCount?: GraphQL$UnsignedInt | null;
  passionCount_not?: GraphQL$UnsignedInt | null;
  passionCount_gt?: GraphQL$UnsignedInt | null;
  passionCount_gte?: GraphQL$UnsignedInt | null;
  passionCount_lt?: GraphQL$UnsignedInt | null;
  passionCount_lte?: GraphQL$UnsignedInt | null;
  relationshipTo_every?: PersonRelationshipFilterInput | null;
  relationshipTo_some?: PersonRelationshipFilterInput | null;
  relationshipTo_none?: PersonRelationshipFilterInput | null;
  relationshipToCount?: GraphQL$UnsignedInt | null;
  relationshipToCount_not?: GraphQL$UnsignedInt | null;
  relationshipToCount_gt?: GraphQL$UnsignedInt | null;
  relationshipToCount_gte?: GraphQL$UnsignedInt | null;
  relationshipToCount_lt?: GraphQL$UnsignedInt | null;
  relationshipToCount_lte?: GraphQL$UnsignedInt | null;
  relationshipFrom_every?: PersonRelationshipFilterInput | null;
  relationshipFrom_some?: PersonRelationshipFilterInput | null;
  relationshipFrom_none?: PersonRelationshipFilterInput | null;
  relationshipFromCount?: GraphQL$UnsignedInt | null;
  relationshipFromCount_not?: GraphQL$UnsignedInt | null;
  relationshipFromCount_gt?: GraphQL$UnsignedInt | null;
  relationshipFromCount_gte?: GraphQL$UnsignedInt | null;
  relationshipFromCount_lt?: GraphQL$UnsignedInt | null;
  relationshipFromCount_lte?: GraphQL$UnsignedInt | null;
  schools_every?: PersonStudyFilterInput | null;
  schools_some?: PersonStudyFilterInput | null;
  schools_none?: PersonStudyFilterInput | null;
  schoolCount?: GraphQL$UnsignedInt | null;
  schoolCount_not?: GraphQL$UnsignedInt | null;
  schoolCount_gt?: GraphQL$UnsignedInt | null;
  schoolCount_gte?: GraphQL$UnsignedInt | null;
  schoolCount_lt?: GraphQL$UnsignedInt | null;
  schoolCount_lte?: GraphQL$UnsignedInt | null;
  titles_every?: PersonTitleFilterInput | null;
  titles_some?: PersonTitleFilterInput | null;
  titles_none?: PersonTitleFilterInput | null;
  titleCount?: GraphQL$UnsignedInt | null;
  titleCount_not?: GraphQL$UnsignedInt | null;
  titleCount_gt?: GraphQL$UnsignedInt | null;
  titleCount_gte?: GraphQL$UnsignedInt | null;
  titleCount_lt?: GraphQL$UnsignedInt | null;
  titleCount_lte?: GraphQL$UnsignedInt | null;
  histories_every?: PersonHistoryFilterInput | null;
  histories_some?: PersonHistoryFilterInput | null;
  histories_none?: PersonHistoryFilterInput | null;
  historyCount?: GraphQL$UnsignedInt | null;
  historyCount_not?: GraphQL$UnsignedInt | null;
  historyCount_gt?: GraphQL$UnsignedInt | null;
  historyCount_gte?: GraphQL$UnsignedInt | null;
  historyCount_lt?: GraphQL$UnsignedInt | null;
  historyCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (PersonFilterInput | null)[] | null;
  OR?: (PersonFilterInput | null)[] | null;
  NOT?: PersonFilterInput | null;
}

/**
 * The "PersonHistory" node's creation
 */
export interface PersonHistoryCreationWithoutPersonInput {
  id?: GraphQL$UUIDv4 | null;
  fullName: GraphQL$NonEmptyTrimmedString;
  status: PersonStatus;
  statusBioByBrandKey?: GraphQL$JSONObject | null;
}

/**
 * The "PersonHistory" nodes' filter
 */
export interface PersonHistoryFilterInput {
  id?: GraphQL$UUIDv4 | null;
  id_not?: GraphQL$UUIDv4 | null;
  id_in?: GraphQL$UUIDv4[] | null;
  id_not_in?: GraphQL$UUIDv4[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  person?: PersonFilterInput | null;
  person_not?: PersonFilterInput | null;
  userId?: GraphQL$UUIDv4 | null;
  userId_not?: GraphQL$UUIDv4 | null;
  userId_in?: GraphQL$UUIDv4[] | null;
  userId_not_in?: GraphQL$UUIDv4[] | null;
  fullName?: GraphQL$NonEmptyTrimmedString | null;
  fullName_not?: GraphQL$NonEmptyTrimmedString | null;
  fullName_in?: GraphQL$NonEmptyTrimmedString[] | null;
  fullName_not_in?: GraphQL$NonEmptyTrimmedString[] | null;
  fullName_contains?: GraphQL$NonEmptyString | null;
  fullName_not_contains?: GraphQL$NonEmptyString | null;
  fullName_starts_with?: GraphQL$NonEmptyString | null;
  fullName_not_starts_with?: GraphQL$NonEmptyString | null;
  fullName_ends_with?: GraphQL$NonEmptyString | null;
  fullName_not_ends_with?: GraphQL$NonEmptyString | null;
  status?: PersonStatus | null;
  status_not?: PersonStatus | null;
  status_in?: PersonStatus[] | null;
  status_not_in?: PersonStatus[] | null;
  statusBioByBrandKey?: GraphQL$JSONObject | null;
  statusBioByBrandKey_not?: GraphQL$JSONObject | null;
  statusBioByBrandKey_is_null?: boolean | null;
  AND?: (PersonHistoryFilterInput | null)[] | null;
  OR?: (PersonHistoryFilterInput | null)[] | null;
  NOT?: PersonHistoryFilterInput | null;
}

export interface PersonJobCreationJobCreateIfNotExistsInput {
  where: JobUniqueFilterInput;
  data: JobCreationInput;
}

export interface PersonJobCreationJobInput {
  connect?: JobUniqueFilterInput | null;
  create?: JobCreationInput | null;
  createIfNotExists?: PersonJobCreationJobCreateIfNotExistsInput | null;
}

export interface PersonJobCreationPersonCreateIfNotExistsInput {
  where: PersonUniqueFilterInput;
  data: PersonCreationInput;
}

export interface PersonJobCreationPersonInput {
  connect?: PersonUniqueFilterInput | null;
  create?: PersonCreationInput | null;
  createIfNotExists?: PersonJobCreationPersonCreateIfNotExistsInput | null;
}

/**
 * The "PersonJob" node's creation
 */
export interface PersonJobCreationWithoutJobInput {
  person: PersonJobCreationPersonInput;
}

/**
 * The "PersonJob" node's creation
 */
export interface PersonJobCreationWithoutPersonInput {
  job: PersonJobCreationJobInput;
}

/**
 * The "PersonJob" nodes' filter
 */
export interface PersonJobFilterInput {
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  person?: PersonFilterInput | null;
  person_not?: PersonFilterInput | null;
  job?: JobFilterInput | null;
  job_not?: JobFilterInput | null;
  AND?: (PersonJobFilterInput | null)[] | null;
  OR?: (PersonJobFilterInput | null)[] | null;
  NOT?: PersonJobFilterInput | null;
}

/**
 * Given a "job", identifies exactly one "PersonJob" with one of the following combination of components' value:
 * - person / (job)
 */
export interface PersonJobUniqueFilterWithoutJobInput {
  person: PersonUniqueFilterInput;
}

/**
 * Given a "person", identifies exactly one "PersonJob" with one of the following combination of components' value:
 * - (person) / job
 */
export interface PersonJobUniqueFilterWithoutPersonInput {
  job: JobUniqueFilterInput;
}

export interface PersonNominationCreationAwardCreateIfNotExistsInput {
  where: AwardUniqueFilterInput;
  data: AwardCreationInput;
}

export interface PersonNominationCreationAwardInput {
  connect?: AwardUniqueFilterInput | null;
  create?: AwardCreationInput | null;
  createIfNotExists?: PersonNominationCreationAwardCreateIfNotExistsInput | null;
}

export interface PersonNominationCreationPersonCreateIfNotExistsInput {
  where: PersonUniqueFilterInput;
  data: PersonCreationInput;
}

export interface PersonNominationCreationPersonInput {
  connect?: PersonUniqueFilterInput | null;
  create?: PersonCreationInput | null;
  createIfNotExists?: PersonNominationCreationPersonCreateIfNotExistsInput | null;
}

/**
 * The "PersonNomination" node's creation
 */
export interface PersonNominationCreationWithoutAwardInput {
  id?: GraphQL$UUIDv4 | null;
  person: PersonNominationCreationPersonInput;
  year?: GraphQL$UnsignedInt | null;
  work?: GraphQL$NonEmptyTrimmedString | null;
}

/**
 * The "PersonNomination" node's creation
 */
export interface PersonNominationCreationWithoutPersonInput {
  id?: GraphQL$UUIDv4 | null;
  award: PersonNominationCreationAwardInput;
  year?: GraphQL$UnsignedInt | null;
  work?: GraphQL$NonEmptyTrimmedString | null;
}

/**
 * The "PersonNomination" nodes' filter
 */
export interface PersonNominationFilterInput {
  id?: GraphQL$UUIDv4 | null;
  id_not?: GraphQL$UUIDv4 | null;
  id_in?: GraphQL$UUIDv4[] | null;
  id_not_in?: GraphQL$UUIDv4[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  person?: PersonFilterInput | null;
  person_not?: PersonFilterInput | null;
  award?: AwardFilterInput | null;
  award_not?: AwardFilterInput | null;
  year?: GraphQL$UnsignedInt | null;
  year_not?: GraphQL$UnsignedInt | null;
  year_is_null?: boolean | null;
  year_in?: (GraphQL$UnsignedInt | null)[] | null;
  year_not_in?: (GraphQL$UnsignedInt | null)[] | null;
  year_gt?: GraphQL$UnsignedInt | null;
  year_gte?: GraphQL$UnsignedInt | null;
  year_lt?: GraphQL$UnsignedInt | null;
  year_lte?: GraphQL$UnsignedInt | null;
  work?: GraphQL$NonEmptyTrimmedString | null;
  work_not?: GraphQL$NonEmptyTrimmedString | null;
  work_is_null?: boolean | null;
  work_in?: (GraphQL$NonEmptyTrimmedString | null)[] | null;
  work_not_in?: (GraphQL$NonEmptyTrimmedString | null)[] | null;
  work_contains?: GraphQL$NonEmptyString | null;
  work_not_contains?: GraphQL$NonEmptyString | null;
  work_starts_with?: GraphQL$NonEmptyString | null;
  work_not_starts_with?: GraphQL$NonEmptyString | null;
  work_ends_with?: GraphQL$NonEmptyString | null;
  work_not_ends_with?: GraphQL$NonEmptyString | null;
  AND?: (PersonNominationFilterInput | null)[] | null;
  OR?: (PersonNominationFilterInput | null)[] | null;
  NOT?: PersonNominationFilterInput | null;
}

/**
 * Given an "award", identifies exactly one "PersonNomination" with one of the following combination of components' value:
 * - person / (award) / year
 */
export interface PersonNominationUniqueFilterWithoutAwardInput {
  person: PersonUniqueFilterInput;
  year?: GraphQL$UnsignedInt | null;
}

/**
 * Given a "person", identifies exactly one "PersonNomination" with one of the following combination of components' value:
 * - (person) / award / year
 */
export interface PersonNominationUniqueFilterWithoutPersonInput {
  award: AwardUniqueFilterInput;
  year?: GraphQL$UnsignedInt | null;
}

/**
 * The "PersonNomination" node's update
 */
export interface PersonNominationUpdateWithoutAwardInput {
  year?: GraphQL$UnsignedInt | null;
  work?: GraphQL$NonEmptyTrimmedString | null;
}

/**
 * The "PersonNomination" node's update
 */
export interface PersonNominationUpdateWithoutPersonInput {
  year?: GraphQL$UnsignedInt | null;
  work?: GraphQL$NonEmptyTrimmedString | null;
}

export interface PersonPassionCreationPassionCreateIfNotExistsInput {
  where: PassionUniqueFilterInput;
  data: PassionCreationInput;
}

export interface PersonPassionCreationPassionInput {
  connect?: PassionUniqueFilterInput | null;
  create?: PassionCreationInput | null;
  createIfNotExists?: PersonPassionCreationPassionCreateIfNotExistsInput | null;
}

export interface PersonPassionCreationPersonCreateIfNotExistsInput {
  where: PersonUniqueFilterInput;
  data: PersonCreationInput;
}

export interface PersonPassionCreationPersonInput {
  connect?: PersonUniqueFilterInput | null;
  create?: PersonCreationInput | null;
  createIfNotExists?: PersonPassionCreationPersonCreateIfNotExistsInput | null;
}

/**
 * The "PersonPassion" node's creation
 */
export interface PersonPassionCreationWithoutPassionInput {
  person: PersonPassionCreationPersonInput;
}

/**
 * The "PersonPassion" node's creation
 */
export interface PersonPassionCreationWithoutPersonInput {
  passion: PersonPassionCreationPassionInput;
}

/**
 * The "PersonPassion" nodes' filter
 */
export interface PersonPassionFilterInput {
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  person?: PersonFilterInput | null;
  person_not?: PersonFilterInput | null;
  passion?: PassionFilterInput | null;
  passion_not?: PassionFilterInput | null;
  AND?: (PersonPassionFilterInput | null)[] | null;
  OR?: (PersonPassionFilterInput | null)[] | null;
  NOT?: PersonPassionFilterInput | null;
}

/**
 * Given a "person", identifies exactly one "PersonPassion" with one of the following combination of components' value:
 * - (person) / passion
 */
export interface PersonPassionUniqueFilterWithoutPersonInput {
  passion: PassionUniqueFilterInput;
}

export interface PersonRelationshipCreationPersonFromCreateIfNotExistsInput {
  where: PersonUniqueFilterInput;
  data: PersonCreationInput;
}

export interface PersonRelationshipCreationPersonFromInput {
  connect?: PersonUniqueFilterInput | null;
  create?: PersonCreationInput | null;
  createIfNotExists?: PersonRelationshipCreationPersonFromCreateIfNotExistsInput | null;
}

export interface PersonRelationshipCreationPersonToCreateIfNotExistsInput {
  where: PersonUniqueFilterInput;
  data: PersonCreationInput;
}

export interface PersonRelationshipCreationPersonToInput {
  connect?: PersonUniqueFilterInput | null;
  create?: PersonCreationInput | null;
  createIfNotExists?: PersonRelationshipCreationPersonToCreateIfNotExistsInput | null;
}

export interface PersonRelationshipCreationRelationshipTitleCreateIfNotExistsInput {
  where: RelationshipTitleUniqueFilterInput;
  data: RelationshipTitleCreationInput;
}

export interface PersonRelationshipCreationRelationshipTitleInput {
  connect?: RelationshipTitleUniqueFilterInput | null;
  create?: RelationshipTitleCreationInput | null;
  createIfNotExists?: PersonRelationshipCreationRelationshipTitleCreateIfNotExistsInput | null;
}

/**
 * The "PersonRelationship" node's creation
 */
export interface PersonRelationshipCreationWithoutPersonFromInput {
  relationshipTitle: PersonRelationshipCreationRelationshipTitleInput;
  personTo: PersonRelationshipCreationPersonToInput;
  order?: GraphQL$UnsignedInt | null;
}

/**
 * The "PersonRelationship" node's creation
 */
export interface PersonRelationshipCreationWithoutPersonToInput {
  personFrom: PersonRelationshipCreationPersonFromInput;
  relationshipTitle: PersonRelationshipCreationRelationshipTitleInput;
  order?: GraphQL$UnsignedInt | null;
}

/**
 * The "PersonRelationship" node's creation
 */
export interface PersonRelationshipCreationWithoutRelationshipTitleInput {
  personFrom: PersonRelationshipCreationPersonFromInput;
  personTo: PersonRelationshipCreationPersonToInput;
  order?: GraphQL$UnsignedInt | null;
}

/**
 * The "PersonRelationship" nodes' filter
 */
export interface PersonRelationshipFilterInput {
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  personFrom?: PersonFilterInput | null;
  personFrom_not?: PersonFilterInput | null;
  relationshipTitle?: RelationshipTitleFilterInput | null;
  relationshipTitle_not?: RelationshipTitleFilterInput | null;
  personTo?: PersonFilterInput | null;
  personTo_not?: PersonFilterInput | null;
  order?: GraphQL$UnsignedInt | null;
  order_not?: GraphQL$UnsignedInt | null;
  order_is_null?: boolean | null;
  order_in?: (GraphQL$UnsignedInt | null)[] | null;
  order_not_in?: (GraphQL$UnsignedInt | null)[] | null;
  order_gt?: GraphQL$UnsignedInt | null;
  order_gte?: GraphQL$UnsignedInt | null;
  order_lt?: GraphQL$UnsignedInt | null;
  order_lte?: GraphQL$UnsignedInt | null;
  AND?: (PersonRelationshipFilterInput | null)[] | null;
  OR?: (PersonRelationshipFilterInput | null)[] | null;
  NOT?: PersonRelationshipFilterInput | null;
}

/**
 * Given a "personFrom", identifies exactly one "PersonRelationship" with one of
 * the following combination of components' value:
 * - (personFrom) / personTo
 */
export interface PersonRelationshipUniqueFilterWithoutPersonFromInput {
  personTo: PersonUniqueFilterInput;
}

/**
 * Given a "personTo", identifies exactly one "PersonRelationship" with one of the following combination of components' value:
 * - personFrom / (personTo)
 */
export interface PersonRelationshipUniqueFilterWithoutPersonToInput {
  personFrom: PersonUniqueFilterInput;
}

export interface PersonRelationshipUpdateRelationshipTitleCreateIfNotExistsInput {
  where: RelationshipTitleUniqueFilterInput;
  data: RelationshipTitleCreationInput;
}

export interface PersonRelationshipUpdateRelationshipTitleInput {
  connect?: RelationshipTitleUniqueFilterInput | null;
  create?: RelationshipTitleCreationInput | null;
  createIfNotExists?: PersonRelationshipUpdateRelationshipTitleCreateIfNotExistsInput | null;
  update?: RelationshipTitleUpdateInput | null;
}

/**
 * The "PersonRelationship" node's update
 */
export interface PersonRelationshipUpdateWithoutPersonFromInput {
  relationshipTitle?: PersonRelationshipUpdateRelationshipTitleInput | null;
  order?: GraphQL$UnsignedInt | null;
}

/**
 * The "PersonRelationship" node's update
 */
export interface PersonRelationshipUpdateWithoutPersonToInput {
  relationshipTitle?: PersonRelationshipUpdateRelationshipTitleInput | null;
  order?: GraphQL$UnsignedInt | null;
}

/**
 * The "PersonRelationship" node's update
 */
export interface PersonRelationshipUpdateWithoutRelationshipTitleInput {
  order?: GraphQL$UnsignedInt | null;
}

export interface PersonStudyCreationPersonCreateIfNotExistsInput {
  where: PersonUniqueFilterInput;
  data: PersonCreationInput;
}

export interface PersonStudyCreationPersonInput {
  connect?: PersonUniqueFilterInput | null;
  create?: PersonCreationInput | null;
  createIfNotExists?: PersonStudyCreationPersonCreateIfNotExistsInput | null;
}

export interface PersonStudyCreationSchoolCreateIfNotExistsInput {
  where: SchoolUniqueFilterInput;
  data: SchoolCreationInput;
}

export interface PersonStudyCreationSchoolInput {
  connect?: SchoolUniqueFilterInput | null;
  create?: SchoolCreationInput | null;
  createIfNotExists?: PersonStudyCreationSchoolCreateIfNotExistsInput | null;
}

/**
 * The "PersonStudy" node's creation
 */
export interface PersonStudyCreationWithoutPersonInput {
  school: PersonStudyCreationSchoolInput;
}

/**
 * The "PersonStudy" node's creation
 */
export interface PersonStudyCreationWithoutSchoolInput {
  person: PersonStudyCreationPersonInput;
}

/**
 * The "PersonStudy" nodes' filter
 */
export interface PersonStudyFilterInput {
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  person?: PersonFilterInput | null;
  person_not?: PersonFilterInput | null;
  school?: SchoolFilterInput | null;
  school_not?: SchoolFilterInput | null;
  AND?: (PersonStudyFilterInput | null)[] | null;
  OR?: (PersonStudyFilterInput | null)[] | null;
  NOT?: PersonStudyFilterInput | null;
}

/**
 * Given a "person", identifies exactly one "PersonStudy" with one of the following combination of components' value:
 * - (person) / school
 */
export interface PersonStudyUniqueFilterWithoutPersonInput {
  school: SchoolUniqueFilterInput;
}

export interface PersonTitleCreationPersonCreateIfNotExistsInput {
  where: PersonUniqueFilterInput;
  data: PersonCreationInput;
}

export interface PersonTitleCreationPersonInput {
  connect?: PersonUniqueFilterInput | null;
  create?: PersonCreationInput | null;
  createIfNotExists?: PersonTitleCreationPersonCreateIfNotExistsInput | null;
}

export interface PersonTitleCreationTitleCreateIfNotExistsInput {
  where: TitleUniqueFilterInput;
  data: TitleCreationInput;
}

export interface PersonTitleCreationTitleInput {
  connect?: TitleUniqueFilterInput | null;
  create?: TitleCreationInput | null;
  createIfNotExists?: PersonTitleCreationTitleCreateIfNotExistsInput | null;
}

/**
 * The "PersonTitle" node's creation
 */
export interface PersonTitleCreationWithoutPersonInput {
  title: PersonTitleCreationTitleInput;
}

/**
 * The "PersonTitle" node's creation
 */
export interface PersonTitleCreationWithoutTitleInput {
  person: PersonTitleCreationPersonInput;
}

/**
 * The "PersonTitle" nodes' filter
 */
export interface PersonTitleFilterInput {
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  person?: PersonFilterInput | null;
  person_not?: PersonFilterInput | null;
  title?: TitleFilterInput | null;
  title_not?: TitleFilterInput | null;
  AND?: (PersonTitleFilterInput | null)[] | null;
  OR?: (PersonTitleFilterInput | null)[] | null;
  NOT?: PersonTitleFilterInput | null;
}

/**
 * Given a "person", identifies exactly one "PersonTitle" with one of the following combination of components' value:
 * - (person) / title
 */
export interface PersonTitleUniqueFilterWithoutPersonInput {
  title: TitleUniqueFilterInput;
}

/**
 * Identifies exactly one "Person" with one of the following combination of components' value:
 * - id
 * - slug
 * - fullName
 * - prismamediaId
 */
export interface PersonUniqueFilterInput {
  id?: GraphQL$UUIDv4 | null;
  slug?: GraphQL$NonEmptyTrimmedString | null;
  fullName?: GraphQL$NonEmptyTrimmedString | null;
  prismamediaId?: GraphQL$UnsignedInt | null;
}

export interface PersonUpdateBiographiesCreateIfNotExistsInput {
  where: PersonBiographyUniqueFilterWithoutPersonInput;
  data: PersonBiographyCreationWithoutPersonInput;
}

export interface PersonUpdateBiographiesInput {
  deleteAll?: boolean | null;
  deleteMany?: PersonBiographyFilterInput | null;
  delete?: PersonBiographyUniqueFilterWithoutPersonInput[] | null;
  deleteIfExists?: PersonBiographyUniqueFilterWithoutPersonInput[] | null;
  create?: PersonBiographyCreationWithoutPersonInput[] | null;
  createIfNotExists?: PersonUpdateBiographiesCreateIfNotExistsInput[] | null;
  updateAll?: PersonBiographyUpdateWithoutPersonInput | null;
  updateMany?: PersonUpdateBiographiesUpdateManyInput | null;
  update?: PersonUpdateBiographiesUpdateInput[] | null;
  updateIfExists?: PersonUpdateBiographiesUpdateIfExistsInput[] | null;
  upsert?: PersonUpdateBiographiesUpsertInput[] | null;
}

export interface PersonUpdateBiographiesUpdateIfExistsInput {
  where: PersonBiographyUniqueFilterWithoutPersonInput;
  data?: PersonBiographyUpdateWithoutPersonInput | null;
}

export interface PersonUpdateBiographiesUpdateInput {
  where: PersonBiographyUniqueFilterWithoutPersonInput;
  data?: PersonBiographyUpdateWithoutPersonInput | null;
}

export interface PersonUpdateBiographiesUpdateManyInput {
  where?: PersonBiographyFilterInput | null;
  data?: PersonBiographyUpdateWithoutPersonInput | null;
}

export interface PersonUpdateBiographiesUpsertInput {
  where: PersonBiographyUniqueFilterWithoutPersonInput;
  create: PersonBiographyCreationWithoutPersonInput;
  update?: PersonBiographyUpdateWithoutPersonInput | null;
}

export interface PersonUpdateCountriesCreateIfNotExistsInput {
  where: PersonCountryUniqueFilterWithoutPersonInput;
  data: PersonCountryCreationWithoutPersonInput;
}

export interface PersonUpdateCountriesInput {
  deleteAll?: boolean | null;
  deleteMany?: PersonCountryFilterInput | null;
  delete?: PersonCountryUniqueFilterWithoutPersonInput[] | null;
  deleteIfExists?: PersonCountryUniqueFilterWithoutPersonInput[] | null;
  create?: PersonCountryCreationWithoutPersonInput[] | null;
  createIfNotExists?: PersonUpdateCountriesCreateIfNotExistsInput[] | null;
}

export interface PersonUpdateDegreesCreateIfNotExistsInput {
  where: PersonDegreeUniqueFilterWithoutPersonInput;
  data: PersonDegreeCreationWithoutPersonInput;
}

export interface PersonUpdateDegreesInput {
  deleteAll?: boolean | null;
  deleteMany?: PersonDegreeFilterInput | null;
  delete?: PersonDegreeUniqueFilterWithoutPersonInput[] | null;
  deleteIfExists?: PersonDegreeUniqueFilterWithoutPersonInput[] | null;
  create?: PersonDegreeCreationWithoutPersonInput[] | null;
  createIfNotExists?: PersonUpdateDegreesCreateIfNotExistsInput[] | null;
}

export interface PersonUpdateHistoriesInput {
  deleteAll?: boolean | null;
  deleteMany?: PersonHistoryFilterInput | null;
  create?: PersonHistoryCreationWithoutPersonInput[] | null;
}

/**
 * The "Person" node's update
 */
export interface PersonUpdateInput {
  editedAt?: GraphQL$DateTime | null;
  status?: PersonStatus | null;
  firstName?: GraphQL$NonEmptyTrimmedString | null;
  lastName?: GraphQL$NonEmptyTrimmedString | null;
  realFirstName?: GraphQL$NonEmptyTrimmedString | null;
  realLastName?: GraphQL$NonEmptyTrimmedString | null;
  sex?: GraphQL$NonEmptyTrimmedString | null;
  birth?: GraphQL$Date | null;
  death?: GraphQL$Date | null;
  residence?: GraphQL$NonEmptyTrimmedString | null;
  website?: GraphQL$URL | null;
  facebook?: GraphQL$URL | null;
  instagram?: GraphQL$URL | null;
  snapchat?: GraphQL$URL | null;
  tiktok?: GraphQL$URL | null;
  twitch?: GraphQL$URL | null;
  twitter?: GraphQL$URL | null;
  youtube?: GraphQL$URL | null;
  lockerId?: GraphQL$UUIDv4 | null;
  biographies?: PersonUpdateBiographiesInput | null;
  countries?: PersonUpdateCountriesInput | null;
  degrees?: PersonUpdateDegreesInput | null;
  jobs?: PersonUpdateJobsInput | null;
  nominations?: PersonUpdateNominationsInput | null;
  passions?: PersonUpdatePassionsInput | null;
  relationshipTo?: PersonUpdateRelationshipToInput | null;
  relationshipFrom?: PersonUpdateRelationshipFromInput | null;
  schools?: PersonUpdateSchoolsInput | null;
  titles?: PersonUpdateTitlesInput | null;
  histories?: PersonUpdateHistoriesInput | null;
}

export interface PersonUpdateJobsCreateIfNotExistsInput {
  where: PersonJobUniqueFilterWithoutPersonInput;
  data: PersonJobCreationWithoutPersonInput;
}

export interface PersonUpdateJobsInput {
  deleteAll?: boolean | null;
  deleteMany?: PersonJobFilterInput | null;
  delete?: PersonJobUniqueFilterWithoutPersonInput[] | null;
  deleteIfExists?: PersonJobUniqueFilterWithoutPersonInput[] | null;
  create?: PersonJobCreationWithoutPersonInput[] | null;
  createIfNotExists?: PersonUpdateJobsCreateIfNotExistsInput[] | null;
}

export interface PersonUpdateNominationsCreateIfNotExistsInput {
  where: PersonNominationUniqueFilterWithoutPersonInput;
  data: PersonNominationCreationWithoutPersonInput;
}

export interface PersonUpdateNominationsInput {
  deleteAll?: boolean | null;
  deleteMany?: PersonNominationFilterInput | null;
  delete?: PersonNominationUniqueFilterWithoutPersonInput[] | null;
  deleteIfExists?: PersonNominationUniqueFilterWithoutPersonInput[] | null;
  create?: PersonNominationCreationWithoutPersonInput[] | null;
  createIfNotExists?: PersonUpdateNominationsCreateIfNotExistsInput[] | null;
  updateAll?: PersonNominationUpdateWithoutPersonInput | null;
  updateMany?: PersonUpdateNominationsUpdateManyInput | null;
  update?: PersonUpdateNominationsUpdateInput[] | null;
  updateIfExists?: PersonUpdateNominationsUpdateIfExistsInput[] | null;
  upsert?: PersonUpdateNominationsUpsertInput[] | null;
}

export interface PersonUpdateNominationsUpdateIfExistsInput {
  where: PersonNominationUniqueFilterWithoutPersonInput;
  data?: PersonNominationUpdateWithoutPersonInput | null;
}

export interface PersonUpdateNominationsUpdateInput {
  where: PersonNominationUniqueFilterWithoutPersonInput;
  data?: PersonNominationUpdateWithoutPersonInput | null;
}

export interface PersonUpdateNominationsUpdateManyInput {
  where?: PersonNominationFilterInput | null;
  data?: PersonNominationUpdateWithoutPersonInput | null;
}

export interface PersonUpdateNominationsUpsertInput {
  where: PersonNominationUniqueFilterWithoutPersonInput;
  create: PersonNominationCreationWithoutPersonInput;
  update?: PersonNominationUpdateWithoutPersonInput | null;
}

export interface PersonUpdatePassionsCreateIfNotExistsInput {
  where: PersonPassionUniqueFilterWithoutPersonInput;
  data: PersonPassionCreationWithoutPersonInput;
}

export interface PersonUpdatePassionsInput {
  deleteAll?: boolean | null;
  deleteMany?: PersonPassionFilterInput | null;
  delete?: PersonPassionUniqueFilterWithoutPersonInput[] | null;
  deleteIfExists?: PersonPassionUniqueFilterWithoutPersonInput[] | null;
  create?: PersonPassionCreationWithoutPersonInput[] | null;
  createIfNotExists?: PersonUpdatePassionsCreateIfNotExistsInput[] | null;
}

export interface PersonUpdateRelationshipFromCreateIfNotExistsInput {
  where: PersonRelationshipUniqueFilterWithoutPersonToInput;
  data: PersonRelationshipCreationWithoutPersonToInput;
}

export interface PersonUpdateRelationshipFromInput {
  deleteAll?: boolean | null;
  deleteMany?: PersonRelationshipFilterInput | null;
  delete?: PersonRelationshipUniqueFilterWithoutPersonToInput[] | null;
  deleteIfExists?: PersonRelationshipUniqueFilterWithoutPersonToInput[] | null;
  create?: PersonRelationshipCreationWithoutPersonToInput[] | null;
  createIfNotExists?: PersonUpdateRelationshipFromCreateIfNotExistsInput[] | null;
  updateAll?: PersonRelationshipUpdateWithoutPersonToInput | null;
  updateMany?: PersonUpdateRelationshipFromUpdateManyInput | null;
  update?: PersonUpdateRelationshipFromUpdateInput[] | null;
  updateIfExists?: PersonUpdateRelationshipFromUpdateIfExistsInput[] | null;
  upsert?: PersonUpdateRelationshipFromUpsertInput[] | null;
}

export interface PersonUpdateRelationshipFromUpdateIfExistsInput {
  where: PersonRelationshipUniqueFilterWithoutPersonToInput;
  data?: PersonRelationshipUpdateWithoutPersonToInput | null;
}

export interface PersonUpdateRelationshipFromUpdateInput {
  where: PersonRelationshipUniqueFilterWithoutPersonToInput;
  data?: PersonRelationshipUpdateWithoutPersonToInput | null;
}

export interface PersonUpdateRelationshipFromUpdateManyInput {
  where?: PersonRelationshipFilterInput | null;
  data?: PersonRelationshipUpdateWithoutPersonToInput | null;
}

export interface PersonUpdateRelationshipFromUpsertInput {
  where: PersonRelationshipUniqueFilterWithoutPersonToInput;
  create: PersonRelationshipCreationWithoutPersonToInput;
  update?: PersonRelationshipUpdateWithoutPersonToInput | null;
}

export interface PersonUpdateRelationshipToCreateIfNotExistsInput {
  where: PersonRelationshipUniqueFilterWithoutPersonFromInput;
  data: PersonRelationshipCreationWithoutPersonFromInput;
}

export interface PersonUpdateRelationshipToInput {
  deleteAll?: boolean | null;
  deleteMany?: PersonRelationshipFilterInput | null;
  delete?: PersonRelationshipUniqueFilterWithoutPersonFromInput[] | null;
  deleteIfExists?: PersonRelationshipUniqueFilterWithoutPersonFromInput[] | null;
  create?: PersonRelationshipCreationWithoutPersonFromInput[] | null;
  createIfNotExists?: PersonUpdateRelationshipToCreateIfNotExistsInput[] | null;
  updateAll?: PersonRelationshipUpdateWithoutPersonFromInput | null;
  updateMany?: PersonUpdateRelationshipToUpdateManyInput | null;
  update?: PersonUpdateRelationshipToUpdateInput[] | null;
  updateIfExists?: PersonUpdateRelationshipToUpdateIfExistsInput[] | null;
  upsert?: PersonUpdateRelationshipToUpsertInput[] | null;
}

export interface PersonUpdateRelationshipToUpdateIfExistsInput {
  where: PersonRelationshipUniqueFilterWithoutPersonFromInput;
  data?: PersonRelationshipUpdateWithoutPersonFromInput | null;
}

export interface PersonUpdateRelationshipToUpdateInput {
  where: PersonRelationshipUniqueFilterWithoutPersonFromInput;
  data?: PersonRelationshipUpdateWithoutPersonFromInput | null;
}

export interface PersonUpdateRelationshipToUpdateManyInput {
  where?: PersonRelationshipFilterInput | null;
  data?: PersonRelationshipUpdateWithoutPersonFromInput | null;
}

export interface PersonUpdateRelationshipToUpsertInput {
  where: PersonRelationshipUniqueFilterWithoutPersonFromInput;
  create: PersonRelationshipCreationWithoutPersonFromInput;
  update?: PersonRelationshipUpdateWithoutPersonFromInput | null;
}

export interface PersonUpdateSchoolsCreateIfNotExistsInput {
  where: PersonStudyUniqueFilterWithoutPersonInput;
  data: PersonStudyCreationWithoutPersonInput;
}

export interface PersonUpdateSchoolsInput {
  deleteAll?: boolean | null;
  deleteMany?: PersonStudyFilterInput | null;
  delete?: PersonStudyUniqueFilterWithoutPersonInput[] | null;
  deleteIfExists?: PersonStudyUniqueFilterWithoutPersonInput[] | null;
  create?: PersonStudyCreationWithoutPersonInput[] | null;
  createIfNotExists?: PersonUpdateSchoolsCreateIfNotExistsInput[] | null;
}

export interface PersonUpdateTitlesCreateIfNotExistsInput {
  where: PersonTitleUniqueFilterWithoutPersonInput;
  data: PersonTitleCreationWithoutPersonInput;
}

export interface PersonUpdateTitlesInput {
  deleteAll?: boolean | null;
  deleteMany?: PersonTitleFilterInput | null;
  delete?: PersonTitleUniqueFilterWithoutPersonInput[] | null;
  deleteIfExists?: PersonTitleUniqueFilterWithoutPersonInput[] | null;
  create?: PersonTitleCreationWithoutPersonInput[] | null;
  createIfNotExists?: PersonUpdateTitlesCreateIfNotExistsInput[] | null;
}

/**
 * The "RelationshipTitle" node's creation
 */
export interface RelationshipTitleCreationInput {
  id?: GraphQL$UUIDv4 | null;
  title: GraphQL$NonEmptyTrimmedString;
  personRelationships?: RelationshipTitleCreationPersonRelationshipsInput | null;
}

export interface RelationshipTitleCreationPersonRelationshipsInput {
  create?: PersonRelationshipCreationWithoutRelationshipTitleInput[] | null;
}

/**
 * The "RelationshipTitle" nodes' filter
 */
export interface RelationshipTitleFilterInput {
  id?: GraphQL$UUIDv4 | null;
  id_not?: GraphQL$UUIDv4 | null;
  id_in?: GraphQL$UUIDv4[] | null;
  id_not_in?: GraphQL$UUIDv4[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  title?: GraphQL$NonEmptyTrimmedString | null;
  title_not?: GraphQL$NonEmptyTrimmedString | null;
  title_in?: GraphQL$NonEmptyTrimmedString[] | null;
  title_not_in?: GraphQL$NonEmptyTrimmedString[] | null;
  title_contains?: GraphQL$NonEmptyString | null;
  title_not_contains?: GraphQL$NonEmptyString | null;
  title_starts_with?: GraphQL$NonEmptyString | null;
  title_not_starts_with?: GraphQL$NonEmptyString | null;
  title_ends_with?: GraphQL$NonEmptyString | null;
  title_not_ends_with?: GraphQL$NonEmptyString | null;
  slug?: GraphQL$NonEmptyTrimmedString | null;
  slug_not?: GraphQL$NonEmptyTrimmedString | null;
  slug_in?: GraphQL$NonEmptyTrimmedString[] | null;
  slug_not_in?: GraphQL$NonEmptyTrimmedString[] | null;
  slug_contains?: GraphQL$NonEmptyString | null;
  slug_not_contains?: GraphQL$NonEmptyString | null;
  slug_starts_with?: GraphQL$NonEmptyString | null;
  slug_not_starts_with?: GraphQL$NonEmptyString | null;
  slug_ends_with?: GraphQL$NonEmptyString | null;
  slug_not_ends_with?: GraphQL$NonEmptyString | null;
  personRelationships_every?: PersonRelationshipFilterInput | null;
  personRelationships_some?: PersonRelationshipFilterInput | null;
  personRelationships_none?: PersonRelationshipFilterInput | null;
  personRelationshipCount?: GraphQL$UnsignedInt | null;
  personRelationshipCount_not?: GraphQL$UnsignedInt | null;
  personRelationshipCount_gt?: GraphQL$UnsignedInt | null;
  personRelationshipCount_gte?: GraphQL$UnsignedInt | null;
  personRelationshipCount_lt?: GraphQL$UnsignedInt | null;
  personRelationshipCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (RelationshipTitleFilterInput | null)[] | null;
  OR?: (RelationshipTitleFilterInput | null)[] | null;
  NOT?: RelationshipTitleFilterInput | null;
}

/**
 * Identifies exactly one "RelationshipTitle" with one of the following combination of components' value:
 * - id
 * - slug
 * - title
 */
export interface RelationshipTitleUniqueFilterInput {
  id?: GraphQL$UUIDv4 | null;
  slug?: GraphQL$NonEmptyTrimmedString | null;
  title?: GraphQL$NonEmptyTrimmedString | null;
}

/**
 * The "RelationshipTitle" node's update
 */
export interface RelationshipTitleUpdateInput {
  title?: GraphQL$NonEmptyTrimmedString | null;
  personRelationships?: RelationshipTitleUpdatePersonRelationshipsInput | null;
}

export interface RelationshipTitleUpdatePersonRelationshipsInput {
  deleteAll?: boolean | null;
  deleteMany?: PersonRelationshipFilterInput | null;
  create?: PersonRelationshipCreationWithoutRelationshipTitleInput[] | null;
  updateAll?: PersonRelationshipUpdateWithoutRelationshipTitleInput | null;
  updateMany?: RelationshipTitleUpdatePersonRelationshipsUpdateManyInput | null;
}

export interface RelationshipTitleUpdatePersonRelationshipsUpdateManyInput {
  where?: PersonRelationshipFilterInput | null;
  data?: PersonRelationshipUpdateWithoutRelationshipTitleInput | null;
}

/**
 * The "School" node's creation
 */
export interface SchoolCreationInput {
  id?: GraphQL$UUIDv4 | null;
  title: GraphQL$NonEmptyTrimmedString;
  people?: SchoolCreationPeopleInput | null;
}

export interface SchoolCreationPeopleInput {
  create?: PersonStudyCreationWithoutSchoolInput[] | null;
}

/**
 * The "School" nodes' filter
 */
export interface SchoolFilterInput {
  id?: GraphQL$UUIDv4 | null;
  id_not?: GraphQL$UUIDv4 | null;
  id_in?: GraphQL$UUIDv4[] | null;
  id_not_in?: GraphQL$UUIDv4[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  title?: GraphQL$NonEmptyTrimmedString | null;
  title_not?: GraphQL$NonEmptyTrimmedString | null;
  title_in?: GraphQL$NonEmptyTrimmedString[] | null;
  title_not_in?: GraphQL$NonEmptyTrimmedString[] | null;
  title_gt?: GraphQL$NonEmptyTrimmedString | null;
  title_gte?: GraphQL$NonEmptyTrimmedString | null;
  title_lt?: GraphQL$NonEmptyTrimmedString | null;
  title_lte?: GraphQL$NonEmptyTrimmedString | null;
  title_contains?: GraphQL$NonEmptyString | null;
  title_not_contains?: GraphQL$NonEmptyString | null;
  title_starts_with?: GraphQL$NonEmptyString | null;
  title_not_starts_with?: GraphQL$NonEmptyString | null;
  title_ends_with?: GraphQL$NonEmptyString | null;
  title_not_ends_with?: GraphQL$NonEmptyString | null;
  people_every?: PersonStudyFilterInput | null;
  people_some?: PersonStudyFilterInput | null;
  people_none?: PersonStudyFilterInput | null;
  personCount?: GraphQL$UnsignedInt | null;
  personCount_not?: GraphQL$UnsignedInt | null;
  personCount_gt?: GraphQL$UnsignedInt | null;
  personCount_gte?: GraphQL$UnsignedInt | null;
  personCount_lt?: GraphQL$UnsignedInt | null;
  personCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (SchoolFilterInput | null)[] | null;
  OR?: (SchoolFilterInput | null)[] | null;
  NOT?: SchoolFilterInput | null;
}

/**
 * Identifies exactly one "School" with one of the following combination of components' value:
 * - id
 * - title
 */
export interface SchoolUniqueFilterInput {
  id?: GraphQL$UUIDv4 | null;
  title?: GraphQL$NonEmptyTrimmedString | null;
}

/**
 * The "Title" node's creation
 */
export interface TitleCreationInput {
  id?: GraphQL$UUIDv4 | null;
  title: GraphQL$NonEmptyTrimmedString;
  people?: TitleCreationPeopleInput | null;
}

export interface TitleCreationPeopleInput {
  create?: PersonTitleCreationWithoutTitleInput[] | null;
}

/**
 * The "Title" nodes' filter
 */
export interface TitleFilterInput {
  id?: GraphQL$UUIDv4 | null;
  id_not?: GraphQL$UUIDv4 | null;
  id_in?: GraphQL$UUIDv4[] | null;
  id_not_in?: GraphQL$UUIDv4[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  title?: GraphQL$NonEmptyTrimmedString | null;
  title_not?: GraphQL$NonEmptyTrimmedString | null;
  title_in?: GraphQL$NonEmptyTrimmedString[] | null;
  title_not_in?: GraphQL$NonEmptyTrimmedString[] | null;
  title_gt?: GraphQL$NonEmptyTrimmedString | null;
  title_gte?: GraphQL$NonEmptyTrimmedString | null;
  title_lt?: GraphQL$NonEmptyTrimmedString | null;
  title_lte?: GraphQL$NonEmptyTrimmedString | null;
  title_contains?: GraphQL$NonEmptyString | null;
  title_not_contains?: GraphQL$NonEmptyString | null;
  title_starts_with?: GraphQL$NonEmptyString | null;
  title_not_starts_with?: GraphQL$NonEmptyString | null;
  title_ends_with?: GraphQL$NonEmptyString | null;
  title_not_ends_with?: GraphQL$NonEmptyString | null;
  people_every?: PersonTitleFilterInput | null;
  people_some?: PersonTitleFilterInput | null;
  people_none?: PersonTitleFilterInput | null;
  personCount?: GraphQL$UnsignedInt | null;
  personCount_not?: GraphQL$UnsignedInt | null;
  personCount_gt?: GraphQL$UnsignedInt | null;
  personCount_gte?: GraphQL$UnsignedInt | null;
  personCount_lt?: GraphQL$UnsignedInt | null;
  personCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (TitleFilterInput | null)[] | null;
  OR?: (TitleFilterInput | null)[] | null;
  NOT?: TitleFilterInput | null;
}

/**
 * Identifies exactly one "Title" with one of the following combination of components' value:
 * - id
 * - title
 */
export interface TitleUniqueFilterInput {
  id?: GraphQL$UUIDv4 | null;
  title?: GraphQL$NonEmptyTrimmedString | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

import { PersonStatus } from '../__generated__/queries-person';

export interface PersonStatusProps {
  label: string;
  singularLabel: string;
  inputLabel: string;
  value: PersonStatus | string;
}

export const PERSON_STATUS: PersonStatusProps[] = [
  {
    label: 'Publiés',
    singularLabel: 'Publié',
    inputLabel: 'Publier',
    value: PersonStatus.Published,
  },
  {
    label: 'Brouillons',
    singularLabel: 'Brouillon',
    inputLabel: 'Brouillon',
    value: PersonStatus.Draft,
  },
  {
    label: 'Corbeille',
    singularLabel: 'Corbeille',
    inputLabel: 'Corbeille',
    value: PersonStatus.Deleted,
  },
];

import { gql } from '@apollo/client';

export const GET_RELATIONSHIP_TITLES_QUERY = gql`
  query GetRelationshipTitles(
    $first: UnsignedInt!
    $where: RelationshipTitleFilterInput
    $skip: UnsignedInt
    $orderBy: [RelationshipTitleOrderingInput!]
  ) {
    relationshipTitles(
      first: $first
      where: $where
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      title
      slug
    }
  }
`;

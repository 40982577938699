import axios from 'axios';
import { auth } from './auth';

export const getAxiosClient = async () => {
  const jwt = await auth.getJwt();
  if (!jwt) {
    return null;
  }
  return axios.create({
    headers: { Authorization: `Bearer ${jwt}` },
  });
};

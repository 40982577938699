import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  StandardProps,
} from '@mui/material';
import { SelectProps } from '@mui/material/Select';
import { ExpandMore } from '@mui/icons-material';
import classnames from 'classnames';
import * as React from 'react';
import {
  useEditContext,
  useEditContextForInput,
} from '../../../../one-crud-react';
import { useStyles } from './styles';

interface SelectOption {
  value: string | number;
  label: string | number;
}

type StatusMenuProps = {
  field: string;
  label?: string;
  options?: SelectOption[];
  placeholder?: string;
  selectProps?: SelectProps;
  setSaving: (saving: boolean) => void;
} & StandardProps<React.HTMLAttributes<HTMLDivElement>, ''>;

export const StatusMenu: React.FC<StatusMenuProps> = ({
  className,
  field,
  label,
  options,
  placeholder,
  selectProps,
  setSaving,
  style,
}: StatusMenuProps) => {
  const context = useEditContext();
  const classes = useStyles();
  const {
    fieldValue,
    onChange: onInputChange,
  } = useEditContextForInput<string>(field);
  const value = fieldValue || '';

  return (
    <FormControl className={classnames(classes.root, className)} style={style}>
      <InputLabel htmlFor={field}>{label || field}</InputLabel>
      <Select
        IconComponent={ExpandMore}
        variant="outlined"
        value={value}
        inputProps={{
          name: field,
          id: field,
        }}
        classes={{ iconOutlined: classes.selectIcon }}
        className={classes.selectInput}
        {...selectProps}
        onChange={(event: any) => {
          onInputChange(event.target.value as string);
          setSaving(true);
          context.onSave(context.item).finally(() => {
            setSaving(false);
          });
        }}
      >
        {placeholder && (
          <MenuItem value="">
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {Array.isArray(options) &&
          options.map(({ label: optionLabel, value: optionValue }) => (
            <MenuItem key={optionValue} value={optionValue}>
              {optionLabel}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

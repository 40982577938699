import { gql } from '@apollo/client';

import { COUNTRY_FRAGMENT } from '../Fragments/countryFragment.person.graphql';

export const UPSERT_COUNTRY_MUTATION = gql`
  mutation UpsertCountry(
    $where: CountryUniqueFilterInput!
    $update: CountryUpdateInput!
    $create: CountryCreationInput!
  ) {
    upsertCountry(create: $create, update: $update, where: $where) {
      ...Country
    }
  }
  ${COUNTRY_FRAGMENT}
`;

import React from 'react';
import { ApolloConsumer } from 'react-apollo';
import uuid from 'uuid';
import { GET_COUNTRY_QUERY } from '../../../../apollo/person/Query/getCountryQuery.person.graphql';
import { GET_JOB_QUERY } from '../../../../apollo/person/Query/getJobQuery.person.graphql';
import { UPSERT_COUNTRY_MUTATION } from '../../../../apollo/person/Mutations/upsertCountryMutation.person.graphql';
import { UPSERT_JOB_MUTATION } from '../../../../apollo/person/Mutations/upsertJobMutation.person.graphql';
import {
  ChipsInput,
  InputGroup,
  SelectInput,
  TextInput,
  useEditContext,
} from '../../../../one-crud-react';
import { simpleQuery } from '../../../../utils/simpleQuery';
import {
  GetCountry,
  GetJob,
  UpsertCountry,
  UpsertCountryVariables,
  UpsertJob,
  UpsertJobVariables,
} from '../../../../__generated__/queries-person';
import { CountryResource } from '../../config/country.config';
import { JobResource } from '../../config/job.config';
import { stylesProps, wrapped } from '../styles';
import { BasicContainer } from './BasicContainer';

const CivilityFieldsWithoutStyle: React.FC<stylesProps> = ({ classes }) => {
  const context = useEditContext();
  const { jobs } = context.item;
  const { countries } = context.item;

  return (
    <BasicContainer keyValue="civil" title="État civil">
      <InputGroup>
        <SelectInput
          field="sex"
          label="Sexe *"
          options={[
            { label: 'Homme', value: 'homme' },
            { label: 'Femme', value: 'femme' },
            { label: 'Transgenre', value: 'transgenre' },
          ]}
        />
      </InputGroup>
      <InputGroup>
        <TextInput
          field="lastName"
          label="Nom usuel"
          textFieldProps={{ fullWidth: true }}
        />
        <TextInput
          field="firstName"
          label="Prénom usuel *"
          textFieldProps={{ fullWidth: true }}
        />
      </InputGroup>
      <InputGroup>
        <TextInput
          field="realLastName"
          label="Vrai nom"
          textFieldProps={{ fullWidth: true }}
        />
        <TextInput
          field="realFirstName"
          label="Vrai prénom"
          textFieldProps={{ fullWidth: true }}
        />
      </InputGroup>
      <InputGroup>
        <TextInput
          field="birth"
          label="Date de naissance"
          textFieldProps={{
            fullWidth: true,
            type: 'date',
            InputLabelProps: { shrink: true },
          }}
        />
        <TextInput
          field="death"
          label="Date du décés"
          textFieldProps={{
            fullWidth: true,
            type: 'date',
            InputLabelProps: { shrink: true },
          }}
        />
      </InputGroup>
      <InputGroup>
        <ApolloConsumer>
          {(client) => (
            <>
              <InputGroup>
                <ChipsInput
                  field="jobs"
                  label="Métiers"
                  pathToLabel="job.title"
                  pathToValue="job.id"
                  textFieldProps={{
                    placeholder: 'Ajouter +',
                  }}
                  className={classes.chipsInput}
                  autoSuggestFieldProps={{
                    upsertSuggestion: async ({ id: defaultId, title }) => {
                      const id = defaultId || uuid();
                      try {
                        const result = await client.mutate<
                          UpsertJob,
                          UpsertJobVariables
                        >({
                          mutation: UPSERT_JOB_MUTATION,
                          variables: {
                            create: { id, title },
                            update: { title },
                            where: { id },
                          },
                        });
                        if (result && result.data) {
                          return result.data.upsertJob;
                        }
                      } catch (error) {
                        console.warn('Error during the new job upsert', error);
                      }
                      return undefined;
                    },
                    fetchSuggestions: (value) =>
                      simpleQuery<GetJob>({
                        client: client as any,
                        value,
                        query: GET_JOB_QUERY,
                        extraVariables: { orderBy: 'title_ASC' },
                        resource: JobResource,
                        fetchPolicy: 'network-only',
                        mapper: (res) => ({ id: res.id, label: res.title }),
                        extraWhereConditions: {
                          id_not_in: jobs.map(({ job }: any) => job.id),
                        },
                      }),
                    suggestionStyle: classes.suggestionStyle,
                    keepSelectedValue: false,
                  }}
                />
              </InputGroup>
              <InputGroup>
                <ChipsInput
                  field="countries"
                  label="Pays"
                  pathToLabel="country.title"
                  pathToValue="country.id"
                  className={classes.chipsInput}
                  autoSuggestFieldProps={{
                    upsertSuggestion: async ({ id: defaultId, title }) => {
                      const id = defaultId || uuid();
                      try {
                        const result = await client.mutate<
                          UpsertCountry,
                          UpsertCountryVariables
                        >({
                          mutation: UPSERT_COUNTRY_MUTATION,
                          variables: {
                            create: { id, title },
                            update: { title },
                            where: { id },
                          },
                        });
                        if (result && result.data) {
                          return result.data.upsertCountry;
                        }
                      } catch (error) {
                        console.warn(
                          'Error during the new country upsert',
                          error,
                        );
                      }
                      return undefined;
                    },
                    fetchSuggestions: (value) =>
                      simpleQuery<GetCountry>({
                        client: client as any,
                        value,
                        query: GET_COUNTRY_QUERY,
                        fetchPolicy: 'network-only',
                        resource: CountryResource,
                        mapper: (res) => ({ id: res.id, label: res.title }),
                        extraWhereConditions: {
                          id_not_in: countries.map(
                            ({ country }: any) => country.id,
                          ),
                        },
                      }),
                    suggestionStyle: classes.suggestionStyle,
                    keepSelectedValue: false,
                  }}
                />
              </InputGroup>
            </>
          )}
        </ApolloConsumer>
      </InputGroup>
    </BasicContainer>
  );
};

export const CivilityFields = wrapped(CivilityFieldsWithoutStyle);

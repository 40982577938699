import React from 'react';
import { UrlTextInput } from '../../../../components/UrlTextInput';
import { InputGroup } from '../../../../one-crud-react';
import { BasicContainer } from './BasicContainer';

export const SocialNetworks: React.FC = () => {
  return (
    <BasicContainer keyValue="social-network" title="Réseaux sociaux">
      <InputGroup>
        <UrlTextInput
          field="twitter"
          label="Twitter"
          textFieldProps={{ fullWidth: true }}
        />
        <UrlTextInput
          field="youtube"
          label="Youtube"
          textFieldProps={{ fullWidth: true }}
        />
        <UrlTextInput
          field="facebook"
          label="Facebook"
          textFieldProps={{ fullWidth: true }}
        />
        <UrlTextInput
          field="twitch"
          label="Twitch"
          textFieldProps={{ fullWidth: true }}
        />
        <UrlTextInput
          field="instagram"
          label="Instagram"
          textFieldProps={{ fullWidth: true }}
        />
      </InputGroup>
      <InputGroup>
        <UrlTextInput
          field="tiktok"
          label="Tiktok"
          textFieldProps={{ fullWidth: true }}
        />
        <UrlTextInput
          field="snapchat"
          label="Snapchat"
          textFieldProps={{ fullWidth: true }}
        />
      </InputGroup>
    </BasicContainer>
  );
};

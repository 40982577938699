import { History } from '@mui/icons-material';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { useDialog } from '@prismamedia/one-components';
import { FC, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { GetPerson_person } from '../../../__generated__/queries-person';
import { getBrandKeyFromSlug } from '../../../utils/brands';
import { formatDateFr } from '../../../utils/format';
import { ArticleHistory } from './ArticleHistory/ArticleHistory';
import SavePanel from './SavePanel';

interface AppBarTitleProps {
  item: GetPerson_person;
}

export const ActionBtns: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { openDialog } = useDialog();

  return (
    <div
      data-testid="action-buttons"
      style={{
        alignItems: 'center',
        display: 'flex',
        position: 'absolute',
        right: '10px',
      }}
    >
      {id && (
        <Tooltip title="Historique" aria-label="historique">
          <IconButton
            data-testid="history-button"
            onClick={() =>
              openDialog(<ArticleHistory id={id} />, { maxWidth: 'lg' })
            }
            size="large"
          >
            <History style={{ color: '#FFF' }} />
          </IconButton>
        </Tooltip>
      )}
      <div
        style={{
          backgroundColor: '#000',
          height: '50px',
          opacity: 0.12,
          margin: `0 24px`,
          width: 1,
        }}
      />
      <SavePanel
        key="saveButton"
        buttonProps={{
          'data-testid': 'saveButton',
          variant: 'outlined',
          color: 'inherit',
        }}
        buttonLabel="sauvegarder"
      />
    </div>
  );
};

export const AppBarTitle: FC<AppBarTitleProps> = ({ item }) => {
  const { firstName, lastName, biographies } = item;
  const fullName = `${firstName || ''} ${lastName || ''}`;
  const location = useLocation();
  const brandSlug = useMemo(() => location.pathname.split('/').pop() || '', [
    location.pathname,
  ]);
  const biography =
    brandSlug !== 'generalities'
      ? biographies.find(
          (personBiography) =>
            personBiography.brandKey === getBrandKeyFromSlug(brandSlug),
        )
      : { ...item };

  return (
    <div>
      <Typography variant="h6">{fullName}</Typography>

      {biography?.createdAt && biography?.editedAt && (
        <Typography variant="caption" component="p">
          {`${
            brandSlug === 'generalities' ? 'Fiche' : 'Biographie'
          } créée le ${formatDateFr(
            biography.createdAt,
            "dd/MM/yyyy, hh'h'mm",
          )} - Dernière modification le ${formatDateFr(
            biography.editedAt,
            "dd/MM/yyyy, hh'h'mm",
          )}`}
        </Typography>
      )}
    </div>
  );
};

import { gql } from '@apollo/client';

export const GET_JOB_QUERY = gql`
  query GetJob(
    $first: UnsignedInt!
    $where: JobFilterInput
    $skip: UnsignedInt
    $orderBy: [JobOrderingInput!]
  ) {
    jobs(first: $first, where: $where, skip: $skip, orderBy: $orderBy) {
      id
      title
    }
  }
`;

import { ApolloProvider } from '@apollo/client';
import { ApolloProvider as HooksApolloProvider } from '@apollo/react-hooks';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  createTheme,
  CssBaseline,
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import {
  DialogDisplay,
  DialogProvider,
  ErrorBoundary,
  MenuDisplay,
  MenuProvider,
  NotificationDisplay,
  NotificationProvider,
  UserDialog,
} from '@prismamedia/one-components';
import { setDefaultOptions } from 'date-fns';
import { fr } from 'date-fns/locale';
import frLocale from 'date-fns/locale/fr';
import React from 'react';
import { exception, initialize } from 'react-ga';
import { HashRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { client } from './apollo';
import { getUsers } from './apollo/auth/users.auth.graphql';
import { Router } from './routing';
import { auth } from './utils/auth';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

setDefaultOptions({ locale: fr });

initialize(config.GA_ID, {
  testMode: process.env.NODE_ENV === 'test',
});

const muiTheme = createTheme({
  palette: {
    primary: {
      main: 'rgb(85, 114, 128)',
    },
    secondary: {
      main: 'rgb(60, 80, 89)',
    },
    background: {
      default: '#fafafa',
    },
  },
});

type classNames = 'printPortalWrapper' | 'appContainer';

const styles = createStyles({
  printPortalWrapper: {
    display: 'none',
    '&:not(:empty)': {
      '& ~ $appContainer': {
        '@media print': {
          display: 'none',
        },
      },
      '@media print': {
        display: 'block',
      },
    },
  },
  // need an object for css reference above ('& ~ $appContainer)
  appContainer: {},
});

class MyApp extends React.Component<WithStyles<classNames>> {
  render() {
    Notification.requestPermission();
    const onCatchError = (error: Error, errorInfo: React.ErrorInfo) => {
      exception({
        description: `Message : ${error.toString()} / Stack : ${
          errorInfo.componentStack
        }`,
        fatal: true,
      });
    };

    const { classes } = this.props;
    return (
      <StyledEngineProvider injectFirst>
        <HashRouter>
          <ThemeProvider theme={muiTheme}>
            <ErrorBoundary onCatchError={onCatchError}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={frLocale}
              >
                <RecoilRoot>
                  <NotificationProvider>
                    <div
                      className={`${classes.printPortalWrapper} print-portal-wrapper`}
                    />
                    <DialogProvider>
                      <MenuProvider>
                        <div className={classes.appContainer}>
                          <CssBaseline />
                          <ApolloProvider client={client}>
                            <HooksApolloProvider client={client as any}>
                              <div className="App">
                                <Router />
                                <DialogDisplay />
                                <MenuDisplay />
                                <NotificationDisplay />
                              </div>

                              <UserDialog auth={auth} searchUsers={getUsers} />
                            </HooksApolloProvider>
                          </ApolloProvider>
                        </div>
                      </MenuProvider>
                    </DialogProvider>
                  </NotificationProvider>
                </RecoilRoot>
              </LocalizationProvider>
            </ErrorBoundary>
          </ThemeProvider>
        </HashRouter>
      </StyledEngineProvider>
    );
  }
}

export const App = withStyles(styles)(MyApp);

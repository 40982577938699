import uuidv4 from 'uuid/v4';
import {
  BrandKey,
  GetPerson_person_biographies,
  PersonBiographyStatus,
} from '../../../__generated__/queries-person';

export const getBrandBiographyIndex = (
  biographies: GetPerson_person_biographies[],
  brandKey: BrandKey,
): number =>
  biographies.findIndex(
    (personBiography) => personBiography.brandKey === brandKey,
  );

export const getInitialBiography = (
  brandKey: BrandKey,
): Omit<
  GetPerson_person_biographies,
  '__typename' | 'createdAt' | 'updatedAt'
> => ({
  body: '',
  brandKey,
  id: uuidv4(),
  media: null,
  status: PersonBiographyStatus.Draft,
  editedAt: new Date().toISOString(),
});

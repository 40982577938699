import { getAxiosClient } from '../../utils/getAxiosClient';

export const fetchFocusPoint = async (data: any) => {
  const axios = await getAxiosClient();
  if (!axios) {
    throw 'Could not instanciate axios';
  }
  const { data: focusPoint } = await axios.post(
    `${config.API_ONE_NEWSLETTER}/focuspoint`,
    data,
  );
  return focusPoint;
};

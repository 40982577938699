/* eslint-disable @typescript-eslint/no-var-requires */
import makeStyles from '@mui/styles/makeStyles';
import { BrandKey } from '../../__generated__/queries-person';
import { brandKeys } from '../../utils/brands';

const getIcon = (brandKey: BrandKey) =>
  // eslint-disable-next-line @typescript-eslint/no-require-imports
  require(`../../assets/images/select-icons/${brandKey}.png`).default;

const items = brandKeys.reduce(
  (result: any, brand: BrandKey) => ({
    ...result,
    [brand]: {
      backgroundImage: `url(${getIcon(brand)})`,
    },
  }),
  {},
);

export const useStyles = makeStyles({
  shape: {
    width: 16,
    height: 16,
    marginRight: 5,
    display: 'inline-block',
    borderRadius: '50%',
  },
  ...items,
  unfilled: {
    filter: 'grayscale(100%)',
    opacity: 0.6,
  },
});

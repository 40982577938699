import { Fab, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import React from 'react';
import { stylesProps, wrapped } from '../styles';

type BasicContainerProps = {
  title: string;
  keyValue: string;
  children: React.ReactNode;
  onAdd?: (event: React.MouseEvent) => void;
} & stylesProps;

const BasicContainerWithoutStyle: React.FC<BasicContainerProps> = ({
  classes,
  title,
  keyValue,
  children,
  onAdd,
}) => (
  <div className={classes.fieldGroup}>
    <Typography variant="h6" className={classes.fieldGroupTitle}>
      {title}
    </Typography>
    {children}
    {onAdd && (
      <Fab
        data-testid={`${keyValue}-btn`}
        size="small"
        color="secondary"
        aria-label="Add"
        className={classes.addBtn}
        onClick={onAdd}
      >
        <Add />
      </Fab>
    )}
  </div>
);

export const BasicContainer = wrapped(BasicContainerWithoutStyle);

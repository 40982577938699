import { useEffect } from 'react';
import { set, pageview } from 'react-ga';
import { useLocation } from 'react-router-dom';
import { auth } from './auth';

export const useLocationChange = () => {
  const { pathname, search } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (auth.isAuthenticated()) {
      set({ userId: auth.user.id });
    }
    pageview(`${pathname}${search}`);
  }, [pathname, search]);
};

import { FormLabel, StandardProps, TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import classnames from 'classnames';
import { assocPath, mergeDeepLeft, path } from 'ramda';
import * as React from 'react';
import uuidv4 from 'uuid/v4';
import { parsePathToString } from '../../../crud-core/utils/Path';
import { Item } from '../../../crud-graphql-containers/Utils/genericTypes';
import AutoSuggestField, {
  AutoSuggestFieldProps,
} from '../../Field/AutoSuggestField';
import ChipsField, {
  ChipsFieldProps,
  DEFAULT_PATH_TO_LABEL,
  DEFAULT_PATH_TO_VALUE,
} from '../../Field/ChipsField';
import DraggableChipsField, {
  DEFAULT_PATH_TO_ORDER,
} from '../../Field/DraggableChipsField';
import { useEditContextForInput } from '../useEditContextForInput';
import { useStyles } from './styles';

type ChipsInputProps = {
  field: string;
  item?: Item;
  label?: string;
  chipsFieldProps?: Omit<
    ChipsFieldProps,
    'field' | 'classes' | 'pathToLabel' | 'pathToValue'
  >;
  autoSuggestFieldProps?: Omit<AutoSuggestFieldProps, 'classes'>;
  onChange?: (field: string, value: any) => void;
  pathToLabel?: string;
  pathToValue?: string;
  pathToOrder?: string;
  textFieldProps?: TextFieldProps;
  draggable?: boolean;
} & Omit<StandardProps<React.HTMLAttributes<HTMLDivElement>, ''>, 'onChange'>;

const ChipsInput: React.FC<ChipsInputProps> = ({
  field,
  label,
  chipsFieldProps,
  autoSuggestFieldProps,
  pathToLabel = DEFAULT_PATH_TO_LABEL,
  pathToValue = DEFAULT_PATH_TO_VALUE,
  pathToOrder = DEFAULT_PATH_TO_ORDER,
  textFieldProps,
  draggable,
  className,
  style,
  onChange,
  item,
}) => {
  const classes = useStyles();

  let value: any[];
  let handleChange: (items: any[]) => void;
  const { fieldValue, onChange: onInputChange } = useEditContextForInput<any[]>(
    field,
  );
  if (onChange) {
    value = path(field.split('.'), item) || [];
    handleChange = (items: any[]) => onChange(field, items);
  } else {
    value = fieldValue || [];
    handleChange = (items: any[]) => onInputChange(items);
  }

  const [textInputValue, setTextInputValue] = React.useState<string>('');

  const onAdd = (text: string, id?: string) => {
    const newItem = mergeDeepLeft(
      assocPath(pathToValue.split('.'), id ? id : uuidv4(), {}),
      assocPath(pathToLabel.split('.'), text, {}),
    );

    if (draggable) {
      mergeDeepLeft(
        newItem,
        assocPath(pathToOrder.split('.'), value.length, {}),
      );
    }

    const x = [...value, newItem];
    handleChange(x);
  };

  const onDelete = (text: string) => {
    const x = value.filter(
      (element) => parsePathToString(pathToValue, element) !== text,
    );
    handleChange(x);
  };

  const props = {
    ...chipsFieldProps,
    field: 'chipItems',
    item: { chipItems: value },
  };

  return (
    <div className={classnames(classes.root, className)}>
      {label && (
        <FormLabel classes={{ root: classes.label }}>{label}</FormLabel>
      )}
      <div className={classes.chipsWrapper}>
        {draggable ? (
          <DraggableChipsField
            className={classes.chipsField}
            chipProps={{ clickable: true }}
            onDelete={(text) => onDelete(text)}
            style={style}
            pathToLabel={pathToLabel}
            pathToValue={pathToValue}
            pathToOrder={pathToOrder}
            onChipsChange={(chipsItems) => handleChange(chipsItems)}
            {...props}
          />
        ) : (
          <ChipsField
            className={classes.chipsField}
            chipProps={{ clickable: true }}
            onDelete={(text) => onDelete(text)}
            pathToLabel={pathToLabel}
            pathToValue={pathToValue}
            {...props}
          />
        )}
        {autoSuggestFieldProps ? (
          <AutoSuggestField
            keyValue={field}
            onSuggestionSelected={async (selectedSuggestion) => {
              if (
                autoSuggestFieldProps.upsertSuggestion &&
                selectedSuggestion.isNewValue
              ) {
                try {
                  const result = await autoSuggestFieldProps.upsertSuggestion({
                    title: selectedSuggestion.value,
                  });
                  if (result && result.id) {
                    onAdd(result.title, result.id);
                  }
                } catch (error) {
                  console.error('error during upsetMutation : ', error);
                }
              } else {
                onAdd(selectedSuggestion.label, selectedSuggestion.id);
              }
            }}
            {...autoSuggestFieldProps}
          />
        ) : (
          <TextField
            margin="none"
            className={classes.textField}
            style={style}
            inputProps={{
              'data-testid': `text-input-${field}`,
            }}
            InputProps={{
              margin: 'none',
            }}
            onChange={(e) => setTextInputValue(e.target.value)}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter' && textInputValue) {
                onAdd(textInputValue);
                ev.preventDefault();
              }
            }}
            value={textInputValue}
            {...textFieldProps}
          />
        )}
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default ChipsInput;

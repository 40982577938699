import * as React from 'react';
import { Avatar, Chip, StandardProps, Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { ChipProps } from '@mui/material/Chip';
import {
  parsePathToArray,
  parsePathToString,
} from '../../../crud-core/utils/Path';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
  DropResult,
} from 'react-beautiful-dnd';
import { getSpacing } from '@prismamedia/one-components';

export const DEFAULT_PATH_TO_ORDER = 'order';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chipRoot: {
      maxWidth: '100%',
      marginBottom: getSpacing(theme, 1),
      marginRight: getSpacing(theme, 1),
    },
    chipLabel: {
      display: 'block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      userSelect: 'auto',
    },
  });

const grid = 8;

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const reorderByOrderField = (
  list: any[],
  pathToOrder: string = DEFAULT_PATH_TO_ORDER,
) => {
  const result = Array.from(list);
  result.sort((a: any, b: any) => a[pathToOrder] - b[pathToOrder]);

  return result;
};

const getListStyle = () => ({
  display: 'flex',
  padding: grid,
  overflow: 'auto',
});

const getItemStyle = (
  draggableStyle: undefined | DraggingStyle | NotDraggingStyle,
) => ({
  ...draggableStyle,
});

type ChipsFieldProps = {
  chipProps?: ChipProps;
  defaultAvatar?: JSX.Element;
  field: string;
  item?: any;
  label?: string;
  onChipsChange?: (chipsItems: any[]) => void;
  onClick?: (value: string) => void;
  onDelete?: (value: string) => void;
  pathToAvatar?: string;
  pathToLabel?: string;
  pathToValue?: string;
  pathToOrder?: string;
} & WithStyles<typeof styles> &
  StandardProps<React.HTMLAttributes<HTMLDivElement>, ''>;

interface ChipsFieldState {
  chipItems: any[];
}

class DraggableChipsField extends React.Component<
  ChipsFieldProps,
  ChipsFieldState
> {
  constructor(props: ChipsFieldProps) {
    super(props);
    this.state = {
      chipItems: reorderByOrderField(
        parsePathToArray(this.props.field, this.props.item, null),
        this.props.pathToOrder,
      ),
    };
  }

  componentDidUpdate(prevProps: ChipsFieldProps) {
    if (
      prevProps.field !== this.props.field ||
      JSON.stringify(prevProps.item) !== JSON.stringify(this.props.item)
    ) {
      const chipItems = reorderByOrderField(
        parsePathToArray(this.props.field, this.props.item, null),
        this.props.pathToOrder,
      );

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        {
          chipItems,
        },
        () => {
          if (this.props.onChipsChange) {
            this.props.onChipsChange(this.state.chipItems);
          }
        },
      );
    }
  }

  onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const chipItems = reorderByOrderField(
      reorder(
        this.state.chipItems,
        result.source.index,
        result.destination.index,
      ),
      this.props.pathToOrder,
    );

    this.setState(
      {
        chipItems,
      },
      () => {
        if (this.props.onChipsChange) {
          this.props.onChipsChange(this.state.chipItems);
        }
      },
    );
  };

  render() {
    const {
      pathToAvatar,
      defaultAvatar,
      onClick,
      onDelete,
      chipProps,
      pathToLabel,
      pathToValue,
      classes,
    } = this.props;
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided) => (
            <div
              ref={provided.innerRef}
              style={getListStyle()}
              {...provided.droppableProps}
            >
              {this.state.chipItems.map((chipItem, index) => {
                const label = parsePathToString(
                  pathToLabel,
                  chipItem,
                  chipItem,
                );
                const value = parsePathToString(
                  pathToValue,
                  chipItem,
                  chipItem,
                );

                const avatarUrl = parsePathToString(
                  pathToAvatar,
                  chipItem,
                  null,
                );

                const avatar = avatarUrl ? (
                  <Avatar
                    alt={label}
                    src={avatarUrl}
                    imgProps={{
                      onError: (e) => (e.target as Element).remove(),
                    }}
                  />
                ) : (
                  defaultAvatar
                );

                let props = chipProps;
                if (onDelete) {
                  props = {
                    onDelete: () => onDelete(value),
                    ...props,
                  };
                }
                if (onClick) {
                  props = {
                    onClick: () => onClick(value),
                    ...props,
                  };
                }

                return (
                  <Draggable key={value} draggableId={value} index={index}>
                    {(chipItemProvided) => (
                      <div
                        ref={chipItemProvided.innerRef}
                        {...(chipItemProvided.draggableProps as any)}
                        {...(chipItemProvided.dragHandleProps as any)}
                        style={getItemStyle(
                          chipItemProvided.draggableProps.style,
                        )}
                      >
                        <Chip
                          key={value}
                          label={label}
                          classes={{
                            root: classes.chipRoot,
                            label: classes.chipLabel,
                          }}
                          avatar={avatar}
                          {...props}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

// eslint-disable-next-line import/no-default-export
export default withStyles(styles)(DraggableChipsField);

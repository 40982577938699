import React from 'react';
import { CivilityFields } from './CivilityFields';
import { CVFields } from './CVFields';
import { NominationsFields } from './NominationsFields';
import { RelationsFields } from './RelationsFields';
import { SocialNetworks } from './SocialNetworks';

const columnsStyles: React.CSSProperties = { float: 'left', width: '50%' };
const fieldSetStyle: React.CSSProperties = { margin: '30px 15px 0 15px' };

export const Generalities: React.FC = () => (
  <>
    <div style={{ width: '100%' }}>
      <div style={columnsStyles}>
        <div style={fieldSetStyle}>
          <CivilityFields />
        </div>
        <div style={fieldSetStyle}>
          <RelationsFields />
        </div>
      </div>
      <div style={columnsStyles}>
        <div style={fieldSetStyle}>
          <CVFields />
        </div>
        <div style={fieldSetStyle}>
          <SocialNetworks />
        </div>
        <div style={fieldSetStyle}>
          <NominationsFields />
        </div>
      </div>
    </div>
  </>
);
